import Vue from 'vue';

class Notify {
   success(title, text, options = {}) {
      return this.show(title, { type: 'success', text, ...options });
   }

   error(title, text, options = {}) {
      return this.show(title, { type: 'error', text, ...options });
   }

   warn(title, text, options = {}) {
      return this.show(title, { type: 'success', text, ...options });
   }

   show(title, options = { type: 'success', group: 'default', text: null }) {
      return Vue.notify({
         title,
         speed: 0,
         duration: 3000,
         ...options,
      });
   }
}

export default new Notify();
