<template>
   <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M2.11897 0.562063C3.42989 -0.199635 5.04122 -0.186322 6.33983 0.596937C7.62568 1.39615 8.40718 2.82251 8.3999 4.35687C8.36997 5.88116 7.53198 7.314 6.48448 8.42165C5.8799 9.06384 5.20357 9.6317 4.46931 10.1136C4.39368 10.1574 4.31085 10.1866 4.22489 10.2C4.14216 10.1965 4.06159 10.172 3.99045 10.1289C2.86946 9.40474 1.886 8.48042 1.0874 7.40038C0.419153 6.49882 0.0395014 5.4096 6.97039e-07 4.28065C-0.000866805 2.74335 0.80806 1.32376 2.11897 0.562063ZM2.87652 4.91687C3.09703 5.4605 3.61753 5.8151 4.19498 5.81511C4.57328 5.81782 4.93693 5.66629 5.2049 5.39428C5.47288 5.12227 5.62291 4.75238 5.62157 4.36703C5.62358 3.77882 5.27729 3.24739 4.74438 3.02086C4.21146 2.79434 3.59704 2.91741 3.18801 3.33261C2.77897 3.7478 2.656 4.37323 2.87652 4.91687Z"
         fill="#137AF6"
      />
      <ellipse opacity="0.4" cx="4.2002" cy="11.4" rx="3" ry="0.6" fill="#137AF6" />
   </svg>
</template>

<script>
export default {
   name: 'IconCoordinates',
};
</script>
