<template>
   <AmcRow class="mt-4">
      <AmcCol col="2 mt-5 pt-4">
         <AmcNav :items="items" />
      </AmcCol>
      <AmcCol col="10">
         <router-view></router-view>
      </AmcCol>
   </AmcRow>
</template>

<script>
import get from 'lodash/get';
import moment from 'moment';

import IconHome from '@/components/icons/facilities-detail-icons/IconHome';
import IconAuthorities from '@/components/icons/facilities-detail-icons/IconAuthorities';
import IconModemler from '@/components/icons/facilities-detail-icons/IconModemler';

export default {
   components: {},
   data() {
      return {
         today: moment().get().format('YYYY-MM-DD'),
         items: [
            {
               title: this.$t('reports.Olay Geçmişi'),
               subtitle: this.$t('reports.Belirli tarihler arasında olay geçmişini görüntüleyin'),
               tag: IconHome,
               to: `/reports/modem-logs`,
            },
            {
               title: this.$t('reports.Modem Listesi'),
               subtitle: this.$t('reports.Sistemde tanımlı modem listesini görüntüleyin'),
               tag: IconModemler,
               to: `/reports/modem-list`,
            },
            {
               title: this.$t('reports.Kullanıcı Listesi'),
               subtitle: this.$t('reports.Sistemde tanımlı kullanıcı listesini görüntüleyin'),
               tag: IconAuthorities,
               to: `/reports/user-list`,
            },
         ],
         company: {
            project: {},
         },
         step: 1,
         totalStep: 3,
      };
   },

   methods: {
      get,
      nextStep() {
         if (this.step !== this.totalStep) {
            this.step++;
         }
      },
      previousStep() {
         if (this.step !== 1) {
            this.step--;
         }
      },
      close() {
         this.step = 1;
      },
   },
};
</script>

<style scoped lang="scss">
.step-number {
   font-size: 20px;
   font-weight: 600;
   height: 50px;
   width: 50px;
   background-color: #ced4da;
   display: flex;
   justify-content: center;
   border-radius: 100%;
}
.step-line {
   background-color: #ced4da;
   margin: 0 10px;
   width: 50px;
   height: 3px;
   border-radius: 50px;
}
.state-bar {
   margin-top: -9px;
   margin-right: 20px;
}
</style>
