<template>
   <AmcText>
      <AmcText class="d-flex justify-content-between shadow-sm rounded-8 p-4">
         <AmcText class="fs-3 align-self-center">{{
            $t('facilities.detail.bildirimAyarlari.Bildirim Ayarları')
         }}</AmcText>
         <button v-if="!readOnly" @click="editBuilding" class="btn btn-primary px-4 align-self-center py-2">
            {{ $t('buttons.Ayarları Kaydet') }}
         </button>
      </AmcText>
      <AmcText class="shadow-sm rounded-8 mt-4 py-4">
         <AmcText class="border m-5">
            <AmcText class="border-bottom d-flex justify-content-between">
               <AmcText class="p-3 fs-16 align-self-center"
                  ><span class="fw-500">{{ $t('facilities.detail.bildirimAyarlari.Alarm Bildirim Tekrar') }}</span> ({{
                     $t('facilities.detail.bildirimAyarlari.Dakika')
                  }})
               </AmcText>
               <AmcText class="text-secondary pe-5 py-3 d-flex">
                  <input
                     :disabled="readOnly"
                     type="number"
                     v-autowidth="{ maxWidth: '960px', minWidth: '50px', comfortZone: 0 }"
                     class="form-control shadow-none"
                     v-model="notificationSettings.alarm_notification_timeout"
                  />
               </AmcText>
            </AmcText>
            <AmcText class="border-bottom d-flex justify-content-between">
               <AmcText class="p-3 fs-16 align-self-center fw-500">{{
                  $t('facilities.detail.bildirimAyarlari.Alarm Bildirim Max Deneme')
               }}</AmcText>
               <AmcText class="text-secondary pe-5 py-3">
                  <AmcText class="increase"></AmcText>
                  <input
                     :disabled="readOnly"
                     type="number"
                     v-autowidth="{ maxWidth: '960px', minWidth: '50px', comfortZone: 0 }"
                     class="form-control shadow-none"
                     v-model="notificationSettings.alarm_notification_max_tries"
                  />
               </AmcText>
            </AmcText>
            <AmcText class="border-bottom d-flex justify-content-between">
               <AmcText class="p-3 fs-16 align-self-center"
                  ><span class="fw-500">{{ $t('facilities.detail.bildirimAyarlari.Arıza Bildirim Tekrar') }}</span> ({{
                     $t('facilities.detail.bildirimAyarlari.Dakika')
                  }})
               </AmcText>
               <AmcText class="text-secondary pe-5 py-3">
                  <AmcText class="increase"></AmcText>
                  <input
                     :disabled="readOnly"
                     type="number"
                     v-autowidth="{ maxWidth: '960px', minWidth: '50px', comfortZone: 0 }"
                     class="form-control shadow-none"
                     v-model="notificationSettings.fault_notification_timeout"
                  />
               </AmcText>
            </AmcText>
            <AmcText class="border-bottom d-flex justify-content-between">
               <AmcText class="p-3 fs-16 align-self-center fw-500">{{
                  $t('facilities.detail.bildirimAyarlari.Arıza Bildirim Max Deneme')
               }}</AmcText>
               <AmcText class="text-secondary pe-5 py-3">
                  <AmcText class="increase"></AmcText>
                  <input
                     :disabled="readOnly"
                     type="number"
                     v-autowidth="{ maxWidth: '960px', minWidth: '50px', comfortZone: 0 }"
                     class="form-control shadow-none"
                     v-model="notificationSettings.fault_notification_max_tries"
                  />
               </AmcText>
            </AmcText>
         </AmcText>
         <AmcText :key="i" v-for="(notificationRuleGroup, i) in notificationRuleGroups" class="border-top">
            <AmcText
               class="fw-500 px-5 mt-5"
               v-html="$t(`facilities.detail.bildirimAyarlari.${notificationRuleGroup.title}`)"
            />
            <AmcText class="d-flex">
               <AmcText :key="index" class="px-5 py-4" v-for="(notification, index) in notificationOptions">
                  <input
                     :disabled="readOnly"
                     type="checkbox"
                     v-model="notificationRule[notificationRuleGroup.key]"
                     :value="notification.value"
                     :id="`${i}-${notification.value}`"
                  />
                  <label class="ms-3" :for="`${i}-${notification.value}`">
                     {{ $t(`checkbox.${notification.text}`) }}
                  </label>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
   </AmcText>
</template>

<script>
import get from 'lodash/get';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';

import Notify from '@/libs/notify';
import BuildingApi from '@/api/BuildingApi';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'NotificationSettings',
   data() {
      return {
         buildingDetail: {},
         notificationRule: {},
         notificationSettings: {},
         readOnly: false,
         notificationRuleGroups: [
            {
               title: 'Alarm Bildirim Seçenekleri',
               key: 'in_alarm',
            },
            {
               title: 'Arıza Bildirim Seçenekleri',
               key: 'in_fault',
            },
            {
               title: 'Normal Bildirim Seçenekleri',
               key: 'in_normal',
            },
            {
               title: 'Offline Bildirim Seçenekleri',
               key: 'in_disconnected',
            },
            {
               title: 'Online Bildirim Seçenekleri',
               key: 'in_connected',
            },
         ],
         notificationOptions: [],
      };
   },
   computed: {
      buildingId() {
         return this.$route.params.id;
      },
   },
   async beforeMount() {
      this.readOnly = this.$store.state.auth.authUser.read_only;
      await this.getBuildingDetail();
   },
   methods: {
      async getBuildingDetail() {
         try {
            const result = await BuildingApi.GET_BUILDING(this.buildingId);
            const buildingDetail = get(result, 'data');
            this.buildingDetail = buildingDetail;

            const defaultNotificationRule = reduce(
               this.notificationRuleGroups,
               (acc, { key }) => {
                  acc[key] = [];
                  return acc;
               },
               {}
            );

            this.notificationRule = merge(defaultNotificationRule, get(buildingDetail, 'notification_rule'));
            this.notificationSettings = pick(buildingDetail, [
               'alarm_notification_timeout',
               'alarm_notification_max_tries',
               'fault_notification_timeout',
               'fault_notification_max_tries',
            ]);

            if (this.buildingDetail.project) {
               const project = this.buildingDetail.project;
               if (project.sms_notification) {
                  this.notificationOptions.push({ value: 'sms', text: 'Sms' });
               }
               if (project.call_notification) {
                  this.notificationOptions.push({ value: 'call', text: 'Çağrı' });
               }
               if (project.mobile_notification) {
                  this.notificationOptions.push({ value: 'mobile', text: 'Mobile' });
               }
               if (project.email_notification) {
                  this.notificationOptions.push({ value: 'email', text: 'E-posta' });
               }
            }
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editBuilding() {
         try {
            const buildingPayload = pick(this.buildingDetail, [
               'address',
               'city_id',
               'country_id',
               'district_id',
               'name',
               'project_id',
               'purpose_id',
               'risk_id',
               'short_name',
            ]);
            const notificationRulePayload = { notification_rule: this.notificationRule };
            const payload = merge(buildingPayload, notificationRulePayload, this.notificationSettings);
            await BuildingApi.EDIT_BUILDING(this.buildingId, payload);

            Notify.success(this.$t('notify.Bildirim Ayarlari Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
   },
};
</script>

<style scoped></style>
