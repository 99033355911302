import Http from '../libs/http';

class PanelsApi extends Http {
   index() {
      return super.get('api/panel/panels');
   }
   CREATE_PANEL(payload) {
      return super.post('api/panel/panels', payload);
   }
   EDIT_PANEL(id, payload) {
      return super.put(`api/panel/panels/${id}`, payload);
   }
   REMOVE_PANEL(ID) {
      return super.delete(`api/panel/panels/${ID}`);
   }
}

export default new PanelsApi();
