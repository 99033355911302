import Http from '../libs/http';

class ModelsApi extends Http {
   index(ID) {
      return super.get(`api/panel/panels/${ID}/models`);
   }

   CREATE_MODEL(idBrand, payload) {
      return super.post(`api/panel/panels/${idBrand}/models`, payload);
   }

   EDIT_MODEL(idBrand, idModel, payload) {
      return super.put(`api/panel/panels/${idBrand}/models/${idModel}`, payload);
   }

   REMOVE_MODEL(idBrand, idModel) {
      return super.delete(`api/panel/panels/${idBrand}/models/${idModel}`);
   }
}

export default new ModelsApi();
