import Http from '../libs/http';

class ModemApi extends Http {
   GET_ALARM() {
      return super.get('api/panel/statistics/alarm');
   }

   GET_FAULT() {
      return super.get('api/panel/statistics/fault');
   }

   GET_OFFLINE() {
      return super.get('api/panel/statistics/offline');
   }

   GET_ONLINE() {
      return super.get('api/panel/statistics/online');
   }
}

export default new ModemApi();
