<template>
   <v-main>
      <router-view />
      <notifications position="top center" :max="1" width="100%" />
   </v-main>
</template>

<script>
export default {
   name: 'App',
   mounted() {
      this.initHMRControl();
   },
   methods: {
      initHMRControl() {
         if (module && module.hot) {
            // noinspection JSUnresolvedFunction
            module.hot.addStatusHandler(function (status) {
               const elmBackdrop = document.body.querySelector('.modal-backdrop');

               if (!elmBackdrop) return;

               if (status === 'apply') {
                  elmBackdrop.style.display = 'none';
               }
            });
         }
      },
   },
};
</script>
