<template>
   <svg fill="none" height="30" viewBox="0 0 112 30" width="112" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M55.1203 14.6382C55.1203 12.3327 54.3013 10.3576 52.6702 8.7265C51.0391 7.09544 49.0638 6.27647 46.7582 6.27647C44.4458 6.27647 42.4774 7.09544 40.8463 8.7265C39.2152 10.3576 38.3962 12.3327 38.3962 14.6382V29.2764H32.1194V14.6382C32.1194 10.5847 33.5441 7.13674 36.4003 4.28066C39.2565 1.42459 42.7045 0 46.7582 0C50.8119 0 54.26 1.42459 57.1162 4.28066C57.5291 4.69359 57.9145 5.12028 58.2587 5.55385C58.6097 5.12028 58.9882 4.69359 59.4011 4.28066C62.2573 1.42459 65.7054 0 69.7591 0C73.8128 0 77.2609 1.42459 80.1171 4.28066C82.9732 7.13674 84.3979 10.5847 84.3979 14.6382V29.2764H78.1212V14.6382C78.1212 12.3327 77.3022 10.3576 75.671 8.7265C74.0399 7.09544 72.0647 6.27647 69.7591 6.27647C67.4466 6.27647 65.4783 7.09544 63.8471 8.7265C62.216 10.3576 61.397 12.3327 61.397 14.6382V29.2764H55.1203V14.6382Z"
         fill="#137AF6"
      />
      <path
         d="M111.57 24.9958C108.714 27.8519 105.266 29.2764 101.212 29.2764C97.1584 29.2764 93.7104 27.8519 90.8542 24.9958C87.998 22.1397 86.5734 18.6918 86.5734 14.6382C86.5734 10.5847 87.998 7.13674 90.8542 4.28066C93.7104 1.42459 97.1584 0 101.212 0C105.266 0 108.714 1.42459 111.57 4.28066L111.605 4.31508L107.158 8.76091L107.124 8.7265C105.493 7.09544 103.518 6.27647 101.212 6.27647C98.8997 6.27647 96.9313 7.09544 95.3002 8.7265C93.6691 10.3576 92.8501 12.3327 92.8501 14.6382C92.8501 16.9506 93.6691 18.9189 95.3002 20.5499C96.9313 22.181 98.9066 23 101.212 23C103.518 23 105.493 22.181 107.124 20.5499L107.158 20.5155L111.605 24.9614L111.57 24.9958Z"
         fill="#137AF6"
      />
      <path
         d="M29.2019 1.57687V29.2842H22.5673V15.4581C22.5673 13.2489 21.7896 11.3632 20.241 9.81474C18.6925 8.26627 16.8136 7.48859 14.5975 7.48859C13.5307 7.48859 12.5053 7.69505 11.528 8.09422C10.5507 8.50026 9.69037 9.07147 8.95395 9.80786C7.40542 11.3563 6.62771 13.2351 6.62771 15.4512C6.62771 17.6259 7.40542 19.491 8.95395 21.0394C9.69037 21.8171 10.5507 22.3952 11.528 22.7806C12.5053 23.166 13.5307 23.3587 14.5975 23.3587C15.7055 23.3587 16.6966 23.1729 17.5844 22.8081L19.9657 28.7267C18.2658 29.5732 16.2974 29.9999 14.0469 29.9999C12.0166 29.9999 10.1446 29.6214 8.43089 28.8644C6.71719 28.1074 5.2306 27.0682 3.97801 25.7399C2.72542 24.4117 1.74812 22.8632 1.04612 21.0945C0.351 19.3327 0 17.447 0 15.4581C0 13.4278 0.351 11.5284 1.053 9.75968C1.755 7.99098 2.7323 6.44251 3.98489 5.11427C5.23748 3.78602 6.72407 2.74683 8.43778 1.98979C10.1515 1.23276 12.0235 0.854248 14.0538 0.854248C15.857 0.854248 17.4606 1.12953 18.8646 1.68698C20.2686 2.23755 21.5005 2.93952 22.5673 3.78602V1.57687H29.2019Z"
         fill="#137AF6"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconAmc',
};
</script>
