<template>
   <AmcText>
      <AmcCard Card p3>
         <AmcText>
            <AmcText class="d-flex">
               <VueCtkDateTimePicker
                  v-model="dateValue"
                  :no-value-to-custom-elem="true"
                  class="d-flex justify-content-between"
                  :locale="dateLang"
                  checked="value"
                  range
                  no-shortcuts
                  format="YYYY-MM-DD"
               >
                  <AmcText class="position-relative">
                     <input
                        id="RangeDatePicker"
                        v-autowidth="{ maxWidth: '960px', minWidth: '150px', comfortZone: 0 }"
                        class="form-control date-input field-input bg-white"
                        type="text"
                        readonly
                        :value="datePlaceholder"
                     />
                     <span>
                        <icon-mini-calender class="icon-mini-calender" />
                     </span>
                     <span>
                        <icon-open class="icon-open" />
                     </span>
                  </AmcText>
                  <AmcText class="d-flex justify-content-between">
                     <AmcText class="d-flex">
                        <AmcText class="align-self-center">
                           <select
                              class="form-select shadow-none"
                              aria-label="Default select example"
                              v-model="projectID"
                              @change="loadBuilding"
                           >
                              <option selected value="0">{{ $t('dropdowns.Tüm Projeler') }}</option>
                              <option :key="index" v-for="(project, index) in projectsData" :value="project.id">
                                 {{ get(project, 'name') }}
                              </option>
                           </select>
                        </AmcText>
                        <AmcText class="align-self-center">
                           <select
                              class="form-select shadow-none ms-3"
                              aria-label="Default select example"
                              v-model="buildingID"
                           >
                              <option selected value="0">{{ $t('dropdowns.Tüm Tesisler') }}</option>
                              <option :key="index" v-for="(building, index) in buildingData" :value="building.id">
                                 {{ get(building, 'name') }}
                              </option>
                           </select>
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcButton
                     is-hidden
                     @click="loadData"
                     class="text-white ms-5 rounded-pill"
                     :icon="IconAdded"
                     ps="2"
                     px="5"
                     height="42px"
                  >
                     {{ $t('buttons.Rapor Oluştur') }}
                  </AmcButton>
               </VueCtkDateTimePicker>
            </AmcText>
            <AmcText class="rounded-8 mt-4">
               <AmcStateButton @filterChanged="filterChanged" :filters="currentFilter" no-count="true" />
            </AmcText>
         </AmcText>
      </AmcCard>
      <AmcCard class="border rounded-8 shadow-sm mt-4">
         <AmcText v-if="stateLoading" class="d-flex justify-content-center py-5">
            <AmcText class="text-center">
               <IconCalender />
               <AmcText class="mt-4 fw-600 fs-16">{{ $t('reports.Tarih aralığı seçiniz') }}</AmcText>
               <AmcText class="text-muted mt-3">
                  {{
                     $t(
                        'reports.Raporu görmek için lütfen önce filtrelerinizi seçiniz ve ardından Rapor Oluştur’a tıklayarak rapor oluşturunuz'
                     )
                  }}.
               </AmcText>
            </AmcText>
         </AmcText>
         <AmcTable
            hover
            Border
            Center
            striped
            :data="data"
            ref="logTable"
            :header="header"
            @pageChanged="loadReports"
            @perPageChanged="loadReports"
            v-show="!stateLoading && !loading"
         >
            <template #header>
               <AmcText class="d-flex align-self-center pb-4">
                  <AmcText role="button" class="me-3 download-file align-self-center" @click="downloadReport">
                     <AmcText v-if="stateDownload" class="spinner-border spinner-border-sm" />
                     <IconDownload v-else />
                  </AmcText>
                  <AmcText as="button" class="mail-push" @click="pushEmail">
                     <AmcText v-if="stateEmail" class="spinner-border spinner-border-sm" />
                     <IconMailPush v-else />
                  </AmcText>
               </AmcText>
            </template>
            <template #created_at="{ prop }">
               {{ moment(prop).format('DD-MM-YYYY HH:MM:SS') }}
            </template>
            <template #alarm="{ prop }">
               <span v-if="prop === 'normal'">
                  <div class="alert-primary fs-10 py-1 rounded-pill">{{ $t('situations.NORMAL') }}</div>
               </span>
               <span v-if="prop === 'alarm'">
                  <div class="alert-danger alert-primary fs-10 py-1 rounded-pill">{{ $t('situations.ALARM') }}</div>
               </span>
            </template>
            <template #fault="{ prop }">
               <span v-if="prop === 'normal'">
                  <div class="alert-primary fs-10 py-1 rounded-pill">{{ $t('situations.NORMAL') }}</div>
               </span>
               <span v-else-if="prop === 'fault'">
                  <div class="alert-warning alert-primary fs-10 py-1 rounded-pill">{{ $t('situations.ARIZA') }}</div>
               </span>
            </template>
            <template #status="{ prop }">
               <span v-if="prop === 'normal'">
                  <div class="alert-primary fs-10 py-1 rounded-pill border-primary">{{ $t('situations.NORMAL') }}</div>
               </span>
               <span v-else-if="prop === 'fault'">
                  <div class="alert-warning alert-primary fs-10 py-1 rounded-pill border-primary">
                     {{ $t('situations.ARIZA') }}
                  </div>
               </span>
               <span v-else-if="prop === 'connected'">
                  <div class="alert-success alert-primary fs-10 py-1 rounded-pill border-primary">
                     {{ $t('situations.ÇEVRİMİÇİ') }}
                  </div>
               </span>
               <span v-else-if="prop === 'disconnected'">
                  <div class="alert-secondary alert-primary fs-10 py-1 rounded-pill border-primary">
                     {{ $t('situations.ÇEVRİMDIŞI') }}
                  </div>
               </span>
               <span v-else-if="prop === 'fire'">
                  <div class="alert-danger alert-primary fs-10 py-1 rounded-pill border-primary">
                     {{ $t('situations.YANGIN') }}
                  </div>
               </span>
               <span v-else-if="prop === 'info'">
                  <div class="alert-info alert-primary fs-10 py-1 rounded-pill border-primary">
                     {{ $t('situations.BİLGİ') }}
                  </div>
               </span>
            </template>
         </AmcTable>
      </AmcCard>
      <AmcLoading v-if="loading" />
   </AmcText>
</template>
<script>
import get from 'lodash/get';
import moment from 'moment';
import LogApi from '@/api/LogApi';
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import IconOpen from '@/components/icons/IconOpen';
import IconAdded from '@/components/icons/IconAdded';
import IconCalender from '@/components/icons/IconCalender';
import IconDownload from '@/components/icons/IconDownload';
import IconMailPush from '@/components/icons/IconMailPush';
import IconMiniCalender from '@/components/icons/IconMiniCalender';
import IconSearch from '@/components/icons/header/IconSearch';
import ProjectsApi from '@/api/ProjectsApi';
import BuildingApi from '@/api/BuildingApi';
export default {
   name: 'Reports',
   components: {
      IconMiniCalender,
      IconCalender,
      IconDownload,
      IconMailPush,
      IconOpen,
   },
   data() {
      return {
         dateLang: localStorage.getItem('lang'),
         search: '',
         IconSearch,
         IconAdded,
         dateValue: null,
         projectsData: [{ project: {} }],
         projectID: 0,
         buildingData: [],
         buildingID: 0,
         header: [
            { title: 'PROJE', value: 'building.project.name' },
            { title: 'BİNA', value: 'building_name' },
            { title: 'MODEM ID', value: 'serial_number' },
            { title: 'TARİH', value: 'created_at' },
            { title: 'ALARM', value: 'alarm' },
            { title: 'ARIZA', value: 'fault' },
            { title: 'OLAY', value: 'status' },
         ],
         stateLoading: true,
         stateDownload: false,
         stateEmail: false,
         loading: false,
         data: {},
         query: {
            page: get(this.$route.query, 'page', 1),
            per_page: get(this.$route.query, 'perPage', 10),
            fire: get(this.$route.query, 'fire', 1),
            fault: get(this.$route.query, 'fault', 1),
            info: get(this.$route.query, 'info', 1),
            is_connected: get(this.$route.query, 'is_connected', 1),
            is_disconnected: get(this.$route.query, 'is_disconnected', 1),
            startTime: '00:00:00',
            endTime: '23:59:59',
         },
      };
   },
   methods: {
      get,
      async loadData() {
         await this.filterChanged();
         await this.loadReports();
      },
      async loadReports() {
         const params = this.query;
         if (!params) return;

         params.page = get(this.$route.query, 'page', 1);
         params.per_page = get(this.$route.query, 'perPage', 10);
         params.startDate = get(this.dateValue, 'start') || get(this.$route.query, 'startDate');
         params.endDate = get(this.dateValue, 'end') || get(this.$route.query, 'endDate');
         if (!params.startDate || !params.endDate) {
            Notify.error(this.$t('notify.Lütfen tarih aralığını seçiniz'));
            return null;
         }
         try {
            this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});
            this.loading = true;
            this.stateLoading = false;
            this.data = await LogApi.index(params);
            this.loading = false;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async downloadReport() {
         const params = this.query;
         if (!params) return;
         params.startDate = get(this.dateValue, 'start') || get(this.$route.query, 'startDate');
         params.endDate = get(this.dateValue, 'end') || get(this.$route.query, 'endDate');
         if (!params.startDate || !params.endDate) {
            Notify.error(this.$t('notify.Lütfen tarih aralığını seçiniz'));
            return null;
         }
         try {
            this.stateDownload = true;
            const data = await LogApi.index({ ...params, download: true });
            window.open(data.file);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateDownload = false;
         }
      },
      async pushEmail() {
         const params = this.query;
         if (!params) return;
         params.startDate = get(this.dateValue, 'start') || get(this.$route.query, 'startDate');
         params.endDate = get(this.dateValue, 'end') || get(this.$route.query, 'endDate');
         if (!params.startDate || !params.endDate) {
            Notify.error(this.$t('notify.Lütfen tarih aralığını seçiniz'));
            return null;
         }
         try {
            this.stateEmail = true;
            await LogApi.index({ ...params, by_email: true });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateEmail = false;
         }
      },
      async filterChanged(filter, newValue) {
         this.query[filter] = newValue;
         this.query['project_id'] = this.projectID > 0 ? this.projectID : null;
         this.query['building_id'] = this.buildingID > 0 ? this.buildingID : null;
         await this.$router.push({ query: { ...this.$route.query, page: '1' } }).catch(() => {});
      },
      async loadProjects() {
         try {
            const response = await ProjectsApi.index();
            this.projectsData = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadBuilding() {
         try {
            const response = await BuildingApi.index({ project_id: this.projectID });
            this.buildingData = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
   },
   computed: {
      datePlaceholder() {
         if (!this.dateValue) return this.$t('reports.Tarih aralığı seçiniz');
         return (
            (moment(this.dateValue.start).format('DD-MM-YYYY') ||
               this.$t('facilities.detail.kullanimBilgileri.Başlangıç Tarihi')) +
            ' / ' +
            (moment(this.dateValue.end).format('DD-MM-YYYY') ||
               this.$t('facilities.detail.kullanimBilgileri.Bitiş Tarihi'))
         );
      },
      currentFilter() {
         return this.query;
      },
   },
   async beforeMount() {
      const endDate = this.$route.query.endDate;
      const startDate = this.$route.query.startDate;
      if (startDate && endDate) {
         this.dateValue = { start: startDate, end: endDate };
         await this.loadReports();
      }
      await this.loadProjects();
      await this.loadBuilding();
   },
};
</script>

<style scoped lang="scss">
.date-input {
   margin-right: 15px;
   padding-left: 35px;
   padding-right: 40px;
   height: 30.5px;
   &:focus {
      box-shadow: none;
      border: 1px solid #137af6;
   }
}
.icon-mini-calender {
   position: absolute;
   top: 12px;
   left: 10px;
}
.icon-open {
   position: absolute;
   right: 20px;
   top: 5.5px;
}
.datetimepicker {
   margin-bottom: 30px !important;
}
.search-input {
   border-radius: 50px;
   padding-top: 10px;
   padding-bottom: 10px;
   &:focus {
      box-shadow: none !important;
      border: 1px solid #137af6;
   }
}
.icon-search {
   position: absolute;
   top: 4px;
   right: 6px;
}
</style>
