<template>
   <AmcModal ref="modal">
      <template #header>
         <AmcText class="h4 pt-3"> {{ $t('modems.modal.Modem Düzenle') }} </AmcText>
      </template>
      <template>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">{{ $t('modems.modal.Cihaz Kodu') }}</AmcText>
            <input v-model="item.name" type="text" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">{{ $t('modems.modal.Özellikleri') }}</AmcText>
            <input v-model="item.description" type="text" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3 d-flex justify-content-between">
            <AmcText>
               <AmcText class="form-label fw-600 fs-16">Input</AmcText>
               <input v-model="item.number_contact_in" type="number" class="form-control shadow-none" />
            </AmcText>
            <AmcText>
               <AmcText class="form-label fw-600 fs-16">Output</AmcText>
               <input v-model="item.number_contact_out" type="number" class="form-control shadow-none" />
            </AmcText>
         </AmcText>
         <AmcText class="mb-3 d-flex justify-content-between">
            <AmcText class="d-flex">
               <AmcText class="form-label fw-600 fs-16 align-self-center mt-3">RS232</AmcText>
               <input
                  :checked="item.rs232_enabled"
                  class="form-check-input ms-3 shadow-none text-center mt-2 align-self-center"
                  type="checkbox"
                  :v-model="item.rs232_enabled"
                  @change="item.rs232_enabled = !item.rs232_enabled"
               />
            </AmcText>
            <AmcText class="d-flex">
               <AmcText class="form-label fw-600 fs-16 align-self-center mt-3">RS485</AmcText>
               <input
                  :checked="item.rs485_enabled"
                  class="form-check-input ms-3 shadow-none text-center mt-2 rs485-input align-self-center"
                  type="checkbox"
                  :v-model="item.rs485_enabled"
                  @change="item.rs485_enabled = !item.rs485_enabled"
               />
            </AmcText>
         </AmcText>
      </template>
      <template #actions>
         <AmcText>
            <button @click="hideModal" class="btn btn-outline-secondary">{{ $t('modalActions.Vazgeç') }}</button>
         </AmcText>
         <AmcText>
            <button @click="edit(item)" class="btn btn-primary">{{ $t('modalActions.Kaydet') }}</button>
         </AmcText>
      </template>
   </AmcModal>
</template>

<script>
export default {
   name: 'EditModal',
   data() {
      return {
         item: {},
      };
   },
   methods: {
      show(item) {
         this.$refs.modal.show();
         this.item = item;
      },
      hideModal() {
         this.$refs.modal.hide();
      },
      edit(item) {
         this.hideModal();
         this.$emit('edit', item);
      },
   },
};
</script>

<style scoped lang="scss">
.form-check-input {
   -webkit-appearance: none;
   background-color: #ffb5b5;
   border: none;
   padding: 8px;
   border-radius: 3px;
   position: relative;
   cursor: pointer;
   margin-bottom: 2px;
   width: 20px;
   height: 20px;

   &:checked {
      background-color: #2fa84f;
   }
}
</style>
