<template>
   <AmcModal ref="modal" lg>
      <template #header class="d-flex">
         <AmcText inline class="text-black h4 py-3" fwBold> {{ $t('user.modal.Yeni Kullanıcı Ekle') }}</AmcText>
         <AmcText class="d-flex">
            <AmcText class="d-flex">
               <AmcText :class="{ 'bg-primary': step === 1 || 2 || 3 }" class="step-number align-self-center">
                  <span :class="{ 'text-white': step !== 1 || 2 || 3 }" class="d-flex align-self-center">1</span>
               </AmcText>
               <AmcText :class="{ 'text-primary': step !== 1 || 2 || 3 }" class="align-self-center mx-3 fs-5 fw-500">
                  {{ $t('user.modal.Kişisel Bilgileri') }}
               </AmcText>
            </AmcText>
            <AmcText class="d-flex">
               <AmcText :class="{ 'bg-primary': step !== 1 }" class="step-number align-self-center">
                  <span :class="{ 'text-white': step !== 1 }" class="d-flex align-self-center">2</span>
               </AmcText>
               <AmcText :class="{ 'text-primary': step !== 1 }" class="align-self-center mx-3 fw-500 fs-5">
                  {{ $t('user.modal.Telefon') }}
               </AmcText>
            </AmcText>
            <AmcText class="d-flex">
               <AmcText :class="{ 'bg-primary': step === 3 }" class="step-number align-self-center">
                  <span :class="{ 'text-white': step === 3 }" class="d-flex align-self-center">3</span>
               </AmcText>
               <AmcText :class="{ 'text-primary': step === 3 }" class="align-self-center mx-3 fw-500 fs-5">
                  {{ $t('user.modal.Şifre') }}
               </AmcText>
            </AmcText>
         </AmcText>
      </template>
      <AmcText v-show="step === 1">
         <AmcText class="form-group mt-3" fwBold>
            <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.Adı Soyadı') }}</AmcText>
            <input
               @keyup="manager.isValid()"
               :class="{ 'border-danger': manager.errors.has('name') }"
               v-model="manager.name"
               class="py-2 form-control shadow-none w-100"
            />
            <span
               class="text-danger fs-12"
               :key="i"
               v-for="(error, i) in manager.errors.get('name')"
               v-html="error"
            ></span>
         </AmcText>
         <AmcText class="form-group mt-3" fwBold>
            <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.E-Posta Adresi') }}</AmcText>
            <input
               @keyup="manager.isValid()"
               :class="{ 'border-danger': manager.errors.has('email') }"
               v-model="manager.email"
               class="py-2 form-control shadow-none w-100"
            />
            <span
               class="text-danger fs-12"
               :key="i"
               v-for="(error, i) in manager.errors.get('email')"
               v-html="error"
            ></span>
         </AmcText>
      </AmcText>
      <AmcText v-show="step === 2" class="pb-5">
         <AmcText class="form-group mt-4 pb-5">
            <label class="form-label fw-600">{{ $t('user.modal.Telefon Numarsı') }}</label>
            <VuePhoneNumberInput
               class="pb-5"
               default-country-code="TR"
               v-model="manager.phone_nonformatted"
               formatInternational
               @keyup="manager.isValid()"
               :class="{ 'border-danger': manager.errors.has('phone_nonformatted') }"
            />
            <span
               class="text-danger fs-12"
               :key="i"
               v-for="(error, i) in manager.errors.get('phone_nonformatted')"
               v-html="error"
            ></span>
         </AmcText>
      </AmcText>
      <AmcText v-show="step === 3">
         <AmcText class="form-group mt-3" fwBold>
            <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.Şifre') }}</AmcText>
            <input
               @keyup="manager.isValid()"
               :class="{ 'border-danger': manager.errors.has('password') }"
               type="password"
               v-model="manager.password"
               class="w-100 form-control shadow-none py-2"
            />
            <span
               class="text-danger fs-12"
               :key="i"
               v-for="(error, i) in manager.errors.get('password')"
               v-html="error"
            ></span>
         </AmcText>
      </AmcText>
      <template #actions>
         <AmcText>
            <button
               v-if="step !== totalStep"
               type="button"
               class="btn bg-transparent text-black border px-5 py-2 me-2"
               data-bs-dismiss="modal"
               @click="reset"
            >
               {{ $t('modalActions.Vazgeç') }}
            </button>
            <button
               v-if="step === totalStep"
               type="button"
               class="btn bg-transparent text-black border px-5 py-2 me-2"
               data-bs-dismiss="modal"
               @click="reset"
            >
               {{ $t('modalActions.Kapat') }}
            </button>
            <button v-if="step !== 1" @click="previousStep" class="btn btn-secondary px-5 py-2">
               {{ $t('modalActions.Geri') }}
            </button>
         </AmcText>
         <button v-if="step !== 3" @click="nextStep" class="btn btn-primary px-5 py-2">
            {{ $t('modalActions.Devam') }}
         </button>
         <button v-if="step === 3" @click="emitCreate" class="btn btn-primary px-5 py-2">
            {{ $t('modalActions.Kaydet') }}
         </button>
      </template>
   </AmcModal>
</template>

<script>
import get from 'lodash/get';
import replace from 'lodash/replace';

import Form from '@/libs/form';
import startsWith from 'lodash/startsWith';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
   name: 'CreateUserModal',
   components: {
      VuePhoneNumberInput,
   },
   data() {
      return {
         step: 1,
         totalStep: 3,
         manager: Form.create(['name', 'email', 'phone', 'phone_nonformatted', 'password'])
            .validate({
               name: 'required',
               email: 'required',
               phone: 'required',
               phone_nonformatted: 'required',
               password: 'required',
            })
            .defaults({
               name: '',
               email: '',
               phone: '',
               phone_nonformatted: '',
               password: '',
               role: 'super-admin',
            }),
      };
   },
   methods: {
      checkValidation() {
         const validationKeyMapping = {
            1: ['name', 'email'],
            2: ['phone_nonformatted'],
            3: ['password'],
         };

         return this.manager.isValid({
            disableDirtyCheck: true,
            only: get(validationKeyMapping, this.step),
         });
      },
      previousStep: function () {
         this.step--;
      },
      nextStep: function () {
         if (this.checkValidation()) this.step++;
      },
      reset() {
         this.step = 1;
         this.manager.reset();
      },
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.step = 1;
         this.manager.reset();
         this.$refs.modal.hide();
         this.$emit('hide');
         return;
      },
      emitCreate() {
         if (!this.checkValidation()) return;
         this.manager.phone = replace(this.manager.phone_nonformatted, /\s/g, '');

         let haveZero = startsWith(this.manager.phone, '0');
         if (haveZero) {
            this.manager.phone = '+9' + this.manager.phone;
         } else {
            this.manager.phone = '+90' + this.manager.phone;
         }
         this.$emit('create', this.manager);
      },
   },
};
</script>

<style scoped lang="scss">
.step-number {
   font-size: 20px;
   font-weight: 600;
   height: 50px;
   width: 50px;
   background-color: #ced4da;
   display: flex;
   justify-content: center;
   border-radius: 100%;
}
</style>
