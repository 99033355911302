<template>
   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M-6.99382e-07 16L-8.74228e-08 2C-3.91405e-08 0.89543 0.89543 -7.47665e-07 2 -6.99382e-07L6 -5.24537e-07L6 2L2 2L2 16L16 16L16 2L12 2L12 -2.62268e-07L16 -8.74228e-08C17.1046 -3.91405e-08 18 0.89543 18 2L18 16C18 17.1046 17.1046 18 16 18L2 18C0.895429 18 -7.47665e-07 17.1046 -6.99382e-07 16ZM5 8L8 8L8 -4.37114e-07L10 -3.49691e-07L10 8L13 8L9 13L5 8Z"
         fill="#137AF6"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconExport',
};
</script>
