<template>
   <AmcText class="rounded-8 shadow p-4">
      <AmcText class="d-flex justify-content-between">
         <AmcText class="fs-4 align-self-center">{{ $t('projects.detail.cihazlar.Cihaz Listesi') }}</AmcText>
         <AmcText class="d-flex">
            <AmcInput
               :icon="IconSearch"
               width="300"
               :placeholder="$t('placeholders.Arama')"
               v-model="search"
            ></AmcInput>
            <AmcButton @click="showModal" :icon="IconAdded" ps="3" px="5" height="38px" class="text-white ms-4">
               {{ $t('buttons.Yeni Cihaz Ekle') }}
            </AmcButton>
         </AmcText>
      </AmcText>
      <AmcText v-show="!loading" class="mt-4 border-top">
         <AmcText as="table" class="table-striped table-borderless table">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText
                     v-for="(col, index) in headers"
                     :key="index"
                     as="th"
                     class="text-uppercase text-secondary text-start"
                  >
                     {{ $t(`tableTitle.${col.title}`) }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText
                  as="tr"
                  v-for="(modem, index) in $options.filters.filterTableData(dataModem, headers, search).data"
                  :key="index"
               >
                  <AmcText as="td"> {{ get(modem, 'serial_number') }} </AmcText>
                  <AmcText as="td"> {{ get(modem, 'device.name') }} </AmcText>
                  <AmcText as="td"> {{ get(modem, 'building.short_name') }} </AmcText>
                  <AmcText as="td"> {{ get(modem, 'stf_id') }} </AmcText>
                  <AmcText as="td"> {{ moment(get(modem, 'stf_date')).format('DD-MM-YYYY HH:MM:SS') }} </AmcText>
                  <AmcText as="td"> {{ get(modem, 'irs_no') }} </AmcText>
                  <AmcText as="td"> {{ moment(get(modem, 'irs_date')).format('DD-MM-YYYY HH:MM:SS') }} </AmcText>
                  <AmcText as="td"> {{ get(modem, 'invoice_number') }} </AmcText>
                  <AmcText as="td"> {{ moment(get(modem, 'invoice_date')).format('DD-MM-YYYY HH:MM:SS') }} </AmcText>
                  <AmcText as="td">
                     <div class="form-check form-switch">
                        <input
                           @change="editModem(modem)"
                           v-model="modem.is_active"
                           class="form-check-input"
                           type="checkbox"
                           id="flexSwitchCheckDefault"
                        />
                     </div>
                  </AmcText>
                  <AmcText as="td">
                     <AmcText as="button" @click="showRemoveModal(modem)">
                        <IconDel />
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcLoading v-if="loading" />
      <AmcModal xl ref="createModal">
         <template #header>
            <AmcText class="fs-3 py-3">{{ $t('projects.detail.cihazlar.modal.Yeni Cihaz Ekle') }}</AmcText>
         </template>
         <template>
            <AmcText class="mb-3"
               ><AmcText class="fw-600 text-secondary mb-3"
                  >{{ $t('projects.detail.cihazlar.modal.Cihaz Türü') }}
               </AmcText>
               <select
                  v-model="newModem.device_id"
                  class="form-select shadow-none border-2 py-2"
                  aria-label="Default select example"
               >
                  <option disabled selected>Proje Seçiniz</option>
                  <option :value="device.id" v-for="(device, index) in deviceType.data" :key="index">
                     {{ device.name }}
                  </option>
               </select>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="fw-600 text-secondary mb-3"> STF No. / Tarih </AmcText>
               <AmcText class="d-flex">
                  <input type="text" class="form-control me-5" v-model="newModem.stf_id" />
                  <VueCtkDateTimePicker
                     v-model="newModem.stf_date"
                     :locale="lang"
                     no-shortcuts
                     format="YYYY-MM-DD"
                     only-date
                  >
                  </VueCtkDateTimePicker>
               </AmcText>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="fw-600 text-secondary mb-3"
                  >{{ $t('projects.detail.cihazlar.modal.İrsaliye No / Tarih') }}
               </AmcText>
               <AmcText class="d-flex">
                  <input type="text" class="form-control me-5" v-model="newModem.irs_no" />

                  <VueCtkDateTimePicker
                     v-model="newModem.irs_date"
                     :locale="lang"
                     no-shortcuts
                     format="YYYY-MM-DD"
                     only-date
                  >
                  </VueCtkDateTimePicker>
               </AmcText>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="fw-600 text-secondary mb-3"
                  >{{ $t('projects.detail.cihazlar.modal.Fatura No / Tarih') }}
               </AmcText>
               <AmcText class="d-flex">
                  <input type="text" class="form-control me-5" v-model="newModem.invoice_number" />

                  <VueCtkDateTimePicker
                     v-model="newModem.invoice_date"
                     :locale="lang"
                     no-shortcuts
                     format="YYYY-MM-DD"
                     only-date
                  >
                  </VueCtkDateTimePicker>
               </AmcText>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="fw-600 text-secondary mb-3">
                  {{ $t('projects.detail.cihazlar.modal.Seri Numarası') }}
               </AmcText>
               <input type="text" class="form-control me-5" v-model="newModem.serial_number" />
            </AmcText>
         </template>
         <template #actions>
            <AmcText>
               <button @click="reset" data-bs-dismiss="modal" class="btn btn-outline-secondary px-5 py-2">
                  {{ $t('modalActions.Vazgeç') }}
               </button>
            </AmcText>
            <AmcText>
               <button @click="createModem" data-bs-dismiss="modal" class="btn btn-primary px-5 py-2">
                  {{ $t('modalActions.Kaydet') }}
               </button>
            </AmcText>
         </template>
      </AmcModal>
      <AmcRemoveModal ref="deleteModal" @remove="removeModem" />
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import DeviceTypes from '@/api/DeviceTypes';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import ProjectModemApi from '@/api/ProjectModemApi';
import IconAdded from '@/components/icons/IconAdded';
import IconSearch from '@/components/icons/header/IconSearch';

export default {
   name: 'DetailDevices',
   components: {
      IconDel,
   },
   data() {
      return {
         lang: localStorage.getItem('lang'),
         IconSearch,
         IconAdded,
         dataModem: {},
         search: '',
         headers: [
            { title: 'ID', value: 'serial_number' },
            { title: 'MODEM TÜRÜ', value: 'device.name' },
            { title: 'BİNA', value: 'building.short_name' },
            { title: 'STF NO', value: 'stf_id' },
            { title: 'STF TARİH', value: 'stf_date' },
            { title: 'İRSALİYE NO', value: 'irs_no' },
            { title: 'İRSALİYE TARİHİ', value: 'irs_date' },
            { title: 'FATURA NO', value: 'invoice_number' },
            { title: 'FATURA TARİHİ', value: 'invoice_date' },
            { title: 'AKTİF', value: '' },
         ],
         loading: false,
         newModem: Form.create([
            'device_id',
            'stf_id',
            'stf_date',
            'irs_no',
            'irs_date',
            'invoice_number',
            'invoice_date',
            'serial_number',
         ]),
         deviceType: {},
      };
   },
   methods: {
      get,
      async loadDeviceType() {
         this.deviceType = await DeviceTypes.index();
      },
      async getModems() {
         this.loading = true;
         try {
            await ProjectModemApi.index(this.$route.params.id).then((data) => {
               this.dataModem = data;
               this.loading = false;
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async createModem() {
         try {
            await ProjectModemApi.CREATE_MODEM(this.$route.params.id, this.newModem.toObject()).then(() => {
               Notify.success(this.$t('notify.Modem Eklendi'));
               this.getModems();
               this.reset();
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editModem(item) {
         try {
            await ProjectModemApi.EDIT_MODEM(this.$route.params.id, item.id, item);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeModem(item) {
         try {
            await ProjectModemApi.DELETE_MODEM(this.$route.params.id, item.id).then(() => {
               this.getModems();
               Notify.error(this.$t('notify.Modem Silindi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showModal() {
         this.$refs.createModal.show();
         this.loadDeviceType();
      },
      reset() {
         this.newModem.reset();
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   async beforeMount() {
      await this.getModems();
   },
};
</script>

<style scoped></style>
