<template>
   <AmcText>
      <AmcText class="shadow-sm p-4 rounded-8 mt-4">
         <AmcText v-if="!loadData" class="d-flex justify-content-between">
            <AmcText class="input-group mb-3">
               <AmcInput :icon="IconSearch" py="2" :placeholder="$t('placeholders.Arama')" v-model="search" />
            </AmcText>
            <AmcButton @click="showModal" class="text-white" :icon="IconAdded" ps="3" px="4" height="40px"
               >{{ $t('buttons.Yeni Kullanıcı Ekle') }}
            </AmcButton>
         </AmcText>
         <AmcText v-if="!loadData" as="table" class="table-striped table-borderless table">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText
                     v-for="(col, index) in headers"
                     :key="index"
                     as="th"
                     class="text-uppercase text-secondary"
                     :class="{ 'text-center': col.title === 'proje' }"
                  >
                     <span> {{ $t(`tableTitle.${col.title}`) }} </span>
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText
                  as="tr"
                  class="table-row"
                  v-for="(manager, index) in $options.filters.filterTableData(projectManager, headers, search).data"
                  :key="index"
               >
                  <AmcText as="td" class="table-item">
                     <AmcText class="my-3 fw-bold" v-text="manager.name" />
                  </AmcText>
                  <AmcText as="td" class="table-item">
                     <AmcText class="my-3" v-text="manager.email" />
                  </AmcText>
                  <AmcText as="td" class="table-item">
                     <AmcText class="my-3" v-text="manager.phone" />
                  </AmcText>
                  <AmcText as="td" class="table-item">
                     <AmcText class="my-3" v-for="projects in manager.projects" :key="projects.id">
                        <span class="fw-bold">
                           {{ projects.name }}
                        </span>
                     </AmcText>
                  </AmcText>
                  <AmcText as="th" class="table-item">
                     <button class="mt-3" @click="showRemoveModal(manager)">
                        <IconDel />
                     </button>
                     <button class="ms-2" @click="showManagerEditModal(manager)">
                        <IconEdit />
                     </button>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
         <!-- Creat Modal Start -->
         <AmcModal ref="createModal" lg>
            <template #header class="d-flex">
               <AmcText inline class="text-black h4 py-3" fwBold> {{ $t('user.modal.Yeni Kullanıcı Ekle') }}</AmcText>
               <AmcText class="d-flex">
                  <AmcText class="d-flex">
                     <AmcText :class="{ 'bg-primary': step === 1 || 2 || 3 }" class="step-number align-self-center">
                        <span :class="{ 'text-white': step !== 1 || 2 || 3 }" class="d-flex align-self-center">1</span>
                     </AmcText>
                     <AmcText
                        :class="{ 'text-primary': step !== 1 || 2 || 3 }"
                        class="align-self-center mx-3 fs-5 fw-500"
                     >
                        {{ $t('user.modal.Kişisel Bilgileri') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="d-flex">
                     <AmcText :class="{ 'bg-primary': step !== 1 }" class="step-number align-self-center">
                        <span :class="{ 'text-white': step !== 1 }" class="d-flex align-self-center">2</span>
                     </AmcText>
                     <AmcText :class="{ 'text-primary': step !== 1 }" class="align-self-center mx-3 fw-500 fs-5">
                        {{ $t('user.modal.Proje') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="d-flex">
                     <AmcText :class="{ 'bg-primary': step === 3 }" class="step-number align-self-center">
                        <span :class="{ 'text-white': step === 3 }" class="d-flex align-self-center">3</span>
                     </AmcText>
                     <AmcText :class="{ 'text-primary': step === 3 }" class="align-self-center mx-3 fw-500 fs-5">
                        {{ $t('user.modal.Şifre') }}
                     </AmcText>
                  </AmcText>
               </AmcText>
            </template>
            <AmcText v-show="step === 1">
               <AmcText class="form-group mt-3" fwBold>
                  <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.Adı Soyadı') }}</AmcText>
                  <input
                     @keyup="manager.isValid()"
                     :class="{ 'border-danger': manager.errors.has('name') }"
                     ref="name"
                     v-model="manager.name"
                     class="w-100 py-2 form-control shadow-none"
                  />
                  <span
                     class="text-danger fs-12"
                     :key="i"
                     v-for="(error, i) in manager.errors.get('name')"
                     v-html="error"
                  ></span>
               </AmcText>
               <AmcText>
                  <label class="form-label fw-600">{{ $t('user.modal.Telefon Numarsı') }}</label>
                  <VuePhoneNumberInput
                     @keyup="manager.isValid()"
                     :class="{ 'border-danger': manager.errors.has('phone_nonformatted') }"
                     default-country-code="TR"
                     v-model="manager.phone_nonformatted"
                     formatInternational
                  />
                  <span
                     class="text-danger fs-12"
                     :key="i"
                     v-for="(error, i) in manager.errors.get('phone_nonformatted')"
                     v-html="error"
                  ></span>
               </AmcText>
               <AmcText class="form-group mt-3" fwBold>
                  <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.E-Posta Adresi') }}</AmcText>
                  <input
                     type="email"
                     v-model="manager.email"
                     class="w-100 form-control py-2 shadow-none"
                     @keyup="manager.isValid()"
                     :class="{ 'border-danger': manager.errors.has('email') }"
                  />
                  <span
                     class="text-danger fs-12"
                     :key="i"
                     v-for="(error, i) in manager.errors.get('email')"
                     v-html="error"
                  ></span>
               </AmcText>
            </AmcText>
            <AmcText v-show="step === 2" class="pb-5">
               <AmcText class="form-group mt-4 pb-5">
                  <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.Proje') }}</AmcText>
                  <VueSelect v-model="manager.projects" :options="allProjects" label="name" multiple />
                  <span
                     class="text-danger fs-12"
                     :key="i"
                     v-for="(error, i) in manager.errors.get('projects')"
                     v-html="error"
                  ></span>
               </AmcText>
            </AmcText>
            <AmcText v-show="step === 3">
               <AmcText class="form-group mt-3" fwBold>
                  <AmcText class="fs-16 mb-2" fwBold>{{ $t('user.modal.Şifre') }}</AmcText>
                  <input
                     @keyup="manager.isValid()"
                     :class="{ 'border-danger': manager.errors.has('password') }"
                     type="password"
                     v-model="manager.password"
                     class="w-100 form-control py-2 shadow-none"
                  />
                  <span
                     class="text-danger fs-12"
                     :key="i"
                     v-for="(error, i) in manager.errors.get('password')"
                     v-html="error"
                  ></span>
               </AmcText>
            </AmcText>
            <template #actions>
               <AmcText>
                  <button
                     v-if="step !== totalStep"
                     type="button"
                     class="btn bg-transparent text-black border px-5 py-2 me-2"
                     data-bs-dismiss="modal"
                     @click="close()"
                  >
                     {{ $t('modalActions.Vazgeç') }}
                  </button>
                  <button v-if="step !== 1" @click="previousStep" class="btn btn-secondary px-5 py-2">
                     {{ $t('modalActions.Geri') }}
                  </button>
               </AmcText>
               <button v-if="step !== 3" @click="nextStep" class="btn btn-primary px-5 py-2">
                  {{ $t('modalActions.Devam') }}
               </button>
               <button v-if="step === 3" @click="creatProjectManager" class="btn btn-primary px-5 py-2">
                  {{ $t('modalActions.Kaydet') }}
               </button>
            </template>
         </AmcModal>
         <!-- Creat Modal Finish -->
         <AmcRemoveModal ref="deleteModal" @remove="removeManager" />
         <ProjectManagerModal @edit="editManager" ref="managerModal" />
         <AmcLoading v-if="loadData" />
      </AmcText>
      <router-view></router-view>
   </AmcText>
</template>

<script>
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import UserApi from '@/api/UserApi';
import ProjectsApi from '@/api/ProjectsApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconAdded from '@/components/icons/IconAdded';
import VuePhoneNumberInput from 'vue-phone-number-input';
import IconSearch from '@/components/icons/header/IconSearch';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import ProjectManagerModal from './_modals/ProjectManagerModal';
import IconEdit from '@/components/icons/IconEdit';

export default {
   name: 'ProjectManagerUser',
   components: {
      IconDel,
      IconEdit,
      ProjectManagerModal,
      VuePhoneNumberInput,
   },
   data() {
      return {
         IconSearch,
         IconAdded,
         search: '',
         allProjects: [],
         projectManager: [],
         loadData: false,
         headers: [
            { title: 'ADI SOYADI', value: 'name' },
            { title: 'E-POSTA', value: 'email' },
            { title: 'TELEFON', value: 'phone' },
            { title: 'PROJE' },
            { title: 'DÜZENLE' },
         ],
         manager: Form.create(['name', 'phone_nonformatted', 'phone', 'email', 'projects', 'password', 'role'])
            .validate({
               name: 'required',
               phone_nonformatted: 'required',
               phone: 'required',
               email: 'required',
               projects: 'required',
               password: 'required',
            })
            .defaults({
               name: '',
               phone_nonformatted: '',
               phone: '',
               email: '',
               projects: [],
               password: '',
               role: 'project-admin',
            }),
         step: 1,
         totalStep: 3,
         modalHeader: [
            { step: '1', stepText: 'Kişisel' },
            { step: '2', stepText: 'Proje' },
            { step: '3', stepText: 'Şifre' },
         ],
         selectedID: '',
      };
   },
   methods: {
      get,
      map,
      checkValidation() {
         const validationKeyMapping = {
            1: ['name', 'phone_nonformatted', 'email'],
            2: ['projects'],
            3: ['password'],
         };

         return this.manager.isValid({
            disableDirtyCheck: true,
            only: get(validationKeyMapping, this.step),
         });
      },
      async loadProjects() {
         this.loadData = true;

         try {
            const { data } = await ProjectsApi.index();
            this.allProjects = map(data, (item) => pick(item, ['id', 'name']));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
      async loadProjectManager() {
         try {
            await UserApi.USER_PROJECT_MANAGER().then((data) => (this.projectManager = data));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async creatProjectManager() {
         this.loadData = true;
         if (!this.checkValidation()) return;

         this.manager.phone = replace(this.manager.phone_nonformatted, /\s/g, '');

         let haveZero = startsWith(this.manager.phone, '0');
         if (haveZero) {
            this.manager.phone = '+9' + this.manager.phone;
         } else {
            this.manager.phone = '+90' + this.manager.phone;
         }

         const payload = this.manager.toObject();

         payload.projects = map(payload.projects, (item) => item.id);

         try {
            await UserApi.CREATE_PROJECT_MANAGER(payload);
            Notify.success(this.$t('notify.Proje Yöneticisi Eklendi'));
            await this.loadProjectManager();
            this.close();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
      async removeManager(item) {
         try {
            await UserApi.REMOVE_MANAGER(item.id);
            Notify.error(this.$t('notify.Proje yöneticisi silindi'));
            await this.loadProjectManager();
         } catch (e) {
            ErrorHandler.handle(e);
         }
      },

      async editManager(item) {
         try {
            await UserApi.EDIT_MANAGER(item.id, item);
            Notify.success(this.$t('notify.Bilgiler güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
      showModal(item) {
         this.$refs.createModal.show(item);
      },
      showManagerEditModal(item) {
         this.$refs.managerModal.show(item);
      },
      previousStep() {
         this.step--;
      },
      nextStep() {
         if (this.checkValidation()) this.step++;
      },
      reset() {
         this.manager.reset();
         this.step = 1;
      },
      close() {
         this.reset();
         this.$refs.createModal.hide();
      },
   },
   async beforeMount() {
      await this.loadProjectManager();
      await this.loadProjects();
   },
};
</script>

<style scoped lang="scss">
.input-group {
   width: 300px;
}

.table {
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
}

.modal-header {
   .badge {
      border-radius: 100%;
      background-color: #e6e7e9;
      padding: 10px 12px;
      font-size: 14px;
      margin-right: 8px;
   }
}
.step-number {
   font-size: 20px;
   font-weight: 600;
   height: 50px;
   width: 50px;
   background-color: #ced4da;
   display: flex;
   justify-content: center;
   border-radius: 100%;
}
</style>
