<template>
   <svg width="8" height="6" viewBox="0 0 8 6" fill="#606F89" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6L0.535899 -6.52533e-07L7.4641 -4.68497e-08L4 6Z" />
   </svg>
</template>

<script>
export default {
   name: 'IconDownArrow2',
};
</script>

<style scoped></style>
