<template>
   <svg
      :class="IconClass"
      stroke="#7D90B2"
      width="20"
      height="20"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M7.7093 2.5813H2.49095C2.09553 2.5813 1.7163 2.73838 1.43669 3.01799C1.15708 3.2976 1 3.67683 1 4.07225V14.5089C1 14.9044 1.15708 15.2836 1.43669 15.5632C1.7163 15.8428 2.09553 15.9999 2.49095 15.9999H12.9276C13.3231 15.9999 13.7023 15.8428 13.9819 15.5632C14.2615 15.2836 14.4186 14.9044 14.4186 14.5089V9.29059"
         stroke-width="1.5"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path
         d="M13.3002 1.46318C13.5967 1.16661 13.999 1 14.4184 1C14.8378 1 15.24 1.16661 15.5366 1.46318C15.8332 1.75975 15.9998 2.16198 15.9998 2.5814C15.9998 3.00081 15.8332 3.40304 15.5366 3.69961L8.45457 10.7816L5.47266 11.5271L6.21813 8.54521L13.3002 1.46318Z"
         stroke-width="1.5"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconEdit',
   props: {
      IconClass: {
         type: [String],
         default: '',
      },
   },
};
</script>
<style scoped>
svg:hover {
   stroke: #0a65ff;
}
</style>
