import Http from '../libs/http';

class ModemApi extends Http {
   index(ID, payload) {
      return super.post(`api/panel/building/${ID}/notification/send`, payload);
   }
   EDIT_NOTIFICATIONS(ID, payload) {
      return super.post(`api/panel/building/${ID}/notifications`, payload);
   }
}

export default new ModemApi();
