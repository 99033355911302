<template>
   <AmcText>
      <AmcText v-if="authority">
         <AmcText class="border-bottom modem-header">
            <AmcText fsXlarge class="ps-4 pb-3">{{ $t('modems.Modemler') }}</AmcText>
         </AmcText>
         <AmcText v-if="!loadModem" class="shadow-sm py-4 rounded-8 mt-4">
            <AmcText class="modem-create-inputs d-flex justify-content-between pb-4">
               <AmcText class="ms-4">
                  <AmcInput
                     py="2"
                     width="244px"
                     secondary
                     :placeholder="$t('placeholders.Cihaz Kodu')"
                     v-model="addModem.name"
                  />
               </AmcText>
               <AmcText class="ms-4">
                  <AmcInput
                     py="2"
                     width="175px"
                     secondary
                     :placeholder="$t('placeholders.Özellikler')"
                     v-model="addModem.description"
                  />
               </AmcText>
               <AmcText class="ms-4">
                  <AmcInput
                     type="number"
                     py="2"
                     width="147px"
                     secondary
                     placeholder="Input"
                     v-model="addModem.number_contact_in"
                  />
               </AmcText>
               <AmcText class="ms-4">
                  <AmcInput
                     type="number"
                     py="2"
                     width="147px"
                     secondary
                     placeholder="Output"
                     v-model="addModem.number_contact_out"
                  />
               </AmcText>
               <AmcText class="ms-4 checkbox-content d-flex">
                  <AmcText class="my-auto mx-auto">
                     <label class="form-check-label text-secondary mt-1" for="RS232">RS232</label>
                     <input
                        @change="addModem.rs232_enabled = !addModem.rs232_enabled"
                        :v-model="addModem.rs232_enabled"
                        class="form-check-input ms-3 shadow-none"
                        type="checkbox"
                        id="RS232"
                     />
                  </AmcText>
               </AmcText>
               <AmcText class="ms-4 checkbox-content d-flex">
                  <AmcText class="my-auto mx-auto">
                     <label class="form-check-label text-secondary mt-1" for="RS485">RS484</label>
                     <input
                        @change="addModem.rs485_enabled = !addModem.rs485_enabled"
                        class="form-check-input ms-3 shadow-none"
                        type="checkbox"
                        :v-model="addModem.rs485_enabled"
                        id="RS485"
                     />
                  </AmcText>
               </AmcText>
               <AmcText class="ms-4">
                  <AmcButton
                     @click="createModem(addModem)"
                     class="text-white me-3"
                     :icon="IconAdded"
                     ps="3"
                     px="3"
                     height="35px"
                  >
                     {{ $t('buttons.Yeni Modem Ekle') }}
                  </AmcButton>
               </AmcText>
            </AmcText>
            <AmcText class="pt-3 border-top ps-4">
               <AmcText as="table" class="table-striped table-borderless table">
                  <AmcText as="thead">
                     <AmcText as="tr">
                        <AmcText
                           v-for="(col, index) in headers"
                           :key="index"
                           as="th"
                           class="text-secondary text-start table-header"
                        >
                           {{ $t(`tableTitle.${col.title}`) }}
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcText as="tbody">
                     <AmcText as="tr" class="table-row" v-for="modem in allModems" :key="modem.id">
                        <AmcText as="td" class="table-item">
                           <AmcText class="mt-2 fw-bold" v-text="modem.name" />
                        </AmcText>
                        <AmcText as="td" class="table-item ps-4">
                           <AmcText class="mt-2" v-text="modem.description" />
                        </AmcText>
                        <AmcText as="td" class="table-item">
                           <AmcText class="mt-2 text-center" v-text="modem.number_contact_in" />
                        </AmcText>
                        <AmcText as="td" class="table-item">
                           <AmcText class="mt-2 text-center" v-text="modem.number_contact_out" />
                        </AmcText>
                        <AmcText as="td" class="table-item">
                           <input
                              disabled
                              :checked="modem.rs232_enabled"
                              class="form-check-input ms-3 shadow-none text-center mt-2"
                              type="checkbox"
                           />
                        </AmcText>
                        <AmcText as="td" class="table-item">
                           <input
                              disabled
                              :checked="modem.rs485_enabled"
                              class="form-check-input ms-3 shadow-none text-center mt-2 rs485-input"
                              type="checkbox"
                           />
                        </AmcText>
                        <AmcText as="td" class="table-item">
                           <button class="mt-2" @click="showRemoveModal(modem)">
                              <IconDel />
                           </button>
                           <button class="ms-4" @click="showModal(modem)">
                              <IconEdit />
                           </button>
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
         <AmcRemoveModal ref="deleteModal" @remove="removeModem" />
         <EditModal @edit="editModem" ref="editModal" />
         <AmcLoading v-if="loadModem" />
      </AmcText>
      <AmcText v-if="!authority">
         <AuthorizationCheck />
      </AmcText>
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import DevicesApi from '@/api/DevicesApi';
import EditModal from './_Modals/EditModal';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';
import IconAdded from '@/components/icons/IconAdded';

export default {
   name: 'Modems',
   components: {
      IconDel,
      IconEdit,
      EditModal,
   },
   data() {
      return {
         IconAdded,
         allModems: [],
         loadModem: false,
         authority: false,
         headers: [
            { title: 'CİHAZ KODU' },
            { title: 'ÖZELLİKLERİ' },
            { title: 'INPUT' },
            { title: 'OUTPUT' },
            { title: 'RS232' },
            { title: 'RS485' },
            { title: 'DÜZENLE' },
         ],
         addModem: Form.create([
            'description',
            'name',
            'number_contact_in',
            'number_contact_out',
            'rs232_enabled',
            'rs485_enabled',
         ])
            .validate({
               description: 'required',
               name: 'required',
               number_contact_in: 'required',
               number_contact_out: 'required',
               rs232_enabled: 'required',
               rs485_enabled: 'required',
            })
            .defaults({
               description: '',
               name: '',
               number_contact_in: '',
               number_contact_out: '',
               rs232_enabled: false,
               rs485_enabled: false,
            }),
      };
   },
   methods: {
      async loadDevices() {
         this.loadModem = true;
         try {
            await DevicesApi.index().then(({ data }) => {
               this.allModems = data;
            });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadModem = false;
         }
      },
      async createModem() {
         try {
            await DevicesApi.CREATE_MODEM(this.addModem.toObject());
            await this.loadDevices();
            Notify.success(this.$t('notify.Modem Eklendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.addModem.reset();
         }
      },
      async editModem(item) {
         try {
            await DevicesApi.EDIT_MODEM(item.id, item).then(() => {
               this.loadDevices();
            });
            Notify.success(this.$t('notify.Modem Bilgileri Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeModem(item) {
         try {
            await DevicesApi.REMOVE_MODEM(item.id).then(() => {
               this.loadDevices();
            });
            Notify.error(this.$t('notify.Modem Silindi'));
         } catch (e) {
            ErrorHandler.handle(e);
         }
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
      showModal(item) {
         this.$refs.editModal.show(item);
      },
   },
   async beforeMount() {
      if (this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1) {
         this.authority = true;
         await this.loadDevices();
      }
   },
};
</script>

<style scoped lang="scss">
.table {
   .output-input {
      margin-right: 100px;
   }
   .rs485-input {
      margin-right: 200px;
   }
   .table-item {
      &:nth-child(5) {
         padding-left: 150px;
      }
   }
   .table-header {
      &:nth-child(2) {
         padding-left: 21px;
      }
      &:nth-child(3) {
         text-align: center !important;
      }
      &:nth-child(4) {
         text-align: center !important;
      }
      &:nth-child(5) {
         padding-left: 150px;
      }
   }
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
}
.modem-header {
   margin-left: -14px;
   margin-right: -14px;
}
.checkbox-content {
   border-radius: 5px;
   background-color: #f6f6f6;
   width: 100px;
   height: 35px;
}
.form-check-input {
   -webkit-appearance: none;
   background-color: #ffb5b5;
   border: none;
   padding: 8px;
   border-radius: 3px;
   position: relative;
   cursor: pointer;
   margin-bottom: 2px;
   width: 20px;
   height: 20px;

   &:checked {
      background-color: #2fa84f;
   }
}
.form-check-input:disabled {
   opacity: 1;
}
</style>
