import set from 'lodash/set';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import snakeCase from 'lodash/snakeCase';

import createAxiosClient from '@/libs/create-axios-client';

class Http {
   constructor() {
      each(['options', 'head', 'get', 'delete'], (method) =>
         set(Http.prototype, method, (url, options = { disableCaseConversion: false }) => {
            const client = createAxiosClient();

            url = `${this.resource}/${url}`;

            if (!options.disableCaseConversion) {
               options.params = reduce(
                  options.params,
                  (acc, value, key) => {
                     acc[snakeCase(key)] = value;

                     return acc;
                  },
                  {}
               );
            }

            delete options.disableCaseConversion;

            return client.request({ method, url, ...options });
         })
      );

      each(['post', 'put', 'patch'], (method) =>
         set(Http.prototype, method, (url, data, options = { disableCaseConversion: false }) => {
            const client = createAxiosClient();

            url = `${this.resource}/${url}`;

            if (!options.disableCaseConversion) {
               options.params = reduce(
                  options.params,
                  (acc, value, key) => {
                     acc[snakeCase(key)] = value;

                     return acc;
                  },
                  {}
               );
            }

            delete options.disableCaseConversion;

            return client.request({ method, url, data, ...options });
         })
      );
   }

   get resource() {
      return '';
   }
}

export default Http;
