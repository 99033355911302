<template>
   <AmcText>
      <AmcText>
         <AmcText class="d-flex justify-content-between">
            <AmcText fsXlarge> {{ $t('facilities.Tesisler') }} </AmcText>
         </AmcText>
         <AmcTable
            hover
            options
            striped
            :data="data | filterTableData(header, search)"
            :header="header"
            v-show="!stateLoading"
            @detail="detail"
            @remove="removeBuild"
            @pageChanged="loadFacilities"
            @perPageChanged="loadFacilities"
         >
            <template #header>
               <AmcText class="border-bottom mb-2 d-flex flex-row">
                  <AmcText class="input-group mb-3">
                     <input
                        v-model="search"
                        aria-describedby="basic-addon1"
                        class="form-control text-secondary"
                        :placeholder="$t('placeholders.Arama')"
                        type="text"
                     />
                     <span class="input-icon d-block position-absolute"> <IconSearch /> </span>
                  </AmcText>

                  <AmcButton
                     v-if="canCreateFacilities"
                     @click="showModal"
                     class="text-white ms-5"
                     :icon="IconAdded"
                     ps="3"
                     px="4"
                     height="40px"
                  >
                     {{ $t('buttons.Yeni Tesis Ekle') }}
                  </AmcButton>
                  <CreateFacilitiesModal ref="createModal" @create="create" />
                  <AmcText class="d-flex align-self-end align-self-center pb-2 ms-3">
                     <AmcText role="button" class="me-3 download-file align-self-center" @click="downloadReport">
                        <AmcText v-if="stateDownload" class="spinner-border spinner-border-sm" />
                        <IconDownload v-else />
                     </AmcText>
                     <AmcText as="button" class="mail-push" @click="pushEmail">
                        <AmcText v-if="stateEmail" class="spinner-border spinner-border-sm" />
                        <IconMailPush v-else />
                     </AmcText>
                  </AmcText>
               </AmcText>
            </template>
         </AmcTable>
      </AmcText>
      <AmcLoading v-if="stateLoading" />
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import Notify from '@/libs/notify';
import BuildingApi from '@/api/BuildingApi';
import ErrorHandler from '@/libs/error-handler';
import IconAdded from '@/components/icons/IconAdded';
import IconSearch from '@/components/icons/header/IconSearch';
import CreateFacilitiesModal from './_modals/CreateFacilitiesModal';
import IconDownload from '@/components/icons/IconDownload';
import IconMailPush from '@/components/icons/IconMailPush';

export default {
   name: 'Facilities',
   components: {
      IconSearch,
      CreateFacilitiesModal,
      IconDownload,
      IconMailPush,
   },
   data() {
      return {
         search: '',
         IconAdded,
         header: [
            { title: 'TESİS', value: 'short_name' },
            { title: 'PROJE', value: 'project.name' },
            { title: 'ÜLKE', value: 'country.name' },
            { title: 'ŞEHİR', value: 'city.name' },
            { title: 'KULLANIM', value: 'purpose.name' },
            { title: 'RİSK', value: 'risk.name' },
         ],
         data: {},
         canCreateFacilities: false,
         stateLoading: false,
         iconColor: false,
         stateEmail: false,
         stateDownload: false,
      };
   },
   methods: {
      // eslint-disable-next-line
      prepareParams() {
         let params = {
            page: get(this.$route.query, 'page', 1),
            per_page: get(this.$route.query, 'perPage', 10),
         };

         return params;
      },
      async loadFacilities() {
         const params = this.prepareParams();

         if (!params) return;
         try {
            this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});
            this.stateLoading = true;
            this.data = await BuildingApi.index(params);
            this.stateLoading = false;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeBuild(item) {
         try {
            await BuildingApi.REMOVE_BUILDING(item.id);
            Notify.error(this.$t('notify.Tesis silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            await this.loadFacilities();
         }
      },
      detail(item) {
         this.$router.push(`detail/${item.id}`);
      },
      async create(payload) {
         try {
            await BuildingApi.create(payload.toObject()).then(() => {
               Notify.success(this.$t('notify.Ekleme işlemi başarılı'));
            });
         } catch (e) {
            ErrorHandler.handle(e);
         } finally {
            await this.loadFacilities();
         }
      },
      showModal(item) {
         this.$refs.createModal.show(item);
      },
      async downloadReport() {
         const params = this.prepareParams();
         try {
            this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});
            this.stateDownload = true;
            const data = await BuildingApi.index({ ...params, download: true });
            window.open(data.file);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateDownload = false;
         }
      },
      async pushEmail() {
         const params = this.prepareParams();
         try {
            this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});
            this.stateEmail = true;
            await BuildingApi.index({ ...params, by_email: true });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateEmail = false;
         }
      },
   },
   async beforeMount() {
      if (
         this.$store.state.auth.authUser.roles.includes('super-admin') ||
         this.$store.state.auth.authUser.roles.includes('project-admin')
      ) {
         this.canCreateFacilities = true;
      }
      await this.loadFacilities();
   },
};
</script>

<style scoped lang="scss">
.input-group {
   width: 300px;

   & .form-control {
      padding: 8.5px 5px 8.5px 45px;
      border-radius: 5px !important;
      background-color: #e5efff;
      border: none !important;

      &:focus {
         box-shadow: none !important;
      }
   }

   & .input-icon {
      top: 9px;
      left: 11px;
      z-index: 20;
   }
}

.modal-header {
   .badge {
      border-radius: 100%;
      background-color: #e6e7e9;
      padding: 10px 12px;
      font-size: 14px;
      margin-right: 8px;
   }
}
</style>
