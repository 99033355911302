import 'dayjs/locale/tr';

import numeral from 'numeral';
import { mapGetters } from 'vuex';
import { formatDate, trFormatDate, trFormatDateLong } from '@/libs/date-utils';

export default {
   computed: {
      ...mapGetters('auth', ['isLoggedIn', 'authUser']),
      isAdmin() {
         return this.isDashboardPermission();
      },
      isSuperAdmin() {
         return this.userRoleCheck('super-admin');
      },
      isProjectAdmin() {
         return this.userRoleCheck('project-admin');
      },
      currencies() {
         return this.$store.state.settings.currencies;
      },
      siteTheme() {
         return this.$store.state.settings.siteTheme;
      },
   },
   methods: {
      pageChanged(page) {
         this.page = page;
      },
      customErrorMessages(errors) {
         for (const e in errors) {
            if (Array.isArray(errors[e])) {
               return errors[e][0];
            }
            return errors.message;
         }
      },
      siteLang: () => (window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'tr'),
      imgPath: (str) => `${process.env.STORAGE_URL}/${str}`,
      isDashboardPermission() {
         return this.userPermissionCheck('view-dashboard');
      },
      userRoleCheck(role) {
         if (this.user.roles) {
            return this.user.roles.includes(role);
         }
         return false;
      },
      userPermissionCheck(permission) {
         return this.userPermissions().includes(permission);
      },
      formatNumber(val, format) {
         return numeral(val).format(format);
      },
      userPermissions() {
         if (this.user.permissions) {
            return this.user.permissions;
         } else {
            return [];
         }
      },
      formatDate,
      trFormatDate,
      trFormatDateLong,
      waitTime() {
         return 600;
      },
   },
};
