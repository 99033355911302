<template>
   <svg stroke="#7D90B2" width="20" height="21" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4H2.5H14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
         d="M4.75 4V2.5C4.75 2.10218 4.90804 1.72064 5.18934 1.43934C5.47064 1.15804 5.85218 1 6.25 1H9.25C9.64782 1 10.0294 1.15804 10.3107 1.43934C10.592 1.72064 10.75 2.10218 10.75 2.5V4M13 4V14.5C13 14.8978 12.842 15.2794 12.5607 15.5607C12.2794 15.842 11.8978 16 11.5 16H4C3.60218 16 3.22064 15.842 2.93934 15.5607C2.65804 15.2794 2.5 14.8978 2.5 14.5V4H13Z"
         stroke-width="1.5"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path d="M6.25 7.75V12.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.25 7.75V12.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconDel',
};
</script>

<style scoped>
svg:hover {
   stroke: #ea3d2f;
}
</style>
