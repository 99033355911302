<template>
   <AmcModal lg ref="modal">
      <template #header>
         <AmcText class="fs-3"> {{ $t('dashboard.modal.Bildirim Gönder') }} </AmcText>
      </template>
      <template>
         <AmcText>
            <AmcText class="fs-4 fw-500"> {{ $t('dashboard.modal.Bildirim Mesajları') }} </AmcText>
            <AmcRow class="mt-4">
               <AmcCol role="button" col="4" v-for="message in messages" :key="message.id">
                  <AmcText>
                     <input
                        type="radio"
                        :id="`${message.id}-message`"
                        :value="message.content"
                        v-model="sendNotification.content"
                     />
                     <label class="ms-2" :for="`${message.id}-message`" v-html="message.title" />
                     <br />
                  </AmcText>
               </AmcCol>
               <AmcText class="mt-2 text-primary">
                  {{ sendNotification.content }}
               </AmcText>
            </AmcRow>
         </AmcText>
         <AmcText class="mt-4">
            <AmcText class="fs-4 fw-500"> {{ $t('dashboard.modal.Yetkililer') }} </AmcText>
            <AmcText>
               <AmcText as="table" class="table-striped table-borderless table border">
                  <AmcText as="thead">
                     <AmcText as="tr">
                        <AmcText as="th" class="text-secondary text-start" v-for="(row, index) in headers" :key="index">
                           {{ $t(`tableTitle.${row.title}`) }}
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcText as="tbody">
                     <AmcText as="tr" class="table-row" v-for="authoriti in authorities" :key="authoriti.id">
                        <AmcText as="td" class="table-item fw-500"> {{ get(authoriti, 'name') }} </AmcText>
                        <AmcText as="td" class="table-item" v-if="sendNotification.sms">
                           <AmcText class="form-check form-switch">
                              <input
                                 class="form-check-input"
                                 type="checkbox"
                                 :value="authoriti.id"
                                 v-model="sendNotification.sms"
                              />
                           </AmcText>
                        </AmcText>
                        <AmcText as="td" class="table-item" v-if="sendNotification.email">
                           <AmcText class="form-check form-switch">
                              <input
                                 class="form-check-input"
                                 type="checkbox"
                                 :value="authoriti.id"
                                 v-model="sendNotification.email"
                              />
                           </AmcText>
                        </AmcText>
                        <AmcText as="td" class="table-item" v-if="sendNotification.call">
                           <AmcText class="form-check form-switch">
                              <input
                                 class="form-check-input"
                                 type="checkbox"
                                 :value="authoriti.id"
                                 v-model="sendNotification.call"
                              />
                           </AmcText>
                        </AmcText>
                        <AmcText as="td" class="table-item" v-if="sendNotification.mobile">
                           <AmcText class="form-check form-switch">
                              <input
                                 class="form-check-input"
                                 type="checkbox"
                                 :value="authoriti.id"
                                 v-model="sendNotification.mobile"
                              />
                           </AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </template>
      <template #actions>
         <button @click="close" class="btn btn-outline-secondary px-5">{{ $t('modalActions.Vazgeç') }}</button>
         <button @click="postNotification(item.building.id)" class="btn btn-primary px-5">
            {{ $t('modalActions.Gönder') }}
         </button>
      </template>
   </AmcModal>
</template>

<script>
import map from 'lodash/map';
import get from 'lodash/get';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import LegalApi from '@/api/LegalApi';
import ErrorHandler from '@/libs/error-handler';
import ProjectMessagesApi from '@/api/ProjectMessagesApi';
import SendNotificationApi from '@/api/SendNotificationApi';

export default {
   name: 'NotificationModal',
   data() {
      return {
         loading: false,
         alarm: false,
         item: {},
         messages: [],
         authorities: [],
         headers: [{ title: 'ADI SOYADI' }],
         sendNotification: {
            content: null,
         },
      };
   },
   mounted() {},
   methods: {
      get,
      show(item) {
         this.item = item;
         this.$refs.modal.show();
         this.getProjectMessages(item.building.project_id);
         this.getAuthorities(item.building.id);

         this.headers = [{ title: 'ADI SOYADI' }];

         var methods = ['content'];
         var defaults = {
            content: null,
            sms: null,
            email: null,
            call: null,
            mobile: null,
         };

         if (this.item.building.project) {
            const project = this.item.building.project;
            if (project.sms_notification) {
               this.headers.push({ title: 'SMS' });
               methods.push('sms');
               defaults.sms = [];
            }
            if (project.email_notification) {
               this.headers.push({ title: 'E-POSTA' });
               methods.push('email');
               defaults.email = [];
            }
            if (project.call_notification) {
               this.headers.push({ title: 'ARAMA' });
               methods.push('call');
               defaults.call = [];
            }
            if (project.mobile_notification) {
               this.headers.push({ title: 'MOBİLE' });
               methods.push('mobile');
               defaults.mobile = [];
            }
         }

         this.sendNotification = Form.create(methods).defaults(defaults);
      },
      async getProjectMessages(item) {
         try {
            const response = await ProjectMessagesApi.index(item);
            this.messages = map(response.data, (item) => {
               item.select = false;
               return item;
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getAuthorities(item) {
         try {
            const response = await LegalApi.index(item);
            this.authorities = map(response.data, (item) => {
               item.sms = false;
               item.email = false;
               item.call = false;
               item.mobile = false;
               return item;
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async postNotification(item) {
         this.loading = true;
         const sms = get(this.sendNotification, 'sms.length');
         const call = get(this.sendNotification, 'call.length');
         const email = get(this.sendNotification, 'email.length');
         const mobile = get(this.sendNotification, 'mobile.length');
         if (email || sms || call || mobile) {
            try {
               await SendNotificationApi.index(item, this.sendNotification.toObject());
               Notify.success(this.$t('notify.Bildirim gönderilmiştir'));
               this.reset();
               this.close();
            } catch (err) {
               ErrorHandler.handle(err);
            } finally {
               this.loading = false;
            }
         } else {
            Notify.error(this.$t('notify.Lütfen bir yetkili seçiniz'));
            return;
         }
      },
      reset() {
         this.sendNotification.reset();
      },
      close() {
         this.$refs.modal.hide();
         this.reset();
      },
   },
};
</script>
