import Http from '../libs/http';

class DevicesApi extends Http {
   index() {
      return super.get('api/panel/devices');
   }
   CREATE_MODEM(payload) {
      return super.post('api/panel/devices', payload);
   }
   REMOVE_MODEM(ID) {
      return super.delete(`api/panel/devices/${ID}`);
   }
   EDIT_MODEM(ID, payload) {
      return super.put(`api/panel/devices/${ID}`, payload);
   }
}

export default new DevicesApi();
