<template>
   <AmcText class="p-4">
      <AmcText class="rounded-8 shadow-sm">
         <AmcText class="border-bottom">
            <AmcText class="py-4 px-5">
               <AmcText class="form-label fs-3"> {{ this.$store.state.auth.authUser.name }} </AmcText>
               <AmcText class="text-secondary"> {{ this.$store.state.auth.authUser.email }} </AmcText>
            </AmcText>
         </AmcText>
         <AmcText>
            <AmcText class="m-5">
               <AmcText class="pb-3">
                  <AmcText class="form-label fs-16 fw-500"> {{ $t('settings.Görevi') }} </AmcText>
                  <div v-for="(role, index) in this.$store.state.auth.authUser.roles" :key="index">
                     <input
                        v-show="role === 'super-admin'"
                        readonly
                        type="text"
                        class="form-control shadow-none"
                        v-model="rolePanel"
                     />
                     <input
                        v-show="role === 'project-admin'"
                        readonly
                        type="text"
                        class="form-control shadow-none"
                        v-model="roleProject"
                     />
                  </div>
               </AmcText>
               <AmcText class="pb-3">
                  <AmcText class="form-label fs-16 fw-500"> {{ $t('settings.Adı Soyadı') }}</AmcText>
                  <input type="text" class="form-control shadow-none" v-model="user.name" />
               </AmcText>
               <AmcText class="pb-3">
                  <AmcText class="form-label fs-16 fw-500"> {{ $t('settings.Telefon Numarası') }} </AmcText>
                  <input type="text" class="form-control shadow-none" v-model="user.phone" />
               </AmcText>
               <AmcText class="pb-3">
                  <AmcText class="form-label fs-16 fw-500"> {{ $t('settings.E-Posta Adresi') }} </AmcText>
                  <input type="email" class="form-control shadow-none" v-model="user.email" />
               </AmcText>
               <AmcText class="pb-3">
                  <AmcText class="form-label fs-16 fw-500"> {{ $t('settings.Ünvan') }} </AmcText>
                  <input readonly type="text" class="form-control shadow-none" v-model="title" />
               </AmcText>
               <AmcText class="pb-3">
                  <AmcText class="form-label fs-16 fw-500"> {{ $t('settings.Yeni Şifre') }} </AmcText>
                  <input
                     type="password"
                     class="form-control shadow-none"
                     v-model="user.password"
                     placeholder="******"
                  />
               </AmcText>
               <AmcText class="d-flex justify-content-end py-5">
                  <button @click="updatePassword" class="btn btn-primary ms-4 px-5">
                     {{ $t('modalActions.Kaydet') }}
                  </button>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcLoading v-if="loading" />
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import UserApi from '@/api/UserApi';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'Settings',
   data() {
      return {
         loading: false,
         rolePanel: this.$t('settings.Panel Yöneticisi'),
         roleProject: this.$t('settings.Proje Yöneticisi'),
         title: this.$t('settings.Yönetici'),
         user: Form.create(['name', 'phone', 'email', 'password']).defaults({
            password: '',
            name: this.$store.state.auth.authUser.name,
            phone: this.$store.state.auth.authUser.phone,
            email: this.$store.state.auth.authUser.email,
         }),
      };
   },
   methods: {
      async updatePassword() {
         this.loading = true;
         try {
            if (this.user.password.length > 0) {
               if (this.user.password.length < 6) {
                  Notify.error(this.$t('notify.Şifreniz en az 6 karekter olmalıdır'));
                  return;
               }
            }
            await UserApi.EDIT_MANAGER(this.$store.state.auth.authUser.id, this.user.toObject());
            Notify.success(this.$t('notify.Bilgileriniz Güncellendi'));
            setTimeout(() => {
               if (this.user.password.length >= 6) {
                  this.$store.dispatch('auth/logout', { redirectRoute: 'Login' });
               }
            }, 1000);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loading = false;
         }
      },
   },
};
</script>

<style scoped></style>
