<template>
   <svg width="28" height="28" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M30.625 0H4.375C1.96191 0 0 1.96191 0 4.375V24.0625C0 26.4756 1.96191 28.4375 4.375 28.4375H10.9375V34.1797C10.9375 34.665 11.334 35 11.7578 35C11.9219 35 12.0928 34.9521 12.2432 34.8359L20.7812 28.4375H30.625C33.0381 28.4375 35 26.4756 35 24.0625V4.375C35 1.96191 33.0381 0 30.625 0ZM31.7188 24.0625C31.7188 24.6641 31.2266 25.1562 30.625 25.1562H19.6875L18.8125 25.8125L14.2188 29.2578V25.1562H4.375C3.77344 25.1562 3.28125 24.6641 3.28125 24.0625V4.375C3.28125 3.77344 3.77344 3.28125 4.375 3.28125H30.625C31.2266 3.28125 31.7188 3.77344 31.7188 4.375V24.0625Z"
         fill="#82C91E"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconSms',
};
</script>

<style scoped></style>
