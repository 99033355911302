<template>
   <AmcText>
      <AmcText fsXlarge>Durum Monitörü</AmcText>
      <AmcTable
         v-if="!stateLoading"
         :data="data"
         :header="header"
         hover
         @initTable="initTable"
         @pageChanged="pageChanged"
         @perPageChanged="perPageChanged"
      >
         <template v-slot:header>
            <AmcText class="d-flex flex-row justify-content-between border-bottom pb-3">
               <AmcText class="d-flex flex-row">
                  <AmcDropdown Secondary inputName="Proje">
                     <template v-slot:dropdown-items>
                        <li class="dropdown-item">Obifi</li>
                        <li class="dropdown-item">Amc</li>
                     </template>
                  </AmcDropdown>
                  <AmcDropdown Secondary inputName="Tesis" class="ms-3">
                     <template v-slot:dropdown-items>
                        <li class="dropdown-item">Obifi</li>
                        <li class="dropdown-item">Amc</li>
                     </template>
                  </AmcDropdown>
                  <AmcDropdown Secondary inputName="Aralık" class="ms-3">
                     <template v-slot:dropdown-items>
                        <li class="dropdown-item">Obifi</li>
                        <li class="dropdown-item">Amc</li>
                     </template>
                  </AmcDropdown>
               </AmcText>
               <AmcButton :iconRight="IconExport" pe="3" class="bg-light-primary text-primary px-4 py-2"
                  >Seçilen <b>(1)</b> Dışa Aktar</AmcButton
               >
            </AmcText>
         </template>
         <template #actions>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
               <li><a class="dropdown-item" href="#">Sil</a></li>
               <li><a class="dropdown-item" href="#">Detay</a></li>
            </ul>
         </template>
      </AmcTable>
      <AmcText v-if="stateLoading" class="alert alert-info"
         >Tablo yükleniyor... <span class="spinner-border spinner-border-sm ms-3" role="status"></span
      ></AmcText>
   </AmcText>
</template>

<script>
import BuildingApi from '@/api/BuildingApi';
import IconExport from '@/components/icons/IconExport';

export default {
   name: 'StatusMonitor',
   data() {
      return {
         IconExport,
         header: [
            { title: 'TESİS ADI', value: 'short_name' },
            { title: 'PROJE ADI', value: 'project.name' },
            { title: 'ÜLKE', value: 'country.name' },
            { title: 'ŞEHİR', value: 'city.name' },
            { title: 'KULLANIM', value: 'purpose.name' },
            { title: 'DURUM', value: 'state' },
         ],
         data: {},
         stateLoading: false,
      };
   },
   async beforeMount() {
      await this.loadStatus();
   },
   methods: {
      // eslint-disable-next-line
      async loadStatus(params) {
         params = { ...this.$route.query, ...params };

         this.stateLoading = true;
         this.data = await BuildingApi.index(params);
         this.stateLoading = false;
      },
      async pageChanged(page) {
         await this.loadStatus({ page });
      },
      async perPageChanged(perPage) {
         await this.loadStatus({ per_page: perPage });
      },
      async initTable({ page, perPage }) {
         await this.loadStatus({ page, per_page: perPage });
      },
   },
};
</script>

<style scoped></style>
