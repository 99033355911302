<template>
   <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <circle
         cx="9.26738"
         cy="9.26738"
         r="8.26738"
         stroke="#919699"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="1.5"
      />
      <path
         d="M15 15L21 21"
         opacity="0.4"
         stroke="#919699"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="1.5"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconSearch',
};
</script>
