<template>
   <AmcText>
      <AmcText v-if="authority">
         <AmcText class="border-bottom d-flex justify-content-between">
            <AmcText fsLarge>{{ $t('definition.Tanımlamalar') }}</AmcText>
            <AmcText class="d-flex me-5 pe-5">
               <AmcText class="navbar-nav d-flex flex-row">
                  <router-link
                     to="/definition"
                     role="button"
                     class="nav-item me-5 text-secondary fw-bold text-decoration-none"
                  >
                     <AmcText :class="{ 'text-dark': this.$route.path === '/definition' }" class="item-text">{{
                        $t('definition.Kullanım Tipleri')
                     }}</AmcText>
                     <AmcText v-if="this.$route.path === '/definition'" class="active-bar" />
                  </router-link>
                  <router-link
                     to="/definition/risk-classes"
                     role="button"
                     class="nav-item me-5 text-secondary fw-bold text-decoration-none"
                  >
                     <AmcText
                        :class="{ 'text-dark': this.$route.path === '/definition/risk-classes' }"
                        class="item-text"
                        >{{ $t('definition.Risk Sınıfları') }}</AmcText
                     >
                     <AmcText v-if="this.$route.path === '/definition/risk-classes'" class="active-bar" />
                  </router-link>
                  <router-link
                     to="/definition/panel-brands"
                     role="button"
                     class="nav-item me-5 text-secondary fw-bold text-decoration-none"
                  >
                     <AmcText
                        :class="{ 'text-dark': this.$route.path === '/definition/panel-brands' }"
                        class="item-text"
                        >{{ $t('definition.Panel Markaları') }}</AmcText
                     >
                     <AmcText v-if="this.$route.path === '/definition/panel-brands'" class="active-bar" />
                  </router-link>
                  <router-link
                     to="/definition/locations"
                     role="button"
                     class="nav-item me-5 text-secondary fw-bold text-decoration-none"
                  >
                     <AmcText
                        :class="{ 'text-dark': this.$route.path === '/definition/locations' }"
                        class="item-text"
                        >{{ $t('definition.Lokasyonlar') }}</AmcText
                     >
                     <AmcText v-if="this.$route.path === '/definition/locations'" class="active-bar" />
                  </router-link>
               </AmcText>
            </AmcText>
            <AmcText class="me-5 pe-5"></AmcText>
         </AmcText>
         <AmcText v-on:keyup.enter="createdUsage" v-if="this.$route.path === '/definition'">
            <AmcCard v-if="!loadData" shadow Card p3 class="mt-4 d-flex justify-content-between">
               <AmcText class="align-self-center" fwBold> {{ $t('definition.Yeni Kullanım Tipi Tanımı') }}</AmcText>
               <AmcText class="d-flex flex-row">
                  <AmcInput
                     v-model="createUse.name"
                     width="300"
                     py="2"
                     secondary
                     :placeholder="$t('placeholders.Yeni Kullanım Tipi Ekle')"
                  ></AmcInput>
                  <AmcButton
                     @click="createdUsage"
                     class="text-white ms-3"
                     :icon="IconAdded"
                     ps="3"
                     px="3"
                     height="35px"
                  >
                     {{ $t('buttons.Yeni Kullanım Tipi Ekle') }}
                  </AmcButton>
               </AmcText>
            </AmcCard>
            <AmcCard v-if="!loadData" p3 Card shadow class="mt-4">
               <AmcText as="table" class="table-striped table-borderless table">
                  <AmcText as="thead">
                     <AmcText as="tr">
                        <AmcText
                           v-for="(col, index) in headers"
                           :key="index"
                           as="th"
                           class="text-secondary text-start table-header"
                        >
                           {{ $t(`tableTitle.${col.title}`) }}
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcText as="tbody">
                     <AmcText as="tr" class="table-row" v-for="use in usages" :key="use.id">
                        <AmcText as="th" class="table-item input-name">
                           <input type="text" class="table-input form-control mt-2 fw-bold" v-model="use.name" />
                        </AmcText>
                        <AmcText as="th" class="table-item text-center">
                           <button class="remove-icon mt-2" @click="showRemoveModal(use)"><IconDel /></button>
                           <button class="mt-2 ms-3" @click="editUsage(use)">
                              <IconEdit />
                           </button>
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcCard>
            <AmcRemoveModal ref="deleteModal" @remove="removeUsage" />
            <AmcLoading v-if="loadData" />
         </AmcText>
         <router-view></router-view>
      </AmcText>
      <AmcText v-if="!authority">
         <AuthorizationCheck />
      </AmcText>
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import UsagesApi from '@/api/UsagesApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';
import IconAdded from '@/components/icons/IconAdded';

export default {
   name: 'Definition',
   components: {
      IconDel,
      IconEdit,
   },
   data() {
      return {
         IconAdded,
         authority: false,
         usages: {},
         createUse: Form.create(['name']).validate({ name: 'required' }),
         editUse: Form.create(['name']).validate({ name: 'required' }),
         headers: [{ title: 'TANIMLAMA' }],
         loadData: false,
      };
   },
   methods: {
      async loadUsages() {
         this.loadData = true;
         try {
            UsagesApi.index().then(({ data }) => (this.usages = data));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
      async createdUsage() {
         try {
            await UsagesApi.CREATE_USAGES(this.createUse.toObject()).then(() => {
               this.loadUsages();
            });
            Notify.success(this.$t('notify.Kullanım tipi eklendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.createUse.name = '';
         }
      },
      async editUsage(item) {
         try {
            this.loadData = true;
            await UsagesApi.EDIT_USAGES(item.id, item).then(() => {
               this.loadUsages();
               this.loadData = false;
            });
            Notify.success(this.$t('notify.Kullanım tipi güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.editUse.name = '';
         }
      },
      async removeUsage(item) {
         try {
            await UsagesApi.REMOVE_USAGES(item.id).then(() => {
               this.loadUsages();
            });
            Notify.error(this.$t('notify.Kullanım tipi silindi'));
         } catch (err) {
            ErrorHandler.handler(err);
         }
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   async beforeMount() {
      if (this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1) {
         this.authority = true;
         await this.loadUsages();
      }
   },
};
</script>

<style scoped lang="scss">
.active-bar {
   height: 4px;
   background-color: #137af6;
   border-radius: 3px 3px 0 0;
   margin-top: 13px;
}
.item-text {
   padding-top: 25px;
   &:hover {
      color: #000000;
   }
}
.table {
   .table-header {
      &:nth-child(1) {
         padding-right: 400px;
         padding-left: 50px;
      }
   }
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
   & .table-row:focus-within {
      border: 1px solid #137af6;
      --bs-table-striped-bg: #ffffff;
   }
   & .input-name {
      padding-left: 50px;
   }
   & .table-input {
      border: none !important;
      background-color: transparent;
      padding-left: 0 !important;
      &:focus {
         box-shadow: none;
      }
   }
}
</style>
