<template>
   <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#E5EFFF" />
      <path
         d="M24 17.6667V20.5556C24 20.9387 23.8478 21.3061 23.5769 21.577C23.306 21.8479 22.9386 22.0001 22.5556 22.0001H12.4444C12.0614 22.0001 11.694 21.8479 11.4231 21.577C11.1522 21.3061 11 20.9387 11 20.5556V17.6667"
         stroke="#137AF6"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path
         d="M13.8887 14.0557L17.4998 17.6668L21.1109 14.0557"
         stroke="#137AF6"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path d="M17.5 17.6667V9" stroke="#137AF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconDownload',
};
</script>

<style scoped></style>
