<template>
   <AmcText>
      <AmcCard Card p3>
         <AmcText class="d-flex justify-content-between">
            <AmcText class="d-flex">
               <AmcText>
                  <select
                     class="form-select shadow-none"
                     aria-label="Default select example"
                     v-model="projectID"
                     @change="loadBuilding"
                  >
                     <option selected value="0">{{ $t('dropdowns.Tüm Projeler') }}</option>
                     <option :key="index" v-for="(project, index) in projectsData" :value="project.id">
                        {{ get(project, 'name') }}
                     </option>
                  </select>
               </AmcText>
               <AmcText>
                  <select class="form-select shadow-none ms-3" aria-label="Default select example" v-model="buildingID">
                     <option selected value="0">{{ $t('dropdowns.Tüm Tesisler') }}</option>
                     <option :key="index" v-for="(building, index) in buildingData" :value="building.id">
                        {{ get(building, 'name') }}
                     </option>
                  </select>
               </AmcText>
               <AmcText class="ms-4">
                  <AmcInput type="text" py="2" secondary :placeholder="$t('placeholders.Adı')" v-model="name" />
               </AmcText>
            </AmcText>
            <AmcText>
               <button class="btn btn-primary px-3" @click="filter">{{ $t('buttons.Filtrele') }}</button>
            </AmcText>
         </AmcText>
      </AmcCard>
      <AmcCard class="border rounded-8 shadow-sm mt-4">
         <AmcTable
            hover
            Border
            Center
            striped
            :data="data"
            ref="logTable"
            :header="header"
            @pageChanged="loadReports"
            @perPageChanged="loadReports"
         >
            <template #header>
               <AmcText class="d-flex align-self-center pb-4">
                  <AmcText role="button" class="me-3 download-file align-self-center" @click="downloadReport">
                     <AmcText v-if="stateDownload" class="spinner-border spinner-border-sm" />
                     <IconDownload v-else />
                  </AmcText>
                  <AmcText as="button" class="mail-push" @click="pushEmail">
                     <AmcText v-if="stateEmail" class="spinner-border spinner-border-sm" />
                     <IconMailPush v-else />
                  </AmcText>
               </AmcText>
            </template>
         </AmcTable>
      </AmcCard>
      <AmcLoading v-if="loading" />
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import LegalReportApi from '@/api/LegalReportApi';
import BuildingApi from '@/api/BuildingApi';
import ProjectsApi from '@/api/ProjectsApi';
import ErrorHandler from '@/libs/error-handler';
import IconDownload from '@/components/icons/IconDownload';
import IconMailPush from '@/components/icons/IconMailPush';

export default {
   name: 'ModemList',
   components: {
      IconDownload,
      IconMailPush,
   },
   data() {
      return {
         search: '',
         dateValue: null,
         header: [
            { title: 'ADI', value: 'name' },
            { title: 'E-POSTA', value: 'email' },
            { title: 'TELEFON', value: 'phone' },
            { title: 'PROJE ADI', value: 'project_name' },
            { title: 'TESİS ADI', value: 'building_name' },
         ],
         stateLoading: true,
         stateDownload: false,
         stateEmail: false,
         loading: false,
         data: {},
         projectsData: [{ project: {} }],
         projectID: 0,
         buildingData: [],
         buildingID: 0,
         name: null,
         query: {
            project_id: get(this.$route.query, 'project_id', null),
            building_id: get(this.$route.query, 'building_id', null),
            name: get(this.$route.query, 'name', null),
         },
      };
   },
   methods: {
      get,
      async loadReports() {
         const params = this.query;

         if (!params) return;

         params.page = get(this.$route.query, 'page', 1);
         params.per_page = get(this.$route.query, 'perPage', 10);
         this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});

         try {
            this.stateLoading = true;
            this.stateLoading = false;
            this.data = await LegalReportApi.list(params);
            this.stateLoading = false;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadProjects() {
         try {
            const response = await ProjectsApi.index();
            this.projectsData = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadBuilding() {
         try {
            const response = await BuildingApi.index({ project_id: this.projectID });
            this.buildingData = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async filterChanged() {
         this.query['project_id'] = this.projectID > 0 ? this.projectID : null;
         this.query['building_id'] = this.buildingID > 0 ? this.buildingID : null;
         this.query['name'] = this.name;

         this.query.page = 1;
      },
      async filter() {
         await this.filterChanged();
         await this.$router.push({ query: { ...this.$route.query, page: '1' } }).catch(() => {});
         await this.loadReports();
      },
      async downloadReport() {
         const params = this.query;
         if (!params) return;
         try {
            this.stateDownload = true;
            const data = await LegalReportApi.list({ ...params, download: true });
            window.open(data.file);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateDownload = false;
         }
      },
      async pushEmail() {
         const params = this.query;
         if (!params) return;
         try {
            this.stateEmail = true;
            await LegalReportApi.list({ ...params, by_email: true });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateEmail = false;
         }
      },
   },
   async mounted() {
      await this.loadProjects();
      await this.loadReports();
   },
};
</script>
