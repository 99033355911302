import Vue from 'vue';
import Validator from 'validatorjs';
import Errors from 'validatorjs/src/errors';
import { get, set, keys, pick, each, head, reduce, isEmpty, isArray } from 'lodash';
Validator.useLang('tr');

class Form extends Vue {
   constructor(attributes) {
      const defaultAttributes = reduce(attributes, (acc, key) => set(acc, key, null) && acc, {});
      super({
         data: () => ({
            attributes,
            _defaults: {},
            _rules: {},
            errors: new Errors(),
            dirtyAttributes: {},
            ...defaultAttributes,
         }),

         watch: {
            ...reduce(
               defaultAttributes,
               (acc, value, key) => {
                  set(acc, key, (newVal) => {
                     if (!newVal || this['dirtyAttributes'][key]) return;

                     this['dirtyAttributes'][key] = true;
                  });

                  return acc;
               },
               {}
            ),
         },
      });

      this.reset();
   }

   static create(attributes) {
      return new this(attributes);
   }

   onChange(_void) {
      _void();
   }

   validate(rules) {
      this._rules = rules;

      return this;
   }

   isValid(options = {}) {
      const reducerFn = (acc, value, key) => {
         if (!options.disableDirtyCheck && !this['dirtyAttributes'][key]) {
            return options.only ? pick(acc, options.only) : acc;
         }

         if (!options.only || (options.only && options.only.includes(key))) {
            acc[key] = value;
         }

         return acc;
      };

      const dirtyAttributes = reduce(this.toObject(), reducerFn, {});
      const appliedRules = pick(this._rules, options.only || keys(dirtyAttributes));

      const validation = new Validator(dirtyAttributes, appliedRules);

      this.errors = validation.errors;

      if (!options.disableDirtyCheck && isEmpty(dirtyAttributes)) {
         return false;
      }

      return validation.passes();
   }

   defaults(attributes = {}) {
      this._defaults = attributes;
      this.reset();

      return this;
   }

   reset(attributes = null) {
      attributes = attributes || this._defaults || this.attributes;

      each(attributes, (value, key) => {
         if (isArray(attributes)) {
            [key, value] = [value, null];
         }

         this[key] = value;
      });

      return this;
   }

   cleaned(isCleaned) {
      this._cleaned = isCleaned;

      return this;
   }

   get(key, defaultValue = null) {
      return get(this, key, defaultValue);
   }

   set(key, value = null) {
      return set(this, key, value);
   }

   merge(attributes = {}) {
      each(attributes, (value, key) => {
         this[key] = value;
      });

      return this;
   }

   only(...keys) {
      if (isArray(keys) && !!keys.length) {
         keys = head(keys);
      }

      return pick(this.toObject(), keys);
   }

   toObject() {
      return reduce(
         pick(this, this.attributes),
         (acc, value, key) => {
            if (this._cleaned && !value) {
               return acc;
            }

            acc[key] = value;

            return acc;
         },
         {}
      );
   }

   getAttributes() {
      return pick(this, this.attributes);
   }

   toJson(indent = 3) {
      return JSON.stringify(...[this.toObject(), null, indent]);
   }
}

export default Form;
