<template>
   <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="31" height="31" rx="4" fill="#137AF6" />
      <path
         d="M15 22C18.866 22 22 18.866 22 15C22 11.134 18.866 8 15 8C11.134 8 8 11.134 8 15C8 18.866 11.134 22 15 22Z"
         stroke="#FFFEFE"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path d="M14.9996 18L12.4015 13.5L17.5977 13.5L14.9996 18Z" fill="white" />
   </svg>
</template>

<script>
export default {
   name: 'IconOpen',
};
</script>

<style scoped lang="scss"></style>
