import Http from '../libs/http';

class MessagesApi extends Http {
   index() {
      return super.get('api/panel/messages');
   }
   CREAT_MESSAGE(payload) {
      return super.post('api/panel/messages', payload);
   }
}

export default new MessagesApi();
