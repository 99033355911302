<template>
   <AmcText v-on:keyup.enter="createPanelBrand">
      <AmcCard v-if="!loadData" shadow Card p3 class="mt-4 d-flex justify-content-between">
         <AmcText class="align-self-center" fwBold> {{ $t('definition.Yeni Panel Markası Tanımı') }} </AmcText>
         <AmcText class="d-flex">
            <AmcInput
               v-model="addBrand.name"
               width="300"
               py="2"
               secondary
               :placeholder="$t('placeholders.Yeni Panel Markası Ekle')"
            ></AmcInput>
            <AmcButton @click="createPanelBrand" class="text-white ms-3" :icon="IconAdded" ps="3" px="3" height="35px">
               {{ $t('buttons.Yeni Panel Markası Ekle') }}
            </AmcButton>
         </AmcText>
      </AmcCard>
      <AmcCard v-if="!loadData" p3 Card shadow class="mt-4">
         <AmcText as="table" class="table-striped table-borderless table">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText
                     v-for="(col, index) in headers"
                     :key="index"
                     as="th"
                     class="text-secondary text-start table-header"
                  >
                     {{ $t(`tableTitle.${col.title}`) }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText as="tr" class="table-row" v-for="panelBrand in panelBrands" :key="panelBrand.id">
                  <AmcText as="th" class="table-item input-name">
                     <input type="text" class="table-input form-control mt-2 fw-bold" v-model="panelBrand.name" />
                  </AmcText>
                  <AmcText as="th" class="table-item text-center">
                     <button @click="showModelModal(panelBrand)">
                        <IconEye />
                     </button>
                     <button class="mt-2 ms-3" @click="showRemoveModal(panelBrand)">
                        <IconDel />
                     </button>
                     <button class="mt-2 ms-3" @click="editPanelBrand(panelBrand)">
                        <IconEdit />
                     </button>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcCard>
      <ModelModal ref="modelModal" />
      <AmcRemoveModal ref="deleteModal" @remove="removePanelBrand" />
      <AmcLoading v-if="loadData" />
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import PanelsApi from '@/api/PanelsApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';
import IconAdded from '@/components/icons/IconAdded';
import IconEye from '@/components/icons/IconEye';
import ModelModal from '@/views/Pages/definition-page/_modals/ModelModal';

export default {
   name: 'PanelBrands',
   components: {
      IconDel,
      IconEdit,
      ModelModal,
      IconEye,
   },
   data() {
      return {
         IconAdded,
         panelBrands: {},
         addBrand: Form.create(['name']).validate({ name: 'required' }),
         headers: [{ title: 'PANEL MARKALARI' }],
         loadData: false,
      };
   },
   methods: {
      async loadPanelBrands() {
         this.loadData = true;
         try {
            await PanelsApi.index().then(({ data }) => (this.panelBrands = data));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
      async createPanelBrand() {
         try {
            await PanelsApi.CREATE_PANEL(this.addBrand.toObject()).then(() => {
               this.loadPanelBrands();
            });
            Notify.success(this.$t('notify.Panel Markası Eklendi'));
            this.addBrand.reset();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editPanelBrand(item) {
         try {
            this.loadData = true;
            await PanelsApi.EDIT_PANEL(item.id, item).then(() => {
               this.loadPanelBrands();
               this.loadData = false;
            });
            Notify.success(this.$t('notify.Panel Markası Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removePanelBrand(item) {
         try {
            await PanelsApi.REMOVE_PANEL(item.id).then(() => {
               this.loadPanelBrands();
               Notify.error(this.$t('notify.Panel Markası Silindi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
      showModelModal(item) {
         this.$refs.modelModal.show(item);
      },
   },
   created() {
      this.loadPanelBrands();
   },
};
</script>

<style scoped lang="scss">
.active-bar {
   height: 4px;
   background-color: #137af6;
   border-radius: 3px 3px 0 0;
   margin-top: 13px;
}
.item-text {
   padding-top: 25px;
   &:hover {
      color: #000000;
   }
}
.table {
   .table-header {
      &:nth-child(1) {
         padding-right: 400px;
         padding-left: 50px;
      }
   }
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
   & .table-row:focus-within {
      border: 1px solid #137af6;
      --bs-table-striped-bg: #ffffff;
   }
   & .input-name {
      padding-left: 50px;
   }
   & .table-input {
      border: none !important;
      background-color: transparent;
      padding-left: 0 !important;
      &:focus {
         box-shadow: none;
      }
   }
}
</style>
