<template>
   <AmcText>
      <AmcText class="shadow-sm rounded-8 p-4 border">
         <AmcText class="fs-4"> {{ dataProject.name }} </AmcText>
      </AmcText>
      <AmcText>
         <AmcRow>
            <AmcCol col="2 mt-4">
               <AmcNav :items="items" />
            </AmcCol>
            <AmcCol col="10" class="mt-5">
               <AmcText v-if="$route.path === `/projects-detail/${$route.params.id}`">
                  <AmcText v-if="!loading" class="rounded-8 shadow">
                     <AmcText class="border-bottom d-flex justify-content-between">
                        <AmcText class="p-4 d-flex">
                           <AmcText class="me-5">
                              <AmcText class="text-secondary fw-500">
                                 {{ $t('projects.detail.genel.Proje Adı') }}
                              </AmcText>
                              <AmcText class="fw-600"> {{ dataProject.name }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Satış Sorumlusu')
                              }}</AmcText>
                              <AmcText class="fw-600"> {{ dataProject.saler_name }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText @click="showProjectModal" as="button" class="p-4">
                           <IconEdit />
                        </AmcText>
                     </AmcText>
                     <AmcText class="p-4 d-flex">
                        <AmcText class="me-5">
                           <AmcText class="text-secondary fw-500">{{
                              $t('projects.detail.genel.Maksimum Kullanıcı Sayısı')
                           }}</AmcText>
                           <AmcText class="fw-600"> {{ dataProject.max_user_per_building }} </AmcText>
                        </AmcText>
                        <AmcText class="ms-5">
                           <AmcText class="text-secondary fw-500">{{
                              $t('projects.detail.genel.Oluşturma Tarihi')
                           }}</AmcText>
                           <AmcText class="fw-600">
                              {{ moment(dataProject['created_at']).format('DD-MM-YYYY HH:MM:SS') }}
                           </AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcText v-if="!loading" class="rounded-8 shadow mt-5">
                     <AmcText class="border-bottom">
                        <AmcText class="fw-500 p-4 fs-5">{{ $t('projects.detail.genel.Yetkililer') }}</AmcText>
                     </AmcText>
                     <AmcText class="border-bottom border-saler d-flex justify-content-between">
                        <AmcText class="p-4 d-flex">
                           <AmcText class="me-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Satış Sorumlusu Adı')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ dataProject.saler_name }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5 me-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Satış Sorumlusu Telefonu')
                              }}</AmcText>
                              <AmcText class="mt-2"> {{ dataProject.saler_phone }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Satış Sorumlusu E-Posta')
                              }}</AmcText>
                              <AmcText class="mt-2"> {{ dataProject.saler_email }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText>
                           <AmcText @click="showSalerModal" as="button" class="p-4">
                              <IconEdit />
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText class="border-bottom border-technical d-flex justify-content-between">
                        <AmcText class="p-4 d-flex">
                           <AmcText class="me-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Teknik Sorumlu Adı')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ dataProject.technical_head_name }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5 me-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Teknik Sorumlu Telefonu')
                              }}</AmcText>
                              <AmcText class="mt-2"> {{ dataProject.technical_head_phone }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Teknik Sorumlu E-Posta')
                              }}</AmcText>
                              <AmcText class="mt-2"> {{ dataProject.technical_head_email }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText>
                           <AmcText @click="showTechnicalModal" as="button" class="p-4">
                              <IconEdit />
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText class="border-bottom border-project d-flex justify-content-between">
                        <AmcText class="p-4 d-flex">
                           <AmcText class="me-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Proje Yöneticisi Adı')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ dataProject.manager_name }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5 me-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Proje Yöneticisi Telefonu')
                              }}</AmcText>
                              <AmcText class="mt-2"> {{ dataProject.manager_phone }} </AmcText>
                           </AmcText>
                           <AmcText class="ms-5">
                              <AmcText class="text-secondary fw-500">{{
                                 $t('projects.detail.genel.Proje Yöneticisi E-Posta')
                              }}</AmcText>
                              <AmcText class="mt-2"> {{ dataProject.manager_email }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText>
                           <AmcText @click="showManagerModal" as="button" class="p-4">
                              <IconEdit />
                           </AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcModal ref="editProject" lg>
                     <template #header>
                        <AmcText class="fs-3 p-4">{{ $t('projects.detail.genel.Proje Bilgileri') }}</AmcText>
                     </template>
                     <template>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{ $t('projects.detail.genel.Proje Adı') }}</label>
                           <input
                              type="text"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.name"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Maksimum Kullanıcı Sayısı')
                           }}</label>
                           <input
                              type="number"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.max_user_per_building"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Bildirim Seçenekleri')
                           }}</label>
                           <AmcText class="my-3 d-flex justify-content-between">
                              <AmcText class="d-flex">
                                 <input v-model="dataProject.sms_notification" type="checkbox" class="checkbox" />
                                 <AmcText :class="{ 'text-primary': dataProject.sms_notification }" class="ms-2">{{
                                    $t('checkbox.Sms')
                                 }}</AmcText>
                              </AmcText>
                              <AmcText class="d-flex">
                                 <input v-model="dataProject.email_notification" type="checkbox" class="checkbox" />
                                 <AmcText :class="{ 'text-primary': dataProject.email_notification }" class="ms-2">{{
                                    $t('checkbox.E-posta')
                                 }}</AmcText>
                              </AmcText>
                              <AmcText class="d-flex">
                                 <input v-model="dataProject.call_notification" type="checkbox" class="checkbox" />
                                 <AmcText :class="{ 'text-primary': dataProject.call_notification }" class="ms-2">{{
                                    $t('checkbox.Arama')
                                 }}</AmcText>
                              </AmcText>
                              <AmcText class="d-flex">
                                 <input v-model="dataProject.mobile_notification" type="checkbox" class="checkbox" />
                                 <AmcText :class="{ 'text-primary': dataProject.mobile_notification }" class="ms-2">{{
                                    $t('checkbox.Mobile')
                                 }}</AmcText>
                              </AmcText>
                           </AmcText>
                        </AmcText>
                     </template>
                     <template #actions>
                        <button class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="reset">
                           {{ $t('modalActions.Vazgeç') }}
                        </button>
                        <button class="btn btn-primary" data-bs-dismiss="modal" @click="editProject">
                           {{ $t('modalActions.Kaydet') }}
                        </button>
                     </template>
                  </AmcModal>
                  <AmcModal ref="editSaler" lg>
                     <template #header>
                        <AmcText class="fs-3 p-4">{{ $t('projects.detail.genel.Satış Sorumlusu Bilgileri') }}</AmcText>
                     </template>
                     <template>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Satış Sorumlusu Adı')
                           }}</label>
                           <input
                              type="text"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.saler_name"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Satış Sorumlusu Telefonu')
                           }}</label>
                           <input
                              type="tel"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.saler_phone"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Satış Sorumlusu E-Posta')
                           }}</label>
                           <input
                              type="email"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.saler_email"
                           />
                        </AmcText>
                     </template>
                     <template #actions>
                        <button class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="reset">
                           {{ $t('modalActions.Vazgeç') }}
                        </button>
                        <button class="btn btn-primary" data-bs-dismiss="modal" @click="editProject">
                           {{ $t('modalActions.Kaydet') }}
                        </button>
                     </template>
                  </AmcModal>
                  <AmcModal ref="editTechnical" lg>
                     <template #header>
                        <AmcText class="fs-3 p-4">{{ $t('projects.detail.genel.Teknik Sorumlu Bilgileri') }}</AmcText>
                     </template>
                     <template>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Teknik Sorumlu Adı')
                           }}</label>
                           <input
                              type="text"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.technical_head_name"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Teknik Sorumlu Telefonu')
                           }}</label>
                           <input
                              type="tel"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.technical_head_phone"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Teknik Sorumlu E-Posta')
                           }}</label>
                           <input
                              type="email"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.technical_head_email"
                           />
                        </AmcText>
                     </template>
                     <template #actions>
                        <button class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="reset">
                           {{ $t('modalActions.Vazgeç') }}
                        </button>
                        <button class="btn btn-primary" data-bs-dismiss="modal" @click="editProject">
                           {{ $t('modalActions.Kaydet') }}
                        </button>
                     </template>
                  </AmcModal>
                  <AmcModal ref="editManager" lg>
                     <template #header>
                        <AmcText class="fs-3 p-4">{{ $t('projects.detail.genel.Yönetici Bilgileri') }}</AmcText>
                     </template>
                     <template>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Proje Yöneticisi Adı')
                           }}</label>
                           <input
                              type="text"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.manager_name"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Proje Yöneticisi Telefonu')
                           }}</label>
                           <input
                              type="tel"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.manager_phone"
                           />
                        </AmcText>
                        <AmcText class="mb-4">
                           <label class="form-label fs-16 fw-500">{{
                              $t('projects.detail.genel.Proje Yöneticisi E-Posta')
                           }}</label>
                           <input
                              type="email"
                              class="form-control shadow-none py-2 border-2"
                              v-model="dataProject.manager_email"
                           />
                        </AmcText>
                     </template>
                     <template #actions>
                        <button class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="reset">
                           {{ $t('modalActions.Vazgeç') }}
                        </button>
                        <button class="btn btn-primary" data-bs-dismiss="modal" @click="editProject">
                           {{ $t('modalActions.Kaydet') }}
                        </button>
                     </template>
                  </AmcModal>
                  <AmcLoading v-if="loading"></AmcLoading>
               </AmcText>
               <router-view></router-view>
            </AmcCol>
         </AmcRow>
      </AmcText>
   </AmcText>
</template>

<script>
import Notify from '@/libs/notify';
import ProjectsApi from '@/api/ProjectsApi';
import ErrorHandler from '@/libs/error-handler';
import IconEdit from '@/components/icons/IconEdit';
import IconDevice from '@/components/icons/IconDevice';
import IconMessage from '@/components/icons/IconMessage';
import IconHome from '@/components/icons/facilities-detail-icons/IconHome';

export default {
   name: 'DetailProjects.vue',
   components: {
      IconEdit,
   },
   data() {
      return {
         loading: false,
         dataProject: {},
         items: [
            {
               title: this.$t('projects.detail.nav.Genel'),
               subtitle: this.$t('projects.detail.nav.Hızlı Bakış'),
               tag: IconHome,
               to: `/projects-detail/${this.$route.params.id}`,
            },
            {
               title: this.$t('projects.detail.nav.Cihaz Listesi'),
               subtitle: this.$t('projects.detail.nav.Cihazlar, Ekle, Kaldır'),
               tag: IconDevice,
               to: `/projects-detail/${this.$route.params.id}/device`,
            },
            {
               title: this.$t('projects.detail.nav.Hazır Mesajlar'),
               subtitle: this.$t('projects.detail.nav.Gönderilecek Mesajlar'),
               tag: IconMessage,
               to: `/projects-detail/${this.$route.params.id}/message`,
            },
         ],
      };
   },
   methods: {
      async getProject() {
         this.loading = true;
         try {
            await ProjectsApi.index(this.$route.params.id).then(({ data }) => {
               this.dataProject = data;
            });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loading = false;
         }
      },
      async editProject() {
         try {
            await ProjectsApi.EDIT_PROJECT(this.$route.params.id, this.dataProject);

            Notify.success(this.$t('notify.Proje Bilgileri Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            await this.getProject();
         }
      },
      showProjectModal() {
         this.$refs.editProject.show();
      },
      showSalerModal() {
         this.$refs.editSaler.show();
      },
      showTechnicalModal() {
         this.$refs.editTechnical.show();
      },
      showManagerModal() {
         this.$refs.editManager.show();
      },
      reset() {
         this.getProject();
      },
   },
   async beforeMount() {
      await this.getProject();
   },
};
</script>

<style scoped lang="scss">
.border-saler {
   border-left: 6px solid #71affa;
}
.border-technical {
   border-left: 6px solid #f5bd17;
}
.border-project {
   border-left: 6px solid #2fa84f;
}
</style>
