<template>
   <AmcText>
      <AmcText v-if="authority">
         <AmcText class="border-bottom d-flex">
            <AmcText fsLarge> {{ $t('user.Kullanıcılar') }} </AmcText>
            <AmcText class="mx-auto pe-5">
               <ul class="navbar-nav d-flex flex-row pe-5">
                  <router-link
                     to="/user"
                     role="button"
                     class="nav-item me-5 text-secondary fw-bold text-decoration-none"
                  >
                     <AmcText :class="{ 'text-dark': this.$route.path === '/user' }" class="item-text">{{
                        $t('user.Sistem Yöneticileri')
                     }}</AmcText>
                     <AmcText v-if="this.$route.path === '/user'" class="active-bar" />
                  </router-link>
                  <router-link
                     to="/user/project-managers"
                     role="button"
                     class="nav-item me-5 text-secondary fw-bold text-decoration-none pe-5"
                  >
                     <AmcText
                        :class="{ 'text-dark': this.$route.path === '/user/project-managers' }"
                        class="item-text"
                        >{{ $t('user.Proje Yöneticileri') }}</AmcText
                     >
                     <AmcText v-if="this.$route.path === '/user/project-managers'" class="active-bar" />
                  </router-link>
               </ul>
            </AmcText>
         </AmcText>
         <AmcText v-if="this.$route.path === '/user'" class="shadow-sm p-4 rounded-8 mt-4">
            <AmcText v-if="!loadData" class="d-flex justify-content-between">
               <AmcText class="input-group mb-3">
                  <AmcInput :icon="IconSearch" py="2" :placeholder="$t('placeholders.Arama')" v-model="search" />
               </AmcText>
               <AmcButton @click="showModal" class="text-white" :icon="IconAdded" ps="3" px="4" height="40px">{{
                  $t('buttons.Yeni Kullanıcı Ekle')
               }}</AmcButton>
            </AmcText>
            <AmcText v-if="!loadData" as="table" class="table-striped table-borderless table">
               <AmcText as="thead">
                  <AmcText as="tr">
                     <AmcText
                        v-for="(col, index) in headers"
                        :key="index"
                        as="th"
                        class="text-uppercase text-secondary text-start"
                     >
                        {{ $t(`tableTitle.${col.title}`) }}
                     </AmcText>
                  </AmcText>
               </AmcText>
               <AmcText as="tbody">
                  <AmcText
                     as="tr"
                     class="table-row"
                     v-for="manager in $options.filters.filterTableData(systemManager, headers, search).data"
                     :key="manager.id"
                  >
                     <AmcText as="td" class="table-item">
                        <AmcText class="my-3 fw-bold" v-text="manager.name" />
                     </AmcText>
                     <AmcText as="td" class="table-item">
                        <AmcText class="my-3" v-text="manager.email" />
                     </AmcText>
                     <AmcText as="td" class="table-item">
                        <AmcText class="my-3 fw-bold" v-text="manager.phone" />
                     </AmcText>
                     <AmcText as="td" class="table-item">
                        <button class="mt-3" @click="showRemoveModal(manager)"><IconDel /></button>
                        <button class="ms-3" @click="showEditModal(manager)">
                           <IconEdit />
                        </button>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
            <CreateUserModal ref="createModal" @hide="hideModal" @create="creatSystemManager" />
            <AmcRemoveModal ref="deleteModal" @remove="removeManager" />
            <UserEditModal @edit="editManager" ref="editModal" />
            <AmcLoading v-if="loadData" />
         </AmcText>

         <router-view></router-view>
      </AmcText>
      <AmcText v-if="!authority">
         <AuthorizationCheck />
      </AmcText>
   </AmcText>
</template>

<script>
import Notify from '@/libs/notify';
import UserApi from '@/api/UserApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconAdded from '@/components/icons/IconAdded';
import CreateUserModal from './_modals/CreateUserModal';
import IconSearch from '@/components/icons/header/IconSearch';
import UserEditModal from '@/views/Pages/user-page/_modals/UserEditModal';
import IconEdit from '@/components/icons/IconEdit';

export default {
   name: 'User',
   components: {
      IconDel,
      IconEdit,
      UserEditModal,
      CreateUserModal,
   },
   data() {
      return {
         IconSearch,
         IconAdded,
         authority: false,
         search: '',
         systemManager: [],
         loadData: false,
         headers: [
            { title: 'ADI SOYADI', value: 'name' },
            { title: 'E-POSTA', value: 'email' },
            { title: 'TELEFON', value: 'phone' },
            { title: 'DÜZENLE' },
         ],
      };
   },
   methods: {
      async loadSystemManager() {
         this.loadData = true;
         try {
            const data = await UserApi.USER_SYSTEM_MANAGER();
            this.systemManager = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },

      async creatSystemManager(payload) {
         this.loadData = true;
         try {
            await UserApi.CREATE_SYSTEM_MANAGER(payload.toObject());
            Notify.success(this.$t('notify.Sistem Yöneticisi Eklendi'));
            this.hideModal();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            await this.loadSystemManager();
            this.loadData = false;
         }
      },
      async editManager(item) {
         try {
            await UserApi.EDIT_MANAGER(item.id, item).then(() => {
               this.loadSystemManager();
            });
            Notify.success(this.$t('notify.Yönetici Bilgileri Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeManager(item) {
         try {
            await UserApi.REMOVE_MANAGER(item.id).then(() => {
               Notify.error(this.$t('notify.Sistem yöneticisi silindi'));
               this.loadSystemManager();
            });
         } catch (e) {
            ErrorHandler.handle(e);
         }
      },

      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
      showModal() {
         this.$refs.createModal.show();
      },
      showEditModal(item) {
         this.$refs.editModal.show(item);
      },
      hideModal() {
         this.$refs.createModal.hide();
      },
   },
   async beforeMount() {
      if (this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1) {
         this.authority = true;
         await this.loadSystemManager();
      }
   },
};
</script>

<style scoped lang="scss">
.active-bar {
   height: 4px;
   background-color: #137af6;
   border-radius: 3px 3px 0 0;
   margin-top: 13px;
}
.item-text {
   padding-top: 25px;
   &:hover {
      color: #000000;
   }
}
.input-group {
   width: 300px;

   & .form-control {
      padding: 8.5px 5px 8.5px 45px;
      border-radius: 5px !important;
      background-color: #e5efff;
      border: none !important;

      &:focus {
         box-shadow: none !important;
      }
   }

   & .input-icon {
      top: 9px;
      left: 11px;
      z-index: 20;
   }
}
.table {
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
}
.modal-header {
   .badge {
      border-radius: 100%;
      background-color: #e6e7e9;
      padding: 10px 12px;
      font-size: 14px;
      margin-right: 8px;
   }
}
</style>
