<template>
   <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#E5EFFF" />
      <path
         d="M10.625 11H23.625C24.5188 11 25.25 11.7312 25.25 12.625V22.375C25.25 23.2688 24.5188 24 23.625 24H10.625C9.73125 24 9 23.2688 9 22.375V12.625C9 11.7312 9.73125 11 10.625 11Z"
         stroke="#137AF6"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path
         d="M25.25 12.625L17.125 18.3125L9 12.625"
         stroke="#137AF6"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconMailPush',
};
</script>

<style scoped></style>
