<template>
   <AmcText class="rounded-8 shadow-sm">
      <AmcText class="d-flex justify-content-between border-bottom">
         <AmcText>
            <AmcText class="fs-3 p-4">{{ $t('projects.detail.hazirMesajlar.Hazır Mesajlar') }}</AmcText>
         </AmcText>
         <AmcText class="p-4">
            <button @click="showNewMessage" class="btn btn-primary px-4 py-2">
               <IconMessage stroke="#FFFFFF" /> <span class="ms-2">{{ $t('buttons.Yeni Mesaj Ekle') }}</span>
            </button>
         </AmcText>
      </AmcText>
      <AmcText>
         <AmcText as="table" class="table-striped table-borderless table">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText as="th" class="text-secondary fw-600">
                     {{ $t('projects.detail.hazirMesajlar.BAŞLIK') }}
                  </AmcText>
                  <AmcText as="th" class="text-secondary fw-600">
                     {{ $t('projects.detail.hazirMesajlar.MESAJ') }}
                  </AmcText>
                  <AmcText as="th" class="text-secondary fw-600">
                     {{ $t('projects.detail.hazirMesajlar.DÜZENLE') }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText as="tr" v-for="(data, index) in dataMessage.data" :key="index">
                  <AmcText as="td" class="text-secondary fw-600 py-4">
                     <span class="mx-2"><IconMessage stroke="#2FA84F" /></span> {{ data.title }}
                  </AmcText>
                  <AmcText as="td" class="text-secondary py-4">
                     {{ data.content }}
                  </AmcText>
                  <AmcText as="td" class="text-secondary fw-600 py-4 d-flex">
                     <AmcText @click="showEditMessage(data)" as="button" class="me-2">
                        <IconEdit />
                     </AmcText>
                     <AmcText as="button" @click="showRemoveModal(data)">
                        <IconDel />
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcModal ref="newMessage" lg>
         <template #header>
            <AmcText class="fs-3 py-3">{{ $t('projects.detail.hazirMesajlar.modal.Yeni Mesaj Ekle') }}</AmcText>
         </template>
         <template>
            <AmcText class="mb-4">
               <AmcText class="fw-600 mb-3 text-secondary">{{
                  $t('projects.detail.hazirMesajlar.modal.Mesaj Başlığı')
               }}</AmcText>
               <input v-model="message.title" class="form-control shadow-none" />
            </AmcText>
            <AmcText>
               <AmcText class="fw-600 mb-3 text-secondary">{{
                  $t('projects.detail.hazirMesajlar.modal.Mesaj')
               }}</AmcText>
               <textarea v-model="message.content" class="form-control shadow-none" rows="8"></textarea>
            </AmcText>
         </template>
         <template #actions>
            <AmcText>
               <button @click="reset" data-bs-dismiss="modal" class="btn btn-outline-secondary px-5 py-2">
                  {{ $t('modalActions.Vazgeç') }}
               </button>
            </AmcText>
            <AmcText>
               <button @click="createMessage" class="btn btn-primary px-5 py-2">{{ $t('modalActions.Kaydet') }}</button>
            </AmcText>
         </template>
      </AmcModal>
      <AmcModal ref="editMessageModal" lg>
         <template #header>
            <AmcText class="fs-3 py-3">{{ $t('projects.detail.hazirMesajlar.modal.Mesaj Düzenle') }}</AmcText>
         </template>
         <template>
            <AmcText class="mb-4">
               <AmcText class="fw-600 mb-3 text-secondary">{{
                  $t('projects.detail.hazirMesajlar.modal.Mesaj Başlığı')
               }}</AmcText>
               <input v-model="selectedModalContent.title" class="form-control shadow-none" />
            </AmcText>
            <AmcText>
               <AmcText class="fw-600 mb-3 text-secondary">{{
                  $t('projects.detail.hazirMesajlar.modal.Mesaj')
               }}</AmcText>
               <textarea v-model="selectedModalContent.content" class="form-control shadow-none" rows="8"></textarea>
            </AmcText>
         </template>
         <template #actions>
            <AmcText>
               <button @click="reset" data-bs-dismiss="modal" class="btn btn-outline-secondary px-5 py-2">
                  {{ $t('modalActions.Vazgeç') }}
               </button>
            </AmcText>
            <AmcText>
               <button @click="editMessage(selectedModalContent)" class="btn btn-primary px-5 py-2">
                  {{ $t('modalActions.Kaydet') }}
               </button>
            </AmcText>
         </template>
      </AmcModal>
      <AmcRemoveModal ref="deleteModal" @remove="removeMessage" />
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';
import IconMessage from '@/components/icons/IconMessage';
import ProjectMessagesApi from '@/api/ProjectMessagesApi';

export default {
   name: 'DetailReadyMessages',
   components: {
      IconMessage,
      IconEdit,
      IconDel,
   },
   data() {
      return {
         dataMessage: {},
         message: Form.create(['title', 'content']).validate({
            title: 'required',
            content: 'required',
         }),
         selectedModalContent: {},
      };
   },
   methods: {
      async loadMessage() {
         try {
            const result = await ProjectMessagesApi.index(this.$route.params.id);
            this.dataMessage = result;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async createMessage() {
         try {
            await ProjectMessagesApi.CREAT_MESSAGE(this.$route.params.id, this.message.toObject()).then(() => {
               Notify.success(this.$t('notify.Mesaj Eklendi'));
               this.loadMessage();
               this.reset();
               this.hideNewMessage();
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editMessage(item) {
         try {
            await ProjectMessagesApi.EDIT_MESSAGE(this.$route.params.id, item.id, item).then(() => {
               Notify.success(this.$t('notify.Mesaj Güncellendi'));
               this.hideEditMessage();
               this.loadMessage();
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeMessage(item) {
         try {
            await ProjectMessagesApi.REMOVE_MESSAGE(this.$route.params.id, item.id).then(() => {
               this.loadMessage();
               Notify.error(this.$t('notify.Mesaj Silindi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showNewMessage() {
         this.$refs.newMessage.show();
      },
      hideNewMessage() {
         this.$refs.newMessage.hide();
      },
      showEditMessage(item) {
         this.$refs.editMessageModal.show(item);
         this.selectedModalContent = item;
      },
      hideEditMessage() {
         this.$refs.editMessageModal.hide();
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },

      reset() {
         this.loadMessage();
         this.message.reset();
      },
   },
   async beforeMount() {
      await this.loadMessage();
   },
};
</script>

<style scoped></style>
