<template>
   <vue-custom-scrollbar class="scroll-area" :settings="settings">
      <div class="sidebar">
         <ul class="navbar-nav">
            <li class="navbar-brand pt-4 text-center sticky-top">
               <router-link class="amc-icon mt-4 ms-4" to="/">
                  <IconAmcWhite />
               </router-link>
               <p class="brand-text ms-4 pb-2">Alarm Monitoring Center</p>
            </li>
            <div class="nav-items">
               <AmcSidebar :items="items"></AmcSidebar>
            </div>
         </ul>
      </div>
   </vue-custom-scrollbar>
</template>

<script>
import 'vue-custom-scrollbar/dist/vueScrollbar.css';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import IconLog from '@/components/icons/sidebar-icon/IconLog';
import IconHome from '@/components/icons/sidebar-icon/IconHome';
import IconInfo from '@/components/icons/sidebar-icon/IconInfo';
import IconUsers from '@/components/icons/sidebar-icon/IconUsers';
import IconModem from '@/components/icons/sidebar-icon/IconModem';
import IconReports from '@/components/icons/sidebar-icon/IconReports';
import IconProject from '@/components/icons/sidebar-icon/IconProject';
import IconAmcWhite from '@/components/icons/sidebar-icon/IconAmcWhite';
import IconDefinition from '@/components/icons/sidebar-icon/IconDefinition';
import IconFacilities from '@/components/icons/sidebar-icon/IconFacilities';

export default {
   name: 'Sidebar',
   components: {
      IconAmcWhite,
      vueCustomScrollbar,
   },
   data() {
      return {
         items: [
            {
               icon: IconHome,
               text: 'Genel',
               to: '/',
               onlySystemManager: true,
            },
            { icon: IconLog, text: 'Loglar', to: '/logs', onlySystemManager: true },
            { icon: IconReports, text: 'Raporlar', to: '/reports/modem-logs', onlySystemManager: true },
            { icon: IconFacilities, text: 'Tesisler', to: '/facilities', onlySystemManager: true },
            {
               icon: IconProject,
               text: 'Projeler',
               to: '/projects',
               onlySystemManager: this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1 ? true : false,
            },
            {
               icon: IconModem,
               text: 'Modemler',
               to: '/modems',
               onlySystemManager: this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1 ? true : false,
            },
            {
               icon: IconDefinition,
               text: 'Tanımlama',
               to: '/definition',
               onlySystemManager: this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1 ? true : false,
            },
            {
               icon: IconUsers,
               text: 'Kullanıcı',
               to: '/user',
               onlySystemManager: this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1 ? true : false,
            },
            { icon: IconInfo, text: 'Geri Bildirim', to: '/feedback', onlySystemManager: true },
         ],
         settings: {
            suppressScrollY: false,
            suppressScrollX: false,
            wheelPropagation: false,
         },
      };
   },
};
</script>

<style lang="scss" scoped>
.sidebar {
   background: #137af6;
   & .navbar-brand {
      background: #137af6;
      margin-bottom: 5px;
   }
   & ul {
      & .nav-items {
         border-top: 2px solid #4093f5;
      }

      & .nav-item {
         text-align: center;
         margin-top: 25px;
         display: block;

         & .item-text {
            color: #ffffff;
            margin-top: 10px;
         }
      }

      & .item-settings {
         margin-top: 150px;
         padding-top: 30px;
         border-top: 2px solid #4093f5;
      }
   }

   & .brand-text {
      font-size: 9px;
      margin-top: 5px;
      color: #ffffff;
      font-weight: 100;
   }
}

.scroll-area {
   position: fixed;
   height: 100vh;
   overflow: auto;
   background: #137af6;
}
</style>
