<template>
   <svg stroke="#B6CAD4" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M13 2.5H2.5C1.67157 2.5 1 3.17157 1 4V14.5C1 15.3284 1.67157 16 2.5 16H13C13.8284 16 14.5 15.3284 14.5 14.5V4C14.5 3.17157 13.8284 2.5 13 2.5Z"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path d="M10.75 1V4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.75 1V4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 7H14.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconMiniCalender',
};
</script>

<style scoped></style>
