<template>
   <AmcModal lg ref="modal" class="mt-5 pt-5">
      <template #header>
         <AmcText class="fs-3">{{ item.name }}</AmcText>
      </template>
      <template>
         <AmcText class="d-flex justify-content-between">
            <input type="text" class="form-control shadow-none" v-model="createModel.name" />
            <button @click="createNewModel" class="btn btn-primary ms-3 px-4">{{ $t('buttons.Ekle') }}</button>
         </AmcText>
         <AmcText class="mt-4" v-for="model in dataModel" :key="model.id">
            <AmcText class="p-2 align-self-center border rounded-8 d-flex justify-content-between">
               <input type="text" class="table-input form-control fw-bold border-0 shadow-none" v-model="model.name" />
               <AmcText class="d-flex">
                  <span @click="editModel(item, model)" role="button" class="align-self-center">
                     <IconEdit />
                  </span>
                  <span @click="removeModel(item, model)" role="button" class="ms-3 align-self-center">
                     <IconDel />
                  </span>
               </AmcText>
            </AmcText>
         </AmcText>
      </template>
      <template #actions>
         <button class="btn btn-outline-secondary px-5" @click="close">{{ $t('modalActions.Kapat') }}</button>
      </template>
   </AmcModal>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import ModelsApi from '@/api/ModelsApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';

export default {
   name: 'ModelModal',
   components: {
      IconEdit,
      IconDel,
   },
   data() {
      return {
         item: {},
         dataModel: [],
         createModel: Form.create(['name']).validate({ name: 'required' }).defaults({ name: null }),
      };
   },
   methods: {
      show(item) {
         this.item = item;
         this.$refs.modal.show();
         this.loadModel();
      },
      close() {
         this.$refs.modal.hide();
         this.createModel.reset();
      },
      async loadModel() {
         try {
            const result = await ModelsApi.index(this.item.id);
            this.dataModel = result.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async createNewModel() {
         try {
            await ModelsApi.CREATE_MODEL(this.item.id, this.createModel.toObject());
            Notify.success(this.$t('notify.Model Eklendi'));
            this.createModel.reset();
            await this.loadModel(this.item.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editModel(idBrand, itemModel) {
         try {
            await ModelsApi.EDIT_MODEL(idBrand.id, itemModel.id, itemModel);
            Notify.success(this.$t('notify.Model Güncellendi'));
            await this.loadModel(idBrand.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeModel(brand, model) {
         try {
            await ModelsApi.REMOVE_MODEL(brand.id, model.id);
            Notify.error(this.$t('notify.Model Silindi'));
            await this.loadModel(brand.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
   },
};
</script>

<style scoped></style>
