<template>
   <AmcText>
      <AmcText v-if="authority">
         <AmcText>
            <AmcText fsXlarge> {{ $t('projects.Projeler') }}</AmcText>
         </AmcText>
         <AmcText class="rounded-8 shadow-sm p-4">
            <AmcText>
               <AmcTable
                  hover
                  options
                  striped
                  v-show="!stateLoading"
                  :data="projectsData | filterTableData(header, search)"
                  :header="header"
                  @detail="detail"
                  @remove="removeProject"
                  @pageChanged="laodProjects"
                  @perPageChanged="laodProjects"
               >
                  <template #header>
                     <AmcText class="border-bottom mb-2 d-flex justify-content-between">
                        <AmcText class="input-group mb-3">
                           <AmcInput
                              :icon="IconSearch"
                              py="2"
                              :placeholder="$t('placeholders.Arama')"
                              v-model="search"
                           />
                        </AmcText>
                        <AmcButton
                           @click="showCreateModal"
                           class="text-white ms-5"
                           :icon="IconAdded"
                           ps="3"
                           px="4"
                           height="40px"
                        >
                           {{ $t('buttons.Yeni Proje Ekle') }}
                        </AmcButton>
                     </AmcText>
                  </template>
               </AmcTable>
            </AmcText>
         </AmcText>
         <!-- Create Modal Start -->
         <AmcModal ref="createModal" lg>
            <template #header>
               <AmcText class="h3 py-3 mb-0"> {{ $t('projects.modal.Yeni Proje Ekle') }} </AmcText>
               <AmcText class="d-flex">
                  <AmcText :class="{ 'bg-primary': step === 1 || 2 || 3 }" class="step-number align-self-center">
                     <span :class="{ 'text-white': step !== 1 || 2 || 3 }" class="d-flex align-self-center">1</span>
                  </AmcText>
                  <AmcText :class="{ 'bg-primary': step !== 1 }" class="align-self-center step-line"></AmcText>
                  <AmcText :class="{ 'bg-primary': step !== 1 }" class="step-number align-self-center">
                     <span :class="{ 'text-white': step !== 1 }" class="d-flex align-self-center">2</span>
                  </AmcText>
                  <AmcText :class="{ 'bg-primary': step === 3 }" class="align-self-center step-line"></AmcText>
                  <AmcText :class="{ 'bg-primary': step === 3 }" class="step-number align-self-center">
                     <span :class="{ 'text-white': step === 3 }" class="d-flex align-self-center">3</span>
                  </AmcText>
               </AmcText>
            </template>
            <template>
               <AmcText v-show="step === 1">
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Proje Adı') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('name') }"
                        v-model="project.name"
                        type="text"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('name')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Proje Yöneticisi Ünvanı') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('manager_title') }"
                        v-model="project.manager_title"
                        type="text"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('manager_title')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Proje Yöneticisi Adı') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('manager_name') }"
                        v-model="project.manager_name"
                        type="text"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('manager_name')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <label class="form-label fw-600">{{
                        $t('projects.modal.Proje Yöneticisi Telefon Numarası')
                     }}</label>
                     <VuePhoneNumberInput
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('manager_phone') }"
                        v-model="project.manager_phone_nonformatted"
                        default-country-code="TR"
                        formatInternational
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('manager_phone_nonformatted')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Proje Yöneticisi E-Postası') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('manager_email') }"
                        v-model="project.manager_email"
                        type="email"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('manager_email')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Maksimum Kullanıcı Sayısı') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('max_user_per_building') }"
                        v-model="project.max_user_per_building"
                        type="email"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('max_user_per_building')"
                        v-html="error"
                     ></span>
                  </AmcText>
               </AmcText>
               <AmcText v-show="step === 2">
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Satış Sorumlusu Adı') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('saler_name') }"
                        v-model="project.saler_name"
                        type="text"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('saler_name')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <label class="form-label fw-600">{{
                        $t('projects.modal.Satış Sorumlusu Telefon Numarası')
                     }}</label>
                     <VuePhoneNumberInput
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('saler_phone_nonformatted') }"
                        v-model="project.saler_phone_nonformatted"
                        default-country-code="TR"
                        formatInternational
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('saler_phone_nonformatted')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Satış Sorumlusu E-Postası') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('saler_email') }"
                        v-model="project.saler_email"
                        type="email"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('saler_email')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Teknik Sorumlu Adı') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('technical_head_name') }"
                        v-model="project.technical_head_name"
                        type="text"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('technical_head_name')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <label class="form-label fw-600">{{ $t('projects.modal.Teknik Sorumlu Telefon Numarası') }}</label>
                     <VuePhoneNumberInput
                        v-model="project.technical_head_phone_nonformatted"
                        default-country-code="TR"
                        formatInternational
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('technical_head_phone_nonformatted') }"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('technical_head_phone_nonformatted')"
                        v-html="error"
                     ></span>
                  </AmcText>
                  <AmcText class="mb-3">
                     <AmcText class="form-label fw-600">{{ $t('projects.modal.Teknik Sorumlu E-Postası') }}</AmcText>
                     <input
                        @keyup="project.isValid()"
                        :class="{ 'border-danger': project.errors.has('technical_head_email') }"
                        v-model="project.technical_head_email"
                        type="email"
                        class="form-control shadow-none"
                     />
                     <span
                        class="text-danger fs-12"
                        :key="i"
                        v-for="(error, i) in project.errors.get('technical_head_email')"
                        v-html="error"
                     ></span>
                  </AmcText>
               </AmcText>
               <AmcText v-show="step === 3">
                  <AmcText class="text-center my-5">
                     <IconSuccess class="text-center" />
                     <AmcText class="text-primary mt-4 fs-3">{{
                        $t('projects.modal.Tesis ekleme işlemi başarılı')
                     }}</AmcText>
                  </AmcText>
               </AmcText>
            </template>
            <template #actions>
               <AmcText class="d-flex flex-row justify-content-between">
                  <button v-if="step !== 3" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                     {{ $t('modalActions.Vazgeç') }}
                  </button>
                  <button v-if="step === 2" class="btn btn-secondary ms-3 px-3" @click="previousStep">
                     {{ $t('modalActions.Geri') }}
                  </button>
               </AmcText>
               <AmcText>
                  <button v-if="step === 1" class="btn btn-primary" @click="nextStep">
                     {{ $t('modalActions.Devam') }}
                  </button>
                  <button v-if="step === 2" class="btn btn-primary" @click="creatProject(project)">
                     {{ $t('modalActions.Gönder') }}
                  </button>
                  <button v-if="step === 3" class="btn btn-secondary" @click="close">
                     {{ $t('modalActions.Kapat') }}
                  </button>
               </AmcText>
            </template>
         </AmcModal>
         <!-- Create Modal Finish -->
         <AmcLoading v-if="stateLoading" />
      </AmcText>
      <AmcText v-if="!authority">
         <AuthorizationCheck />
      </AmcText>
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';
import ProjectsApi from '@/api/ProjectsApi';
import ErrorHandler from '@/libs/error-handler';
import IconAdded from '@/components/icons/IconAdded';
import IconSuccess from '@/components/icons/IconSuccess';
import VuePhoneNumberInput from 'vue-phone-number-input';
import IconSearch from '@/components/icons/header/IconSearch';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
   name: 'Projects.vue',
   components: {
      VuePhoneNumberInput,
      IconSuccess,
   },
   data() {
      return {
         IconSearch,
         IconAdded,
         authority: false,
         search: '',
         header: [
            { title: 'PROJE', value: 'name' },
            { title: 'SATIŞ SORUMLUSU', value: 'saler_name' },
            { title: 'TEKNİK SORUMLU', value: 'technical_head_name' },
            { title: 'PROJE YÖNETİCİSİ', value: 'manager_name' },
            { title: 'MAX KULLANICI', value: 'max_user_per_building' },
         ],
         projectsData: {},
         stateLoading: false,
         step: 1,
         totalStep: 3,
         modalHeader: [
            { step: '1', stepText: 'Tesis Bilgileri' },
            { step: '2', stepText: 'Adres' },
            { step: '3', stepText: 'Eklendi' },
         ],
         project: Form.create([
            'name',
            'manager_title',
            'manager_name',
            'manager_phone',
            'manager_phone_nonformatted',
            'manager_email',
            'max_user_per_building',
            'saler_name',
            'saler_phone',
            'saler_phone_nonformatted',
            'saler_email',
            'technical_head_name',
            'technical_head_phone',
            'technical_head_phone_nonformatted',
            'technical_head_email',
         ])
            .validate({
               name: 'required',
               manager_title: 'required',
               manager_name: 'required',
               manager_phone_nonformatted: 'required',
               manager_email: 'required',
               max_user_per_building: 'required',
               saler_name: 'required',
               saler_phone_nonformatted: 'required',
               saler_email: 'required',
               technical_head_name: 'required',
               technical_head_phone_nonformatted: 'required',
               technical_head_email: 'required',
            })
            .defaults({
               name: '',
               manager_title: '',
               manager_name: '',
               manager_phone_nonformatted: '',
               manager_email: '',
               max_user_per_building: '',
               saler_name: '',
               saler_phone_nonformatted: '',
               saler_email: '',
               technical_head_name: '',
               technical_head_phone_nonformatted: '',
               technical_head_email: '',
            }),
      };
   },
   methods: {
      prepareParams() {
         let params = {
            page: get(this.$route.query, 'page', 1),
            perPage: get(this.$route.query, 'perPage', 10),
         };

         return params;
      },
      formatManagerPhone() {
         this.project.manager_phone = replace(this.project.manager_phone_nonformatted, /\s/g, '');

         let haveZero = startsWith(this.project.manager_phone, '0');
         if (haveZero) {
            this.project.manager_phone = '+9' + this.project.manager_phone;
         } else {
            this.project.manager_phone = '+90' + this.project.manager_phone;
         }
      },
      formatSalerPhone() {
         this.project.saler_phone = replace(this.project.saler_phone_nonformatted, /\s/g, '');

         let haveZero = startsWith(this.project.saler_phone, '0');
         if (haveZero) {
            this.project.saler_phone = '+9' + this.project.saler_phone;
         } else {
            this.project.saler_phone = '+90' + this.project.saler_phone;
         }
      },
      formatTechnicalPhone() {
         this.project.technical_head_phone = replace(this.project.technical_head_phone_nonformatted, /\s/g, '');

         let haveZero = startsWith(this.project.technical_head_phone, '0');
         if (haveZero) {
            this.project.technical_head_phone = '+9' + this.project.technical_head_phone;
         } else {
            this.project.technical_head_phone = '+90' + this.project.technical_head_phone;
         }
      },
      async laodProjects() {
         const params = this.prepareParams();
         if (!params) return;
         this.stateLoading = true;
         try {
            this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});
            const result = await ProjectsApi.PROJECTS(params);
            this.projectsData = result;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.stateLoading = false;
         }
      },
      async creatProject(payload) {
         if (!this.checkValidation()) return;
         this.formatManagerPhone();
         this.formatSalerPhone();
         this.formatTechnicalPhone();
         try {
            await ProjectsApi.CREATE_PROJECT(payload.toObject()).then(() => {
               this.step = 3;
               this.project.reset();
            });
            Notify.success(this.$t('notify.Proje Eklendi'));
         } catch (err) {
            console.log('error');
            ErrorHandler.handle(err);
         }
      },
      async removeProject(item) {
         try {
            await ProjectsApi.REMOVE_PROJECT(item.id).then(() => {
               this.laodProjects();
               Notify.error(this.$t('notify.Proje silindi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      checkValidation() {
         const validationKeyMapping = {
            1: ['name', 'manager_title', 'manager_name', 'manager_phone', 'manager_email', 'max_user_per_building'],
            2: [
               'saler_name',
               'saler_phone',
               'saler_email',
               'technical_head_name',
               'technical_head_phone',
               'technical_head_email',
            ],
         };

         return this.project.isValid({
            disableDirtyCheck: true,
            only: get(validationKeyMapping, this.step),
         });
      },
      detail(item) {
         this.$router.push(`projects-detail/${item.id}`);
      },
      showCreateModal() {
         this.$refs.createModal.show();
      },
      previousStep: function () {
         this.step--;
      },
      nextStep: function () {
         if (this.checkValidation()) this.step++;
      },
      reset: function () {
         this.project.reset();
         this.step = 1;
      },
      close: function () {
         this.laodProjects();
         this.$refs.createModal.hide();
         this.reset();
      },
   },
   async beforeMount() {
      if (this.$store.state.auth.authUser.roles.indexOf('super-admin') > -1) {
         this.authority = true;
         await this.laodProjects();
      }
   },
};
</script>

<style scoped lang="scss">
.input-group {
   width: 300px;

   & .form-control {
      padding: 8.5px 5px 8.5px 45px;
      border-radius: 5px !important;
      background-color: #e5efff;
      border: none !important;

      &:focus {
         box-shadow: none !important;
      }
   }

   & .input-icon {
      top: 9px;
      left: 11px;
      z-index: 20;
   }
}
.step-number {
   font-size: 20px;
   font-weight: 600;
   height: 50px;
   width: 50px;
   background-color: #ced4da;
   display: flex;
   justify-content: center;
   border-radius: 100%;
}
.step-line {
   background-color: #ced4da;
   margin: 0 10px;
   width: 50px;
   height: 3px;
   border-radius: 50px;
}
.state-bar {
   margin-top: -9px;
   margin-right: 20px;
}
</style>
