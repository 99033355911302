import get from 'lodash/get';

import Router from '@/router';
import AuthApi from '@/api/AuthApi';
import UserApi from '@/api/UserApi';

const state = {
   accessToken: null,
   ipAddress: null,
   authUser: null,
};

const getters = {
   isLoggedIn(state) {
      return !!state.accessToken && !!state.authUser;
   },
   accessToken(state) {
      return state.accessToken;
   },
   ipAddress(state) {
      return state.ipAddress;
   },
   authUser(state) {
      return state.authUser;
   },
};

const actions = {
   setIpAddress(context, ipAddress) {
      context.commit('setIpAddress', ipAddress);
   },
   getAccessToken(context) {
      return context.getters['accessToken'];
   },
   async syncUser(context) {
      const { data: authUser } = await UserApi.me();

      context.commit('setAuthUser', authUser);

      return authUser;
   },
   async login(context, payload) {
      payload.grant_type = 'password';
      payload.provider = 'users';
      payload.client_id = 2;
      payload.client_secret = 'QpK3qIHFNWXbPnRSskijxYuBoKCb7VFhi27x5gBF';
      const response = await AuthApi.login(payload);

      context.commit('setAccessToken', get(response, 'access_token'));

      const userResponse = await UserApi.me();

      context.commit('setAuthUser', get(userResponse, 'data'));

      return response;
   },
   async me(context) {
      const response = await UserApi.me();

      context.commit('me', get(response, 'data'));

      return response;
   },
   async logout(context, opts = {}) {
      // const response = await AuthApi.logout();
      //
      // context.commit('logout', response);

      context.commit('logout');

      if (opts.redirectRoute) {
         await Router.push({ name: opts.redirectRoute });
      }

      // return response;
   },
   async cleanSession(context, opts = {}) {
      context.commit('logout');

      if (opts.redirectRoute) {
         await Router.push({ name: opts.redirectRoute });
      }
   },
};

const mutations = {
   setIpAddress(state, ipAddress) {
      state.ipAddress = ipAddress;
   },
   setAuthUser(state, authUser) {
      state.authUser = authUser;
   },
   setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
   },
   me(state, user) {
      state.authUser = user;
   },
   logout(state) {
      state.accessToken = null;
      state.ipAddress = null;
      state.authUser = null;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
