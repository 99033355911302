<template>
   <AmcText class="pe-4">
      <AmcText class="d-flex justify-content-between shadow rounded-8 p-4">
         <AmcText class="fs-3 align-self-center">{{
            $t('facilities.detail.kullanimBilgileri.Kullanım Bilgileri')
         }}</AmcText>
         <AmcText class="align-self-center">
            <AmcText>
               {{ $t('facilities.detail.kullanimBilgileri.Başlangıç Tarihi') }}:
               <span class="fw-600">{{ moment(get(dataUsing, 'created_at')).format('DD-MM-YYYY') }}</span>
            </AmcText>
            <AmcText class="mt-2">
               {{ $t('facilities.detail.kullanimBilgileri.Bitiş Tarihi') }}:
               <span class="fw-600">{{ moment(get(dataUsing, 'subscription_end_at')).format('DD-MM-YYYY') }}</span>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcText class="mt-5 d-flex justify-content-around">
         <AmcText class="shadow rounded-8 p-5 text-center">
            <AmcText> <IconMail /> </AmcText>
            <AmcText class="total-text mt-2"> {{ get(dataUsing, 'total_report_email') }} </AmcText>
            <AmcText class="fw-600 fs-16">{{ $t('facilities.detail.kullanimBilgileri.E-Posta') }}</AmcText>
            <AmcText class="mt-2">{{ $t('facilities.detail.kullanimBilgileri.Gönderilen Rapor E-Postalar') }}</AmcText>
         </AmcText>
         <AmcText class="shadow rounded-8 p-5 text-center">
            <AmcText> <IconMail stroke="#EB5757" /> </AmcText>
            <AmcText class="total-text mt-2"> {{ get(dataUsing, 'total_event_email') }} </AmcText>
            <AmcText class="fw-600 fs-16">{{ $t('facilities.detail.kullanimBilgileri.Olay E-Posta') }}</AmcText>
            <AmcText class="mt-2">{{ $t('facilities.detail.kullanimBilgileri.Gönderilen Olay E-Postaları') }}</AmcText>
         </AmcText>
         <AmcText class="shadow rounded-8 p-5 text-center">
            <AmcText> <IconPhone /> </AmcText>
            <AmcText class="total-text mt-2"> {{ get(dataUsing, 'total_mobile_push') }} </AmcText>
            <AmcText class="fw-600 fs-16">{{ $t('facilities.detail.kullanimBilgileri.Mobil Push') }}</AmcText>
            <AmcText class="mt-2">{{ $t('facilities.detail.kullanimBilgileri.Mobil Push Gönderim') }}</AmcText>
         </AmcText>
         <AmcText class="shadow rounded-8 p-5 text-center">
            <AmcText> <IconCall /> </AmcText>
            <AmcText class="total-text"> {{ get(dataUsing, 'total_call') }} </AmcText>
            <AmcText class="fw-600 fs-16">{{ $t('facilities.detail.kullanimBilgileri.Sesli Çağrı') }}</AmcText>
            <AmcText class="mt-2">{{ $t('facilities.detail.kullanimBilgileri.Gönderilen Sesli Çağırılar') }}</AmcText>
         </AmcText>
         <AmcText class="shadow rounded-8 p-5 text-center">
            <AmcText> <IconSms /> </AmcText>
            <AmcText class="total-text mt-2"> {{ get(dataUsing, 'total_sent_sms') }} </AmcText>
            <AmcText class="fw-600 fs-16">{{ $t('facilities.detail.kullanimBilgileri.Sms') }}</AmcText>
            <AmcText class="mt-2">{{ $t('facilities.detail.kullanimBilgileri.Gönderilen Sms Sayısı') }}</AmcText>
         </AmcText>
      </AmcText>
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import BuildingApi from '@/api/BuildingApi';
import ErrorHandler from '@/libs/error-handler';
import IconSms from '@/components/icons/IconSms';
import IconCall from '@/components/icons/IconCall';
import IconMail from '@/components/icons/IconMail';
import IconPhone from '@/components/icons/IconPhone';

export default {
   name: 'UserInformation',
   components: {
      IconSms,
      IconMail,
      IconCall,
      IconPhone,
   },
   data() {
      return {
         dataUsing: {},
      };
   },
   methods: {
      get,
      async getBuilding() {
         try {
            const result = await BuildingApi.GET_BUILDING(this.$route.params.id);
            this.dataUsing = result.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
   },
   async beforeMount() {
      await this.getBuilding();
   },
};
</script>

<style scoped lang="scss">
.shadow {
   box-shadow: 0 0 15px rgba(165, 165, 165, 0.15) !important;
}
.total-text {
   font-size: 50px;
   font-weight: 600;
}
</style>
