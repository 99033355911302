<template>
   <AmcText>
      <AmcText class="rounded-8 shadow-sm p-3">
         <AmcText class="d-flex justify-content-between">
            <AmcText class="fs-3"> {{ $t('facilities.detail.yetkililer.Yetkililer') }} </AmcText>
            <AmcText v-if="!readOnly">
               <AmcButton @click="showModal" class="text-white" :icon="IconAdded" ps="3" px="4" height="40px">
                  {{ $t('buttons.Yeni Yönetici Ekle') }}
               </AmcButton>
            </AmcText>
         </AmcText>

         <AmcText
            v-for="(legal, index) in allLegal"
            :key="index"
            :class="{ 'border border-primary rounded': legal.edit }"
            class="mt-4 border-top border-bottom p-2"
         >
            <AmcText class="p-3 d-flex justify-content-between">
               <AmcText>
                  <label class="form-label fw-600 text-secondary">{{
                     $t('facilities.detail.yetkililer.ADI SOYADI')
                  }}</label>
                  <AmcText v-if="!legal.edit" class="text-secondary mt-2 fw-600">
                     {{ legal.name }}
                  </AmcText>
                  <input
                     v-if="legal.edit"
                     v-autowidth="{ maxWidth: '960px', minWidth: '20px', comfortZone: 0 }"
                     type="text"
                     class="table-input form-control mt-2 border-0 p-0 text-primary fw-600 shadow-none"
                     v-model="legal.name"
                  />
               </AmcText>
               <AmcText>
                  <label class="form-label fw-600 text-secondary">{{
                     $t('facilities.detail.yetkililer.E-POSTA')
                  }}</label>
                  <AmcText v-if="!legal.edit" class="text-secondary mt-2"> {{ legal.email }} </AmcText>
                  <input
                     v-if="legal.edit"
                     v-autowidth="{ maxWidth: '960px', minWidth: '20px', comfortZone: 0 }"
                     type="email"
                     class="table-input form-control mt-2 border-0 p-0 text-primary shadow-none"
                     v-model="legal.email"
                  />
               </AmcText>
               <AmcText>
                  <label class="form-label fw-600 text-secondary">{{
                     $t('facilities.detail.yetkililer.TELEFON')
                  }}</label>
                  <AmcText v-if="!legal.edit" class="text-secondary mt-2"> {{ legal.phone }} </AmcText>
                  <input
                     v-if="legal.edit"
                     v-autowidth="{ maxWidth: '960px', minWidth: '20px', comfortZone: 0 }"
                     type="tel"
                     class="table-input form-control mt-2 border-0 p-0 text-primary shadow-none"
                     v-model="legal.phone"
                  />
               </AmcText>
               <AmcText v-if="!readOnly">
                  <label class="form-label fw-600 text-secondary">{{ $t('facilities.detail.yetkililer.İŞLEM') }}</label>
                  <AmcText class="text-secondary text-center" @click="enableEditLegal(legal)">
                     <IconOpenSecondary :class="{ 'icon-rotate fill-primary': legal.edit }" />
                  </AmcText>
               </AmcText>
               <AmcText v-if="!readOnly">
                  <label class="form-label fw-600 text-secondary">{{
                     $t('facilities.detail.yetkililer.AKSİYON')
                  }}</label>
                  <AmcText class="text-secondary">
                     <button
                        @click="showRemoveModal(legal)"
                        v-if="!legal.edit"
                        class="text-center remove-icon ms-4 mt-2"
                     >
                        <IconDel />
                     </button>
                     <AmcText @click="editManager(legal)" class="text-center">
                        <button v-if="legal.edit" class="btn btn-primary">{{ $t('buttons.Kaydet') }}</button>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText v-if="legal.edit" class="p-3 d-flex justify-content-between">
               <AmcText class="d-flex">
                  <AmcText class="d-flex">
                     <input v-model="legal.is_active" type="checkbox" class="checkbox" @change="activeChanged(legal)" />
                     <AmcText :class="{ 'text-primary': legal.is_active }" class="ms-2"
                        >{{ $t('checkbox.Aktif') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="d-flex ms-4" v-if="legal.is_active">
                     <input :disabled="!legal.is_active" v-model="legal.is_manager" type="checkbox" class="checkbox" />
                     <AmcText :class="{ 'text-primary': get(legal, 'is_manager') }" class="ms-2"
                        >{{ $t('checkbox.Yönetici') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="d-flex ms-4" v-if="legal.is_active">
                     <input :disabled="!legal.is_active" v-model="legal.web_report" type="checkbox" class="checkbox" />
                     <AmcText :class="{ 'text-primary': legal.web_report }" class="ms-2">Web </AmcText>
                  </AmcText>
                  <AmcText class="d-flex ms-4" v-if="legal.send_email !== null && legal.is_active">
                     <input :disabled="!legal.is_active" v-model="legal.send_email" type="checkbox" class="checkbox" />
                     <AmcText :class="{ 'text-primary': legal.send_email }" class="ms-2"
                        >{{ $t('checkbox.E-posta') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="d-flex ms-4" v-if="legal.mobile_access !== null && legal.is_active">
                     <input
                        :disabled="!legal.is_active"
                        v-model="legal.mobile_access"
                        type="checkbox"
                        class="checkbox"
                     />
                     <AmcText :class="{ 'text-primary': legal.mobile_access }" class="ms-2">Mobil </AmcText>
                  </AmcText>
                  <AmcText class="d-flex ms-4" v-if="legal.voice_call !== null && legal.is_active">
                     <input :disabled="!legal.is_active" v-model="legal.voice_call" type="checkbox" class="checkbox" />
                     <AmcText :class="{ 'text-primary': legal.voice_call }" class="ms-2"
                        >{{ $t('checkbox.Arama') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="d-flex ms-4" v-if="legal.send_sms !== null && legal.is_active">
                     <input :disabled="!legal.is_active" v-model="legal.send_sms" type="checkbox" class="checkbox" />
                     <AmcText :class="{ 'text-primary': legal.send_sms }" class="ms-2">Sms </AmcText>
                  </AmcText>
               </AmcText>
               <AmcText>
                  <AmcText
                     @click="legal.showNotificationMenu = !legal.showNotificationMenu"
                     :class="{ 'border-primary': legal.showNotificationMenu }"
                     class="d-flex edit-notification"
                  >
                     <AmcText>
                        <IconMiniCalender :class="{ 'stroke-primary': legal.showNotificationMenu }" stroke="#606F89" />
                     </AmcText>
                     <AmcText
                        :class="{ 'text-secondary': !legal.showNotificationMenu }"
                        class="text-primary ms-2 align-self-center"
                     >
                        {{ $t('placeholders.Haftalık Bildirimler') }}
                     </AmcText>
                     <AmcText class="ms-4">
                        <IconDownArrow2
                           :class="{ 'stroke-primary fill-primary icon-rotate': legal.showNotificationMenu }"
                        />
                     </AmcText>
                  </AmcText>
                  <AmcText v-show="legal.showNotificationMenu" class="notification-menu mb-5">
                     <AmcText class="m-2" v-for="(day, index) in days" :key="index">
                        <AmcText
                           class="border p-2 rounded d-flex justify-content-between mb-2"
                           :class="{ 'border-primary bg-primary-light': legal.notifications[day.model] }"
                        >
                           <AmcText :class="{ 'text-primary': legal.notifications[day.model] }">
                              <span class="me-2">
                                 <IconMiniCalender :class="{ 'stroke-primary': legal.notifications[day.model] }" />
                              </span>
                              <span>{{ $t(`days.${day.text}`) }}</span>
                           </AmcText>
                           <AmcText class="form-check form-switch ms-5">
                              <input
                                 v-model="legal.notifications[day.model]"
                                 class="form-check-input"
                                 type="checkbox"
                              />
                           </AmcText>
                        </AmcText>
                        <AmcText v-show="legal.notifications[day.model]">
                           <AmcText class="d-flex">
                              <select
                                 v-model="legal.notifications[day.startTime]"
                                 class="form-select"
                                 aria-label="Default select example"
                              >
                                 <option
                                    v-for="i in range(0, 24)"
                                    :key="i"
                                    :value="`${i > 9 ? i : `0${i}`}:00:00`"
                                    v-html="`${i > 9 ? i : `0${i}`}:00`"
                                 />
                              </select>
                              <span class="hour-line"></span>
                              <select
                                 v-model="legal.notifications[day.endTime]"
                                 class="form-select"
                                 aria-label="Default select example"
                              >
                                 <option
                                    v-for="i in range(0, 24)"
                                    :key="i"
                                    :value="`${i > 9 ? i : `0${i}`}:00:00`"
                                    v-html="`${i > 9 ? i : `0${i}`}:00`"
                                 />
                              </select>
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText class="m-2 d-flex justify-content-end align-self-end">
                        <button @click="editNotifications(legal)" class="me-3 btn btn-small btn-primary">
                           {{ $t('modalActions.Güncelle') }}
                        </button>
                        <button @click="legal.showNotificationMenu = false" class="btn btn-small btn-outline-secondary">
                           {{ $t('modalActions.Kapat') }}
                        </button>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcRemoveModal ref="removeModal" @remove="removeManager" />
      <!-- Add new manager modal start -->
      <AmcModal ref="userModal" lg>
         <template #header>
            <AmcText class="h4 pt-3">{{ $t('facilities.detail.yetkililer.modal.Yetkili Ekle') }}</AmcText>
         </template>
         <template>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.yetkililer.modal.Ünvan') }}</AmcText>
               <input
                  v-model="manager.title"
                  type="text"
                  class="form-control shadow-none"
                  @keyup="manager.isValid()"
                  :class="{ 'border-danger': manager.errors.has('title') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in manager.errors.get('title')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.yetkililer.modal.Adı Soyadı') }}</AmcText>
               <input
                  v-model="manager.name"
                  type="text"
                  class="form-control shadow-none"
                  @keyup="manager.isValid()"
                  :class="{ 'border-danger': manager.errors.has('name') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in manager.errors.get('name')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">E-Posta</AmcText>
               <input
                  v-model="manager.email"
                  type="email"
                  class="form-control shadow-none"
                  @keyup="manager.isValid()"
                  :class="{ 'border-danger': manager.errors.has('email') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in manager.errors.get('email')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText>
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.yetkililer.modal.Telefon') }}</AmcText>
               <VuePhoneNumberInput
                  v-model="manager.phone_nonformatted"
                  default-country-code="TR"
                  formatInternational
                  @keyup="manager.isValid()"
                  :class="{ 'border-danger': manager.errors.has('phone_nonformatted') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in manager.errors.get('phone_nonformatted')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.yetkililer.modal.Parola') }}</AmcText>
               <input
                  v-model="manager.password"
                  type="password"
                  class="form-control shadow-none"
                  @keyup="manager.isValid()"
                  :class="{ 'border-danger': manager.errors.has('password') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in manager.errors.get('password')"
                  v-html="error"
               ></span>
            </AmcText>
         </template>
         <template #actions>
            <button type="button" class="btn bg-transparent text-black border px-5 py-2 me-2" @click="reset()">
               {{ $t('modalActions.Vazgeç') }}
            </button>
            <button @click="createLegal" class="btn btn-primary px-5 py-2">{{ $t('modalActions.Kaydet') }}</button>
         </template>
      </AmcModal>
      <!-- Add new manager modal finish -->
      <AmcLoading v-if="loading" />
   </AmcText>
</template>

<script>
import map from 'lodash/map';
import get from 'lodash/get';

import range from 'lodash/range';
import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import LegalApi from '@/api/LegalApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconAdded from '@/components/icons/IconAdded';
import VuePhoneNumberInput from 'vue-phone-number-input';
import IconDownArrow2 from '@/components/icons/IconDownArrow2';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import IconMiniCalender from '@/components/icons/IconMiniCalender';
import IconOpenSecondary from '@/components/icons/IconOpenSecondary';
import SendNotificationApi from '@/api/SendNotificationApi';

export default {
   name: 'Authorities',
   components: {
      IconOpenSecondary,
      IconDel,
      IconMiniCalender,
      IconDownArrow2,
      VuePhoneNumberInput,
   },
   data() {
      return {
         allLegal: [],
         IconAdded,
         loading: false,
         days: [
            {
               text: 'Pazartesi',
               model: 'monday',
               startTime: 'monday_start',
               endTime: 'monday_end',
            },
            {
               text: 'Salı',
               model: 'tuesday',
               startTime: 'tuesday_start',
               endTime: 'tuesday_end',
            },
            {
               text: 'Çarşamba',
               model: 'wednesday',
               startTime: 'wednesday_start',
               endTime: 'wednesday_end',
            },
            {
               text: 'Perşembe',
               model: 'thursday',
               startTime: 'thursday_start',
               endTime: 'thursday_end',
            },
            {
               text: 'Cuma',
               model: 'friday',
               startTime: 'friday_start',
               endTime: 'friday_end',
            },
            {
               text: 'Cumartesi',
               model: 'saturday',
               startTime: 'saturday_start',
               endTime: 'saturday_end',
            },
            {
               text: 'Pazar',
               model: 'sunday',
               startTime: 'sunday_start',
               endTime: 'sunday_end',
            },
         ],
         readOnly: false,
         manager: Form.create([
            'title',
            'name',
            'email',
            'phone',
            'phone_nonformatted',
            'is_manager',
            'web_report',
            'mobile_access',
            'voice_call',
            'send_sms',
            'send_email',
            'is_active',
            'password',
         ])
            .validate({
               title: 'required',
               name: 'required',
               email: 'required',
               phone_nonformatted: 'required',
               is_manager: 'required',
               web_report: 'required',
               mobile_access: 'required',
               voice_call: 'required',
               send_sms: 'required',
               send_email: 'required',
               is_active: 'required',
               password: 'required',
            })
            .defaults({
               title: '',
               name: '',
               email: '',
               phone: '',
               phone_nonformatted: '',
               is_manager: false,
               is_active: true,
               web_report: false,
               mobile_access: false,
               voice_call: false,
               send_sms: false,
               send_email: false,
            }),
      };
   },
   methods: {
      get,
      range,
      async getLegal() {
         try {
            const result = await LegalApi.index(this.$route.params.id);
            this.allLegal = map(result.data, (item) => {
               item.edit = false;
               item.showNotificationMenu = false;
               return item;
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async createLegal() {
         if (!this.checkValidation()) return;
         this.manager.phone = replace(this.manager.phone_nonformatted, /\s/g, '');
         let haveZero = startsWith(this.manager.phone, '0');
         if (haveZero) {
            this.manager.phone = '+9' + this.manager.phone;
         } else {
            this.manager.phone = '+90' + this.manager.phone;
         }
         this.loading = true;
         try {
            await LegalApi.CREATE_LEGAL(this.$route.params.id, this.manager.toObject());
            await this.getLegal();
            Notify.success(this.$t('notify.Yetkili Eklendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.reset();
            this.manager.password = null;
            this.loading = false;
         }
      },
      async activeChanged(legal) {
         if (!legal.is_active) {
            legal.send_sms = legal.send_sms !== null ? false : null;
            legal.send_email = legal.send_email !== null ? false : null;
            legal.mobile_access = legal.mobile_access !== null ? false : null;
            legal.voice_call = legal.voice_call !== null ? false : null;

            legal.is_manager = false;
            legal.web_report = false;
         }
      },
      async editManager(item) {
         this.loading = true;
         try {
            await LegalApi.EDIT_LEGAL(this.$route.params.id, item.id, item);
            Notify.success(this.$t('notify.Yetkili Bilgileri Güncellendi'));
            this.loading = false;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editNotifications(item) {
         this.loading = true;
         const payload = item.notifications;
         payload.legal_id = item.id;
         try {
            await SendNotificationApi.EDIT_NOTIFICATIONS(this.$route.params.id, payload);
            Notify.success(this.$t('notify.Yetkili Bilgileri Güncellendi'));
            this.loading = false;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeManager(item) {
         try {
            await LegalApi.REMOVE_LEGAL(this.$route.params.id, item.id);
            Notify.error(this.$t('notify.Yetkili Silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            await this.getLegal();
         }
      },
      showModal() {
         this.$refs.userModal.show();
      },
      hideModal() {
         this.$refs.userModal.hide();
      },
      showRemoveModal(item) {
         this.$refs.removeModal.show(item);
      },
      checkValidation() {
         return this.manager.isValid({
            disableDirtyCheck: true,
         });
      },
      enableEditLegal(legal) {
         legal.edit = !legal.edit;
         legal.showNotificationMenu = false;
      },
      reset() {
         this.manager.reset();
         this.manager.password = null;
         this.hideModal();
      },
   },
   async beforeMount() {
      this.readOnly = this.$store.state.auth.authUser.read_only;
      await this.getLegal();
   },
};
</script>

<style scoped lang="scss">
.edit-notification {
   cursor: pointer;
   padding-bottom: 8px;
   border-bottom: 1px solid #606f89;
}
.notification-menu {
   z-index: 999;
   position: absolute;
   right: 2%;
   box-shadow: 0 0 10px #ececec;
   border-radius: 5px;
   margin-top: 10px;
   background-color: #ffffff;
   padding: 10px;
   display: grid;
   grid-template-columns: auto auto;
}
.border-primary {
   border: 1px solid #137af6;
   border-radius: 5px;
   padding: 10px;
   transition: 1s ease;
}
.stroke-primary {
   stroke: #137af6;
   transition: 1s ease;
}
.fill-primary {
   fill: #137af6;
   transition: 1s ease;
}
.icon-rotate {
   transform: rotate(180deg);
   transition: 1s ease;
}
.bg-primary-light {
   background-color: #f2f8fd;
}
.form-select {
   border-color: #137af6;
   margin-bottom: 7px;
   &:focus {
      box-shadow: none !important;
      border-color: #137af6;
   }
   &:after {
      color: #137af6;
   }
}
.hour-line {
   display: block;
   width: 20px;
   height: 3px;
   background-color: #3e5466;
   margin: 15px 7px;
}
</style>
