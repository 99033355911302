import Http from '../libs/http';

class ModemApi extends Http {
   index(ID) {
      return super.get(`api/panel/building/${ID}/modem`);
   }

   DETAIL_GET_MODEM(ID, id) {
      return super.get(`api/panel/building/${ID}/modem/${id}`);
   }

   CREATE_MODEM(ID, payload) {
      return super.post(`api/panel/building/${ID}/modem`, payload);
   }

   EDIT_MODEM(ID, id, payload) {
      return super.put(`api/panel/building/${ID}/modem/${id}`, payload);
   }

   REMOVE_MODEM(ID, id) {
      return super.delete(`api/panel/building/${ID}/modem/${id}`);
   }

   list(params) {
      return super.get('api/panel/modem', { params, disableCaseConversion: true });
   }
}

export default new ModemApi();
