import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';

import Logs from '@/views/Pages/Logs';
import Modems from '@/views/Pages/modems/Modems';
import Login from '@/views/Login/Login.vue';
import Reports from '@/views/Pages/reports/Index';
import ModemLog from '@/views/Pages/reports/ModemLog';
import ModemList from '@/views/Pages/reports/ModemList';
import UserList from '@/views/Pages/reports/UserList';
import Feedback from '@/views/Pages/Feedback';
import Settings from '@/views/Pages/Settings';
import User from '@/views/Pages/user-page/User';
import Dashboard from '@/views/Pages/dashboard/Dashboard';
import StatusMonitor from '@/views/Pages/StatusMonitor';
import ResetPassword from '@/views/Login/ResetPassword';
import Projects from '@/views/Pages/projects-page/Projects';
import Facilities from '@/views/Pages/facilities/Facilities';
import Locations from '@/views/Pages/definition-page/Locations';
import Definition from '@/views/Pages/definition-page/Definition';
import RiskClasses from '@/views/Pages/definition-page/RiskClasses';
import PanelBrands from '@/views/Pages/definition-page/PanelBrands';
import ProjectManagers from '@/views/Pages/user-page/ProjectManagers';
import Photos from '@/views/Pages/facilities/facilities-detail-page/Files';
import Detail from '@/views/Pages/facilities/facilities-detail-page/Detail';
import DetailDevices from '@/views/Pages/projects-page/detil-project/DetailDevices';
import DetailProjects from '@/views/Pages/projects-page/detil-project/DetailProjects';
import Authorities from '@/views/Pages/facilities/facilities-detail-page/Authorities';
import DetailModems from '@/views/Pages/facilities/facilities-detail-page/DetailModems';
import DetailReadyMessages from '@/views/Pages/projects-page/detil-project/DetailReadyMessages';
import UsageInformation from '@/views/Pages/facilities/facilities-detail-page/UsageInformation';
import NotificationSettings from '@/views/Pages/facilities/facilities-detail-page/NotificationSettings';

Vue.use(VueRouter);

const routes = [
   {
      path: '/login',
      name: 'Login',
      component: Login,
   },
   {
      path: '/sifre-sifirlama',
      name: 'ResetPassword',
      component: ResetPassword,
   },
   {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      children: [
         {
            path: 'logs',
            name: 'Logs',
            component: Logs,
         },
         {
            path: 'status-monitor',
            name: 'StatusMonitor',
            component: StatusMonitor,
         },
         {
            path: 'reports',
            name: 'Reports',
            component: Reports,
            children: [
               {
                  path: '/reports/modem-logs',
                  name: 'ModemLogs',
                  component: ModemLog,
               },
               {
                  path: '/reports/modem-list',
                  name: 'ModemList',
                  component: ModemList,
               },
               {
                  path: '/reports/user-list',
                  name: 'UserList',
                  component: UserList,
               },
            ],
         },
         {
            path: 'modems',
            name: 'Modems',
            component: Modems,
         },
         {
            path: 'definition',
            name: 'Definition',
            component: Definition,
            children: [
               {
                  path: '/definition/risk-classes',
                  name: 'RiskClasses',
                  component: RiskClasses,
               },
               {
                  path: '/definition/panel-brands',
                  name: 'PanelBrands',
                  component: PanelBrands,
               },
               {
                  path: '/definition/locations',
                  name: 'Locations',
                  component: Locations,
               },
            ],
         },
         {
            path: 'user',
            name: 'User',
            component: User,
            children: [
               {
                  path: '/user/project-managers',
                  name: 'ProjectManagers',
                  component: ProjectManagers,
               },
            ],
         },
         {
            path: 'feedback',
            name: 'Feedback',
            component: Feedback,
         },
         {
            path: 'facilities',
            name: 'Facilities',
            component: Facilities,
         },
         {
            path: 'detail/:id',
            name: 'Detail',
            component: Detail,
            children: [
               {
                  path: '/detail/:id/authorities',
                  name: 'Authorities',
                  component: Authorities,
               },
               {
                  path: '/detail/:id/modems',
                  name: 'DetailModems',
                  component: DetailModems,
               },
               {
                  path: '/detail/:id/usage-information',
                  name: 'UsageInformation',
                  component: UsageInformation,
               },
               {
                  path: '/detail/:id/files',
                  name: 'Photos',
                  component: Photos,
               },
               {
                  path: '/detail/:id/notification-settings',
                  name: 'NotificationSettings',
                  component: NotificationSettings,
               },
            ],
         },
         {
            path: 'projects',
            name: 'Projects',
            component: Projects,
         },
         {
            path: 'projects-detail/:id',
            name: 'DetailProjects',
            component: DetailProjects,
            children: [
               {
                  path: '/projects-detail/:id/device',
                  name: 'DetailDevices',
                  component: DetailDevices,
               },
               {
                  path: '/projects-detail/:id/message',
                  name: 'DetailReadyMessages',
                  component: DetailReadyMessages,
               },
            ],
         },
         {
            path: 'settings',
            name: 'Settings',
            component: Settings,
         },
      ],
   },
   { path: '*', redirect: '/' },
];

const router = new VueRouter({
   mode: 'history',
   routes,
   scrollBehavior: (to) => {
      if (to.hash) {
         return { selector: to.hash };
      } else {
         return { x: 0, y: 0 };
      }
   },
});

router.beforeEach(async (to, from, next) => {
   if (to.query.reloadAuthentication) {
      await Store.dispatch('auth/me');
      const query = Object.assign({}, to.query);
      delete query['reloadAuthentication'];

      await router.replace({ ...to, query });
   }

   const isLoggedIn = Store.getters['auth/isLoggedIn'];
   const guestRoutes = ['Login', 'ResetPassword'];

   if (!isLoggedIn && guestRoutes.indexOf(to.name) === -1) return next('/login');

   if (isLoggedIn && guestRoutes.indexOf(to.name) !== -1) {
      return next('/');
   }

   if (isLoggedIn) Store.dispatch('auth/me');

   return next();
});

export default router;
