<template>
   <svg width="569" height="80" viewBox="0 0 569 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M132.962 39.7595C132.962 34.1981 130.986 29.4337 127.052 25.4992C123.117 21.5648 118.353 19.5893 112.791 19.5893C107.213 19.5893 102.465 21.5648 98.5302 25.4992C94.5957 29.4337 92.6201 34.1981 92.6201 39.7595V75.0697H77.4794V39.7595C77.4794 29.9815 80.916 21.6644 87.8056 14.775C94.6953 7.88562 103.013 4.44922 112.791 4.44922C122.569 4.44922 130.887 7.88562 137.776 14.775C138.772 15.7711 139.702 16.8003 140.532 17.8462C141.379 16.8003 142.292 15.7711 143.288 14.775C150.178 7.88562 158.495 4.44922 168.274 4.44922C178.052 4.44922 186.369 7.88562 193.259 14.775C200.149 21.6644 203.585 29.9815 203.585 39.7595V75.0697H188.444V39.7595C188.444 34.1981 186.469 29.4337 182.534 25.4992C178.6 21.5648 173.835 19.5893 168.274 19.5893C162.695 19.5893 157.947 21.5648 154.013 25.4992C150.078 29.4337 148.103 34.1981 148.103 39.7595V75.0697H132.962V39.7595Z"
         fill="#137AF6"
      />
      <path
         d="M269.127 64.7434C262.237 71.6328 253.92 75.0692 244.141 75.0692C234.363 75.0692 226.046 71.6328 219.156 64.7434C212.266 57.854 208.83 49.5369 208.83 39.759C208.83 29.981 212.266 21.6639 219.156 14.7745C226.046 7.88513 234.363 4.44873 244.141 4.44873C253.92 4.44873 262.237 7.88513 269.127 14.7745L269.21 14.8575L258.485 25.5817L258.402 25.4987C254.468 21.5643 249.703 19.5888 244.141 19.5888C238.563 19.5888 233.815 21.5643 229.881 25.4987C225.946 29.4332 223.97 34.1976 223.97 39.759C223.97 45.3369 225.946 50.0848 229.881 54.0192C233.815 57.9536 238.58 59.9291 244.141 59.9291C249.703 59.9291 254.468 57.9536 258.402 54.0192L258.485 53.9362L269.21 64.6604L269.127 64.7434Z"
         fill="#137AF6"
      />
      <path
         d="M327.184 48.0764L325.026 42.8637H317.157L314.932 48.0764H312.658L321.158 28.7031L329.442 48.0764H327.184ZM317.954 40.938H324.212L321.108 33.534L317.954 40.938Z"
         fill="#137AF6"
      />
      <path d="M331.004 27.8899H332.996V48.0933H331.004V27.8899Z" fill="#137AF6" />
      <path
         d="M345.03 38.2156V36.5887H347.023V48.0932H345.03V46.4829C344.034 47.7777 342.723 48.4252 341.096 48.4252C339.552 48.4252 338.224 47.8939 337.128 46.8149C336.032 45.7358 335.501 44.2417 335.501 42.3326C335.501 40.4567 336.049 38.9792 337.128 37.9168C338.207 36.8543 339.535 36.3231 341.113 36.3231C342.756 36.3065 344.068 36.9373 345.03 38.2156ZM341.312 38.0828C340.266 38.0828 339.369 38.4646 338.639 39.2116C337.908 39.9587 337.543 41.0045 337.543 42.3326C337.543 43.6109 337.875 44.6402 338.556 45.4536C339.237 46.2504 340.15 46.6655 341.312 46.6655C342.474 46.6655 343.404 46.2504 344.101 45.4204C344.798 44.5904 345.147 43.5777 345.147 42.3658C345.147 41.0045 344.765 39.9421 343.985 39.2116C343.204 38.448 342.308 38.0828 341.312 38.0828Z"
         fill="#137AF6"
      />
      <path
         d="M350.228 48.0765V36.572H352.221V37.8171C352.868 36.8044 353.731 36.2898 354.827 36.2898C355.375 36.2898 355.973 36.4392 356.604 36.7546L355.657 38.5475C355.292 38.2819 354.894 38.1657 354.495 38.1657C353.798 38.1657 353.25 38.3981 352.885 38.8795C352.436 39.4772 352.221 40.5064 352.221 41.9839V48.0599H350.228V48.0765Z"
         fill="#137AF6"
      />
      <path
         d="M357.881 48.0765V36.572H359.873V37.7839C360.554 36.7878 361.5 36.2898 362.728 36.2898C364.106 36.2898 365.152 36.904 365.866 38.1491C366.663 36.904 367.809 36.2898 369.336 36.2898C371.826 36.2898 373.071 37.7839 373.071 40.7721V48.0599H371.079V41.0709C371.079 40.523 371.046 40.0582 370.979 39.6764C370.913 39.2946 370.714 38.9293 370.398 38.5807C370.083 38.2321 369.618 38.0495 369.02 38.0495C367.327 38.0495 366.464 39.2448 366.464 41.6519V48.0599H364.472V41.2369C364.472 39.112 363.774 38.0495 362.396 38.0495C360.72 38.0495 359.873 39.2946 359.873 41.7847V48.0433H357.881V48.0765Z"
         fill="#137AF6"
      />
      <path
         d="M384.178 48.0762H382.07L385.141 28.5701L392.23 44.175L399.468 28.5535L402.357 48.0928H400.249L398.505 35.2436L392.213 48.873L386.004 35.2436L384.178 48.0762Z"
         fill="#137AF6"
      />
      <path
         d="M415.304 46.6986C414.142 47.8441 412.698 48.4085 410.938 48.4085C409.178 48.4085 407.734 47.8441 406.572 46.6986C405.41 45.5531 404.845 44.1089 404.845 42.3824C404.845 40.6226 405.426 39.1784 406.572 38.0329C407.734 36.8874 409.178 36.323 410.938 36.323C412.698 36.323 414.142 36.904 415.304 38.0329C416.466 39.1784 417.031 40.6226 417.031 42.3824C417.031 44.1089 416.466 45.5531 415.304 46.6986ZM413.81 39.2116C413.03 38.4479 412.067 38.0827 410.938 38.0827C409.809 38.0827 408.863 38.4645 408.066 39.2282C407.286 39.9918 406.887 41.0377 406.887 42.3657C406.887 43.6938 407.286 44.7397 408.083 45.5033C408.879 46.267 409.826 46.6322 410.938 46.6322C412.05 46.6322 412.997 46.2504 413.793 45.5033C414.59 44.7397 414.989 43.7104 414.989 42.3657C414.989 41.0211 414.59 39.9752 413.81 39.2116Z"
         fill="#137AF6"
      />
      <path
         d="M419.54 48.0765V36.572H421.532V37.9001C422.346 36.821 423.442 36.2898 424.836 36.2898C426.314 36.2898 427.409 36.821 428.14 37.8669C428.588 38.5143 428.804 39.5436 428.804 40.9381V48.0599H426.812V40.9879C426.812 40.0416 426.629 39.3444 426.28 38.8795C425.849 38.3317 425.234 38.0495 424.404 38.0495C423.558 38.0495 422.877 38.3483 422.379 38.9293C421.831 39.5768 421.549 40.606 421.549 42.0337V48.0433H419.54V48.0765Z"
         fill="#137AF6"
      />
      <path
         d="M432.008 33.2353C431.725 32.953 431.593 32.621 431.593 32.2392C431.593 31.8574 431.725 31.5254 432.008 31.2431C432.29 30.9609 432.622 30.8281 433.004 30.8281C433.386 30.8281 433.718 30.9609 434 31.2431C434.282 31.5254 434.415 31.8574 434.415 32.2392C434.415 32.621 434.282 32.953 434 33.2353C433.718 33.5175 433.386 33.6503 433.004 33.6503C432.605 33.6503 432.273 33.5009 432.008 33.2353ZM432.008 36.5887H434V48.0931H432.008V36.5887Z"
         fill="#137AF6"
      />
      <path
         d="M441.254 38.4146H439.262V48.0763H437.269V38.4146H436.057V36.5885H437.269V32.4382H439.262V36.5885H441.254V38.4146Z"
         fill="#137AF6"
      />
      <path
         d="M452.327 46.6986C451.165 47.8441 449.721 48.4085 447.961 48.4085C446.201 48.4085 444.757 47.8441 443.595 46.6986C442.432 45.5531 441.868 44.1089 441.868 42.3824C441.868 40.6226 442.449 39.1784 443.595 38.0329C444.757 36.8874 446.201 36.323 447.961 36.323C449.721 36.323 451.165 36.904 452.327 38.0329C453.489 39.1784 454.054 40.6226 454.054 42.3824C454.054 44.1089 453.473 45.5531 452.327 46.6986ZM450.833 39.2116C450.053 38.4479 449.09 38.0827 447.961 38.0827C446.832 38.0827 445.886 38.4645 445.089 39.2282C444.308 39.9918 443.91 41.0377 443.91 42.3657C443.91 43.6938 444.308 44.7397 445.105 45.5033C445.902 46.267 446.848 46.6322 447.961 46.6322C449.073 46.6322 450.019 46.2504 450.816 45.5033C451.613 44.7397 452.012 43.7104 452.012 42.3657C452.012 41.0211 451.613 39.9752 450.833 39.2116Z"
         fill="#137AF6"
      />
      <path
         d="M456.544 48.0765V36.572H458.536V37.8171C459.183 36.8044 460.047 36.2898 461.142 36.2898C461.69 36.2898 462.288 36.4392 462.919 36.7546L461.972 38.5475C461.607 38.2819 461.209 38.1657 460.81 38.1657C460.113 38.1657 459.565 38.3981 459.2 38.8795C458.752 39.4772 458.536 40.5064 458.536 41.9839V48.0599H456.544V48.0765Z"
         fill="#137AF6"
      />
      <path
         d="M464.196 33.2353C463.914 32.953 463.781 32.621 463.781 32.2392C463.781 31.8574 463.914 31.5254 464.196 31.2431C464.478 30.9609 464.81 30.8281 465.192 30.8281C465.574 30.8281 465.906 30.9609 466.188 31.2431C466.47 31.5254 466.603 31.8574 466.603 32.2392C466.603 32.621 466.47 32.953 466.188 33.2353C465.906 33.5175 465.574 33.6503 465.192 33.6503C464.81 33.6503 464.478 33.5009 464.196 33.2353ZM464.196 36.5887H466.188V48.0931H464.196V36.5887Z"
         fill="#137AF6"
      />
      <path
         d="M469.41 48.0765V36.572H471.402V37.9001C472.216 36.821 473.311 36.2898 474.706 36.2898C476.183 36.2898 477.279 36.821 478.009 37.8669C478.458 38.5143 478.674 39.5436 478.674 40.9381V48.0599H476.681V40.9879C476.681 40.0416 476.499 39.3444 476.15 38.8795C475.718 38.3317 475.104 38.0495 474.274 38.0495C473.427 38.0495 472.747 38.3483 472.249 38.9293C471.701 39.5768 471.419 40.606 471.419 42.0337V48.0433H469.41V48.0765Z"
         fill="#137AF6"
      />
      <path
         d="M490.677 38.2156V36.5887H492.67V47.8109C492.67 49.8196 492.304 51.3303 491.557 52.3264C490.511 53.7541 488.967 54.4513 486.959 54.4513C485.265 54.4513 483.887 53.9367 482.858 52.9074C481.862 51.9114 481.364 50.6663 481.364 49.1888H483.323C483.323 50.1185 483.622 50.8987 484.203 51.5793C484.867 52.3264 485.796 52.6916 486.975 52.6916C488.353 52.6916 489.382 52.1604 490.03 51.0813C490.462 50.4007 490.661 49.4212 490.661 48.143V46.4829C489.665 47.7777 488.353 48.4252 486.726 48.4252C485.132 48.4252 483.821 47.9105 482.792 46.8813C481.679 45.769 481.131 44.2583 481.131 42.3326C481.131 40.4733 481.679 38.9792 482.792 37.867C483.871 36.8377 485.182 36.3231 486.726 36.3231C488.386 36.3065 489.698 36.9373 490.677 38.2156ZM486.975 38.0828C485.962 38.0828 485.099 38.448 484.369 39.1618C483.588 39.9587 483.207 41.0045 483.207 42.316C483.207 43.7769 483.638 44.9058 484.502 45.7026C485.182 46.3334 486.012 46.6489 486.975 46.6489C488.004 46.6489 488.851 46.3334 489.499 45.686C490.362 44.856 490.794 43.7437 490.794 42.3658C490.794 40.9879 490.395 39.8923 489.582 39.1286C488.901 38.4148 488.021 38.0828 486.975 38.0828Z"
         fill="#137AF6"
      />
      <path
         d="M516.427 31.1267V33.6833C515.68 32.9528 514.883 32.3718 514.02 31.9734C512.974 31.492 511.911 31.2595 510.832 31.2595C508.707 31.2595 506.931 32.0066 505.52 33.4841C504.109 34.9782 503.395 36.7877 503.395 38.9624C503.395 41.2865 504.158 43.1292 505.702 44.4739C507.246 45.8186 508.956 46.4992 510.849 46.4992C512.028 46.4992 513.173 46.217 514.285 45.636C515.066 45.2541 515.78 44.7229 516.41 44.0921V46.6486C514.734 47.8439 512.874 48.4415 510.849 48.4415C508.11 48.4415 505.835 47.5285 504.026 45.7024C502.199 43.8763 501.303 41.6185 501.303 38.9458C501.303 36.19 502.216 33.8991 504.059 32.073C505.902 30.2469 508.209 29.3338 510.965 29.3338C513.007 29.3006 514.817 29.8983 516.427 31.1267Z"
         fill="#137AF6"
      />
      <path
         d="M527.68 44.2248L529.34 45.1379C528.959 45.8683 528.477 46.4991 527.929 46.9972C526.883 47.9434 525.605 48.4083 524.111 48.4083C522.567 48.4083 521.222 47.9102 520.11 46.8976C518.998 45.8849 518.433 44.3908 518.433 42.3987C518.433 40.639 518.931 39.1947 519.944 38.0327C520.957 36.8706 522.301 36.3062 524.011 36.3062C525.937 36.3062 527.381 37.0034 528.361 38.4145C529.141 39.5267 529.49 40.971 529.44 42.7307H520.492C520.508 43.8928 520.874 44.839 521.571 45.5529C522.268 46.2667 523.131 46.6319 524.144 46.6319C525.057 46.6319 525.838 46.3497 526.502 45.8019C527 45.3703 527.398 44.839 527.68 44.2248ZM520.608 41.0706H527.365C527.199 40.1576 526.8 39.4437 526.186 38.8959C525.572 38.3481 524.841 38.0825 523.978 38.0825C523.148 38.0825 522.418 38.3647 521.77 38.9291C521.139 39.4935 520.757 40.2074 520.608 41.0706Z"
         fill="#137AF6"
      />
      <path
         d="M531.915 48.0765V36.572H533.908V37.9001C534.721 36.821 535.817 36.2898 537.211 36.2898C538.689 36.2898 539.785 36.821 540.515 37.8669C540.963 38.5143 541.179 39.5436 541.179 40.9381V48.0599H539.187V40.9879C539.187 40.0416 539.004 39.3444 538.656 38.8795C538.224 38.3317 537.61 38.0495 536.78 38.0495C535.933 38.0495 535.252 38.3483 534.754 38.9293C534.206 39.5768 533.924 40.606 533.924 42.0337V48.0433H531.915V48.0765Z"
         fill="#137AF6"
      />
      <path
         d="M548.435 38.4146H546.442V48.0763H544.45V38.4146H543.238V36.5885H544.45V32.4382H546.442V36.5885H548.435V38.4146Z"
         fill="#137AF6"
      />
      <path
         d="M558.296 44.2248L559.956 45.1379C559.574 45.8683 559.093 46.4991 558.545 46.9972C557.499 47.9434 556.221 48.4083 554.727 48.4083C553.183 48.4083 551.838 47.9102 550.726 46.8976C549.613 45.8849 549.049 44.3908 549.049 42.3987C549.049 40.639 549.547 39.1947 550.559 38.0327C551.572 36.8706 552.917 36.3062 554.627 36.3062C556.553 36.3062 557.997 37.0034 558.977 38.4145C559.757 39.5267 560.105 40.971 560.056 42.7307H551.107C551.124 43.8928 551.489 44.839 552.186 45.5529C552.884 46.2667 553.747 46.6319 554.76 46.6319C555.673 46.6319 556.453 46.3497 557.117 45.8019C557.615 45.3703 558.014 44.839 558.296 44.2248ZM551.224 41.0706H557.98C557.814 40.1576 557.416 39.4437 556.802 38.8959C556.187 38.3481 555.457 38.0825 554.594 38.0825C553.764 38.0825 553.033 38.3647 552.386 38.9291C551.771 39.4935 551.373 40.2074 551.224 41.0706Z"
         fill="#137AF6"
      />
      <path
         d="M562.528 48.0765V36.572H564.52V37.8171C565.168 36.8044 566.031 36.2898 567.127 36.2898C567.674 36.2898 568.272 36.4392 568.903 36.7546L567.957 38.5475C567.591 38.2819 567.193 38.1657 566.795 38.1657C566.097 38.1657 565.549 38.3981 565.184 38.8795C564.736 39.4772 564.52 40.5064 564.52 41.9839V48.0599H562.528V48.0765Z"
         fill="#137AF6"
      />
      <path d="M293.067 0H292.204V80H293.067V0Z" fill="#137AF6" />
      <path
         d="M70.4405 8.25042V75.0859H54.4366V41.7346C54.4366 36.4056 52.5606 31.857 48.8253 28.1218C45.0899 24.3866 40.5577 22.5106 35.212 22.5106C32.6387 22.5106 30.1651 23.0087 27.8077 23.9715C25.4502 24.951 23.375 26.3289 21.5987 28.1052C17.8633 31.8404 15.9873 36.3724 15.9873 41.7179C15.9873 46.9639 17.8633 51.4627 21.5987 55.1979C23.375 57.0738 25.4502 58.4683 27.8077 59.398C30.1651 60.3276 32.6387 60.7924 35.212 60.7924C37.8848 60.7924 40.2755 60.3442 42.4171 59.4644L48.1612 73.7412C44.0606 75.7831 39.3126 76.8124 33.8838 76.8124C28.9864 76.8124 24.4707 75.8993 20.3369 74.0732C16.2032 72.2471 12.6172 69.7404 9.59572 66.5364C6.57423 63.3324 4.2168 59.5972 2.52344 55.3307C0.846681 51.0809 0 46.5322 0 41.7346C0 36.8373 0.846681 32.2554 2.54004 27.989C4.23341 23.7225 6.59083 19.9873 9.61232 16.7833C12.6338 13.5793 16.2198 11.0726 20.3535 9.24648C24.4873 7.42038 29.003 6.50732 33.9004 6.50732C38.2501 6.50732 42.1182 7.17136 45.505 8.51604C48.8917 9.84412 51.8634 11.5374 54.4366 13.5793V8.25042H70.4405Z"
         fill="#137AF6"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconAmc',
};
</script>
