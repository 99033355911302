import Vue from 'vue';

//UI Components
Vue.component('AuthorizationCheck', () => import('../components/UI/AuthorizationCheck'));
Vue.component('AmcStateButton', () => import('../components/UI/AmcStateButton'));
Vue.component('AmcRemoveModal', () => import('../components/UI/AmcRemoveModal'));
Vue.component('AmcProgressBar', () => import('../components/UI/AmcProgressBar'));
Vue.component('AmcStatusIcon', () => import('../components/UI/AmcStatusIcon'));
Vue.component('AmcSwitchLang', () => import('../components/UI/AmcSwitchLang'));
Vue.component('AmcPagination', () => import('../components/UI/AmcPagination'));
Vue.component('AmcEditModal', () => import('../components/UI/AmcEditModal'));
Vue.component('AmcStateList', () => import('../components/UI/AmcStateList'));
Vue.component('AmcStateBar', () => import('../components/UI/AmcStateBar'));
Vue.component('AmcSwitches', () => import('../components/UI/AmcSwitches'));
Vue.component('AmcDropdown', () => import('../components/UI/AmcDropdown'));
Vue.component('AmcNavItem', () => import('../components/UI/AmcNavItem'));
Vue.component('AmcSidebar', () => import('../components/UI/AmcSidebar'));
Vue.component('AmcLoading', () => import('../components/UI/AmcLoading'));
Vue.component('AmcButton', () => import('../components/UI/AmcButton'));
Vue.component('AmcImage', () => import('../components/UI/AmcImage'));
Vue.component('AmcInput', () => import('../components/UI/AmcInput'));
Vue.component('AmcTable', () => import('../components/UI/AmcTable'));
Vue.component('AmcModal', () => import('../components/UI/AmcModal'));
Vue.component('AmcBadge', () => import('../components/UI/AmcBadge'));
Vue.component('AmcIcon', () => import('../components/UI/AmcIcon'));
Vue.component('AmcText', () => import('../components/UI/AmcText'));
Vue.component('AmcCard', () => import('../components/UI/AmcCard'));
Vue.component('AmcCol', () => import('../components/UI/AmcCol'));
Vue.component('AmcRow', () => import('../components/UI/AmcRow'));
Vue.component('AmcNav', () => import('../components/UI/AmcNav'));
Vue.component('AmcDot', () => import('../components/UI/AmcDot'));
