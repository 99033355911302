import get from 'lodash/get';
import has from 'lodash/has';
export default (data, header, search) => {
   search = search.toLocaleUpperCase().trim();
   if (data.data && header) {
      const res = data.data.filter((d) => {
         const filtered = header.filter((h) => {
            let val;
            if (has(h, 'value') && has(d, h.value)) {
               val = '' + get(d, h.value);
               return val.toLocaleUpperCase().includes(search);
            }
            return false;
         });
         return filtered.length > 0;
      });
      return { ...data, data: res };
   }
   return data;
};
