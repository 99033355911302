<template>
   <div class="container-fluid">
      <AmcRow>
         <AmcCol col="5">
            <Login />
         </AmcCol>
         <AmcCol class="p-0" col="7">
            <LoginSlider />
         </AmcCol>
      </AmcRow>
   </div>
</template>

<script>
import Login from './LoginForm';
import LoginSlider from './LoginSlider';

export default {
   name: 'Home',
   components: {
      Login,
      LoginSlider,
   },
};
</script>
