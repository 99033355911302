import Http from '../libs/http';

class ModemApi extends Http {
   index(payload) {
      return super.post('api/user/password/email', payload);
   }
   RESET_PASSWORD(payload) {
      return super.post('api/user/password/reset', payload);
   }
}

export default new ModemApi();
