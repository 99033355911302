<template>
   <svg width="31" height="30" viewBox="0 0 31 30" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
         opacity="0.4"
         d="M0 13.7561C0.0757649 17.2989 0.287906 23.3585 0.318212 24.0268C0.425798 25.4557 0.972821 26.8998 1.82442 27.9181C3.00938 29.347 4.46861 29.9849 6.50365 29.9849C9.31605 30.0001 12.4163 30.0001 15.4272 30.0001C18.4503 30.0001 21.3839 30.0001 23.8614 29.9849C25.8676 29.9849 27.3723 29.3303 28.5421 27.9181C29.3937 26.8998 29.9408 25.4406 30.018 24.0268C30.0483 23.4646 30.1999 16.8867 30.2908 13.7561H0Z"
      />
      <path
         d="M14.0088 20.2805V22.2413C14.0088 22.8686 14.5179 23.3778 15.1453 23.3778C15.7726 23.3778 16.2817 22.8686 16.2817 22.2413V20.2805C16.2817 19.6532 15.7726 19.144 15.1453 19.144C14.5179 19.144 14.0088 19.6532 14.0088 20.2805Z"
      />
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M12.4421 19.0261C12.2906 19.5761 11.7617 19.9277 11.189 19.8519C7.32343 19.3125 3.62914 17.9411 0.510655 15.8818C0.190927 15.6727 0 15.3151 0 14.9333V9.68123C0 6.49911 2.59419 3.91098 5.78389 3.91098H8.76448C9.04935 1.71077 10.9132 0 13.1891 0H17.1016C19.3761 0 21.2414 1.71077 21.5263 3.91098H24.5221C27.7027 3.91098 30.2908 6.49911 30.2908 9.68123V14.9333C30.2908 15.3151 30.0983 15.6712 29.7816 15.8818C26.6571 17.9502 22.9477 19.3276 19.0564 19.8655C19.0033 19.8731 18.9518 19.8762 18.9003 19.8762C18.3866 19.8762 17.9275 19.5291 17.7987 19.02C17.4926 17.8138 16.397 16.9698 15.1378 16.9698C13.8619 16.9698 12.7785 17.7956 12.4421 19.0261ZM17.1013 2.27295H13.1888C12.169 2.27295 11.3174 2.96998 11.0629 3.91098H19.2258C18.9712 2.96998 18.1196 2.27295 17.1013 2.27295Z"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconProject',
};
</script>

<style scoped></style>
