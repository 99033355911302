<template>
   <svg stroke="#3B475D" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconMessage',
};
</script>

<style scoped></style>
