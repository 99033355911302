import Http from '../libs/http';

class UserApi extends Http {
   me() {
      return super.get('api/panel/user/me');
   }
   USER_SYSTEM_MANAGER() {
      return super.get('api/panel/user?role=super-admin');
   }
   CREATE_SYSTEM_MANAGER(payload) {
      return super.post('api/panel/user?role=super-admin', payload);
   }
   REMOVE_MANAGER(ID) {
      return super.delete(`api/panel/user/${ID}`);
   }
   EDIT_MANAGER(ID, payload) {
      return super.put(`api/panel/user/${ID}`, payload);
   }
   USER_PROJECT_MANAGER() {
      return super.get('api/panel/user?role=project-admin');
   }
   CREATE_PROJECT_MANAGER(payload) {
      return super.post('api/panel/user', payload);
   }
}

export default new UserApi();
