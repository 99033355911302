<template>
   <svg width="39" height="42" viewBox="0 0 54 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
         <path
            d="M32.182 50.7426C16.1954 48.8645 5.83488 34.5525 9.00725 18.7961C9.14664 18.1038 9.75196 17.5678 10.4565 17.4935L17.2365 16.7786C17.594 16.7408 17.944 16.8262 18.2252 17.0196C18.5065 17.213 18.7012 17.5022 18.7753 17.8367L20.3799 25.0791C20.4484 25.3885 20.4099 25.7191 20.2701 26.0211C20.1303 26.3231 19.8969 26.5801 19.6049 26.7534L15.2122 29.3611C16.5323 34.4483 19.7311 38.7899 24.065 41.5646L27.9074 38.1982C28.1627 37.9745 28.4795 37.8324 28.81 37.7932C29.1405 37.7541 29.4667 37.8201 29.7395 37.9813L36.1261 41.7547C36.4211 41.929 36.6356 42.2039 36.7321 42.5313C36.8287 42.8588 36.8012 43.2179 36.6544 43.546L33.8702 49.7692C33.7286 50.0856 33.4846 50.3541 33.178 50.5309C32.8714 50.7077 32.5203 50.7823 32.182 50.7426V50.7426ZM22.9406 14.752C24.8022 15.4815 26.0331 17.1784 26.1488 19.1745C26.1874 19.8409 25.5742 20.4171 24.8951 20.3517L24.1719 20.2822C23.6985 20.2368 23.3438 19.8847 23.2945 19.4236C23.2467 18.9795 23.0872 18.562 22.8299 18.2072C22.5725 17.8525 22.2251 17.5712 21.8177 17.388C21.3947 17.198 21.1702 16.7515 21.2739 16.2873L21.4324 15.5783C21.581 14.9125 22.3191 14.5084 22.9406 14.752V14.752ZM25.2156 3.64055C33.1837 5.28955 38.3226 12.381 37.4175 20.4609C37.3501 21.0625 36.7764 21.5222 36.1685 21.4637L35.4667 21.3963C34.9147 21.3432 34.531 20.8789 34.5904 20.3326C35.3201 13.6259 31.0565 7.75487 24.46 6.36782C23.9222 6.25471 23.5999 5.74583 23.7209 5.20458L23.8746 4.5165C24.0078 3.92053 24.6228 3.51786 25.2156 3.64055V3.64055ZM24.0401 9.1561C28.9842 10.3757 32.1854 14.7796 31.8095 19.8662C31.7638 20.4847 31.181 20.9707 30.5564 20.9105L29.8521 20.8426C29.3194 20.7913 28.9362 20.3559 28.9716 19.8284C29.2249 16.0585 26.8572 12.795 23.1952 11.8655C22.6827 11.7355 22.3878 11.2361 22.5043 10.7138L22.6584 10.0231C22.7951 9.4107 23.438 9.00757 24.0401 9.1561V9.1561Z"
            fill="#FF5721"
         />
      </g>
      <defs>
         <clipPath id="clip0">
            <rect width="35" height="43" fill="white" transform="translate(0 21.4877) rotate(-35.958)" />
         </clipPath>
      </defs>
   </svg>
</template>

<script>
export default {
   name: 'IconCall',
};
</script>

<style scoped></style>
