import Axios from 'axios';
import Store from '@/store';
import get from 'lodash/get';
import set from 'lodash/set';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';

// import getClientIp from '@/libs/get-client-ip';
import serializeFormData from '@/libs/serialize-form-data';

let createdClient = null;

const createClient = () => {
   if (createdClient) return createdClient;

   console.log(process.env.VUE_APP_API_URL);

   const client = Axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      transformRequest: [
         (data, headers) => {
            const arrayFields = filter(keys(data), (key) => {
               return isArray(get(data, key));
            });

            const fieldsHasFile = merge(
               filter(keys(data), (key) => {
                  const value = get(data, key);
                  return !isArray(value) && value instanceof File;
               }),
               filter(arrayFields, (key) => {
                  const values = get(data, key);
                  return filter(values, (value) => value instanceof File).length;
               })
            );

            if (fieldsHasFile.length) {
               set(headers, 'Content-Type', undefined);

               return serializeFormData(data);
            }

            set(headers, 'Content-Type', 'application/json');

            return JSON.stringify(data);
         },
      ],
   });

   // Add a request interceptor
   client.interceptors.request.use(
      async (config) => {
         const authToken = Store.getters['auth/accessToken'];
         if (authToken) config.headers['Authorization'] = `Bearer ${authToken}`;

         return config;
      },
      (error) => Promise.reject(error)
   );

   // Add a response interceptor
   client.interceptors.response.use(
      (response) => response.data,
      async (err) => {
         const res = err.response;

         const status = get(res, 'status');
         const url = get(res, 'config.url');
         const method = get(res, 'config.method');

         if (status === 401 && method === 'delete' && url.includes('/oauth/token/')) {
            await Store.dispatch('auth/cleanSession', { redirectRoute: 'Login' });
         } else if (res.status === 401) {
            await Store.dispatch('auth/logout', { redirectRoute: 'Login' });
         }

         return Promise.reject(res);
      }
   );

   createdClient = client;

   return client;
};

export default createClient;
