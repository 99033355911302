<template>
   <AmcModal lg ref="modal" class="mt-5 pt-5">
      <template #header>
         <AmcText class="fs-3">{{ item.name }}</AmcText>
      </template>
      <template>
         <AmcText class="fs-4"> {{ $t('definition.lokasyonModal.Şehirler') }} </AmcText>
         <AmcText class="d-flex">
            <input v-model="createCity.name" type="text" class="form-control shadow-none" />
            <button @click="CreateCity(item)" class="btn btn-primary px-5 ms-4">{{ $t('buttons.Ekle') }}</button>
         </AmcText>
         <AmcText class="mt-4 border rounded-8" v-for="city in cities" :key="city.id">
            <AmcText class="d-flex justify-content-between">
               <AmcText class="p-2 align-self-center">
                  <input
                     type="text"
                     class="table-input form-control fw-bold border-0 shadow-none"
                     v-model="city.name"
                  />
               </AmcText>
               <AmcText class="align-self-center p-2">
                  <span class="btn btn-outline-primary me-3" @click="showDistrict(city)">{{
                     $t('buttons.İlçeler')
                  }}</span>
                  <span role="button" @click="EditCity(item, city)"> <IconEdit /> </span>
                  <span role="button" class="ms-3" @click="RemoveCity(item, city)"> <IconDel /> </span>
               </AmcText>
            </AmcText>
            <AmcText v-if="city.district" class="p-4 fs-5 fw-500 text-secondary border-top">
               <AmcText class="d-flex mb-3">
                  <input v-model="createDistrict.name" type="text" class="form-control shadow-none" />
                  <button @click="createDistricts(city)" class="btn btn-primary px-5 ms-4">
                     {{ $t('buttons.Ekle') }}
                  </button>
               </AmcText>
               <AmcText
                  v-for="district in allDistricts"
                  :key="district.id"
                  class="d-flex justify-content-between border rounded-8 mb-3"
               >
                  <AmcText class="align-self-center">
                     <input
                        type="text"
                        class="table-input form-control border-0 shadow-none text-secondary fw-600"
                        v-model="district.name"
                     />
                  </AmcText>
                  <AmcText class="align-self-center p-2">
                     <span role="button" @click="editDistrict(city, district)"> <IconEdit /> </span>
                     <span role="button" class="ms-3" @click="removeDistrict(city, district)"> <IconDel /> </span>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </template>
      <template #actions>
         <button class="btn btn-outline-secondary px-5" @click="close">{{ $t('modalActions.Kapat') }}</button>
      </template>
   </AmcModal>
</template>

<script>
import map from 'lodash/map';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import CitiesApi from '@/api/CitiesApi';
import DistrictsApi from '@/api/DistrictsApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';

export default {
   name: 'LocationsDetailModal',
   components: {
      IconDel,
      IconEdit,
   },
   data() {
      return {
         item: {},
         cities: {},
         allDistricts: {},
         createCity: Form.create(['name']).validate({ name: 'required' }).defaults({ name: null }),
         createDistrict: Form.create(['name']).validate({ name: 'required' }).defaults({ name: null }),
      };
   },
   methods: {
      async loadCities() {
         const response = await CitiesApi.index(this.item.id);
         this.cities = map(response.data, (item) => {
            item.district = false;
            return item;
         });
      },
      async CreateCity(item) {
         try {
            await CitiesApi.CREATE_CITY(item.id, this.createCity.toObject());
            Notify.success(this.$t('notify.Şehir Bilgisi Eklendi'));
            this.createCity.reset();
            await this.loadCities(item.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async EditCity(idCountry, itemCity) {
         try {
            await CitiesApi.EDIT_CITY(idCountry.id, itemCity.id, itemCity);
            Notify.success(this.$t('notify.Şehir Bilgisi Güncellendi'));
            await this.loadCities(idCountry.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async RemoveCity(idCountry, idCity) {
         try {
            await CitiesApi.REMOVE_CITY(idCountry.id, idCity.id);
            Notify.error(this.$t('notify.Şehir Bilgisi Silindi'));
            await this.loadCities(idCountry.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadDistricts(city) {
         try {
            const reponse = await DistrictsApi.index(this.item.id, city);
            this.allDistricts = reponse.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async createDistricts(city) {
         try {
            await DistrictsApi.CREATE_DISTRICT(this.item.id, city.id, this.createDistrict.toObject());
            this.createDistrict.reset();
            Notify.success(this.$t('notify.İlçe Bilgisi Eklendi'));
            await this.loadDistricts(city.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editDistrict(city, district) {
         try {
            await DistrictsApi.EDIT_DISTRICT(this.item.id, city.id, district.id, district);
            Notify.success(this.$t('notify.İlçe Bilgisi Güncellendi'));
            await this.loadDistricts(city.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeDistrict(city, district) {
         try {
            await DistrictsApi.REMOVE_DISTRICT(this.item.id, city.id, district.id);
            Notify.error(this.$t('notify.İlçe Bilgisi Silindi'));
            await this.loadDistricts(city.id);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      show(item) {
         this.item = item;
         this.$refs.modal.show();
         this.loadCities();
      },
      close() {
         this.$refs.modal.hide();
         this.createCity.reset();
      },
      showDistrict(city) {
         city.district = !city.district;
         this.loadDistricts(city.id);
      },
   },
};
</script>

<style scoped></style>
