<template>
   <AmcText class="mt-4">
      <AmcCard Card class="d-flex flex-row justify-content-between">
         <AmcText class="d-flex flex-row">
            <AmcText class="p-4 fs-4"> {{ get(company, 'short_name') }}</AmcText>
            <AmcText
               v-if="company.risk"
               class="alert m-0 align-self-center me-3 py-1"
               role="alert"
               :class="{
                  'alert-success': get(company, 'risk.name') === 'ÇOK DÜŞÜK (VERY LOW)',
                  'alert-primary': get(company, 'risk.name') === 'DÜŞÜK (LOW)',
                  'alert-info': get(company, 'risk.name') === 'ORTA (MIDDLE)',
                  'alert-warning': get(company, 'risk.name') === 'YÜKSEK (HIGH)',
                  'alert-danger': get(company, 'risk.name') === 'ÇOK YÜKSEK (VERY HIGH)',
               }"
               >{{ get(company, 'risk.name') }} RISK</AmcText
            >
         </AmcText>
         <AmcText class="align-self-center fw-500 fs-5 text-secondary me-4">
            {{ $t('facilities.detail.Abonelik Süresi') }}:
            <span class="fw-600 text-black">{{ Math.floor(get(company, 'remain_days') / 30) }}</span>
            {{ $t('facilities.detail.Ay') }}
         </AmcText>
      </AmcCard>
      <AmcRow>
         <AmcCol col="2 mt-5 pt-4">
            <AmcNav :items="items" />
         </AmcCol>
         <AmcCol col="10" class="mt-5 pt-5">
            <AmcText v-if="$route.path === `/detail/${$route.params.id}`">
               <AmcCard Card>
                  <AmcText v-if="!readOnly" class="d-flex justify-content-end pt-3 px-4">
                     <button @click="showModal">
                        <IconEdit />
                     </button>
                  </AmcText>
                  <AmcText class="border-bottom px-5 pb-4 d-flex flex-row justify-content-between">
                     <AmcText class="form-group mt-2" fwBold>
                        <AmcText fwBold class="text-muted fs-15">{{ $t('facilities.detail.genel.Tesis Adı') }}</AmcText>
                        <AmcText class="fw-500 mt-2"> {{ get(company, 'name') }} </AmcText>
                     </AmcText>
                     <AmcText class="form-group mt-2" fwBold>
                        <AmcText fwBold class="text-muted fs-15">{{
                           $t('facilities.detail.genel.Tesis Kısa Adı')
                        }}</AmcText>
                        <AmcText class="fw-500 mt-2"> {{ get(company, 'short_name') }} </AmcText>
                     </AmcText>
                     <AmcText class="form-group mt-2" fwBold>
                        <AmcText
                           as="a"
                           target="_blank"
                           :href="get(company, 'google_pos')"
                           fwBold
                           class="text-muted fs-15 d-flex"
                        >
                           {{ $t('facilities.detail.genel.Lokasyon') }}
                           <IconCoordinates class="ms-3 align-self-center" />
                        </AmcText>
                        <AmcText class="fs-15 fw-500 d-flex">
                           <AmcText class="fw-500 mt-2 pe-5 me-4">
                              {{ get(company, 'latitude') }} - {{ get(company, 'longitude') }}
                           </AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>
                  <AmcText class="px-5 py-4 d-flex flex-row justify-content-between">
                     <AmcText class="d-flex flex-row">
                        <AmcText class="me-5 pe-4">
                           <AmcText class="form-group mt-3" fwBold>
                              <AmcText fwBold class="text-muted fs-15">{{
                                 $t('facilities.detail.genel.Proje')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ get(company, 'project.name') }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText class="me-5 pe-4">
                           <AmcText class="form-group mt-3" fwBold>
                              <AmcText fwBold class="text-muted fs-15">{{
                                 $t('facilities.detail.genel.Risk Durumu')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ get(company, 'risk.name') }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText class="me-5 pe-4">
                           <AmcText class="form-group mt-3" fwBold>
                              <AmcText fwBold class="text-muted fs-15">{{
                                 $t('facilities.detail.genel.Kullanım')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ get(company, 'purpose.name') }} </AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText class="me-5 pe-4">
                           <AmcText class="form-group mt-3" fwBold>
                              <AmcText fwBold class="text-muted fs-15">{{
                                 $t('facilities.detail.genel.Adres')
                              }}</AmcText>
                              <AmcText class="fw-500 mt-2"> {{ get(company, 'address') }} </AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText class="d-flex justify-content-between">
                        <AmcText class="form-group mt-3 me-5 pe-4" fwBold>
                           <AmcText fwBold class="text-muted fs-15"
                              >{{ $t('facilities.detail.genel.Abonelik Başlangıç Tarihi') }}
                           </AmcText>
                           <AmcText class="fw-500 mt-2">
                              {{ moment(get(company, 'created_at')).format('DD-MM-YYYY') }}
                           </AmcText>
                        </AmcText>
                        <AmcText class="form-group mt-3 me-5 pe-4" fwBold>
                           <AmcText fwBold class="text-muted fs-15">{{
                              $t('facilities.detail.genel.Abonelik Bitiş Tarihi')
                           }}</AmcText>
                           <AmcText class="fw-500 mt-2">
                              {{ moment(get(company, 'subscription_end_at')).format('DD-MM-YYYY') }}
                           </AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcCard>
               <AmcCard Card class="mt-4">
                  <AmcText class="px-5 py-4">
                     <AmcText class="fw-600">{{ $t('facilities.detail.genel.Yetkililer') }}</AmcText>
                     <AmcText class="d-flex gap-5">
                        <AmcText class="form-group mt-3 me-4" fwBold>
                           <AmcText fwBold class="text-muted fs-15">{{
                              $t('facilities.detail.genel.Proje Yöneticisi Adı')
                           }}</AmcText>
                           <AmcText class="fw-bold fs-15 mt-2">{{ get(company, 'project.manager_name') }}</AmcText>
                        </AmcText>
                        <AmcText class="form-group mt-3 me-4" fwBold>
                           <AmcText fwBold class="text-muted fs-15">
                              {{ $t('facilities.detail.genel.Proje Yöneticisi Telefon Numarası') }}
                           </AmcText>
                           <AmcText class="fw-bold fs-15 mt-2">
                              {{ get(company, 'project.manager_phone') }}
                           </AmcText>
                        </AmcText>
                        <AmcText class="form-group mt-3 me-4" fwBold>
                           <AmcText fwBold class="text-muted fs-15">
                              {{ $t('facilities.detail.genel.Proje Yöneticisi E-Postası') }}
                           </AmcText>
                           <AmcText class="fw-bold fs-15 mt-2">{{ get(company, 'project.manager_email') }}</AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcCard>
               <AmcCard Card class="mt-4 p-3">
                  <AmcText class="d-flex">
                     <AmcText class="files">
                        <AmcText class="text-center border-bottom py-3">
                           <AmcText class="bg-light-primary rounded-pill mx-5">
                              <AmcText class="rounded-pill px-4 py-2 fw-600">{{
                                 $t('facilities.detail.genel.Site Planı')
                              }}</AmcText>
                           </AmcText>
                        </AmcText>
                        <AmcText class="mt-3 d-flex flex-wrap justify-content-center">
                           <AmcText v-if="company.site_plan" class="file-thumbnail mb-3">
                              <AmcImage class="pointer m-2" thumbnail :src="company.site_plan" />
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText class="upload-file-card">
                        <file-pond
                           v-if="!readOnly"
                           name="fileUpload"
                           ref="pond"
                           :label-idle="$t('facilities.detail.genel.Dosyaları buraya sürükleyin')"
                           accepted-file-types="image/jpeg, image/png, image/gif"
                           v-bind:allow-multiple="true"
                           v-on:init="handleFilePondInit"
                        />
                        <AmcText v-if="files.length > 0" class="text-center">
                           <button
                              :disabled="files.length < 1"
                              @click="saveFiles()"
                              class="btn rounded-pill px-4 py-2 fw-600 m-1 bg-light-primary"
                           >
                              <span v-if="uploading" class="spinner-border" />
                              <AmcText v-if="!uploading"> {{ $t('buttons.Kaydet') }} </AmcText>
                           </button>
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcCard>
               <!-- Edit Modal -->
               <AmcModal ref="editModal" lg>
                  <template #header>
                     <AmcText class="h3 py-3 mb-0"> {{ $t('facilities.modal.Tesis Bilgileri') }} </AmcText>
                     <AmcText class="d-flex">
                        <AmcText class="d-flex">
                           <AmcText
                              :class="{ 'bg-primary': step === 1 || 2 || 3 }"
                              class="step-number align-self-center"
                           >
                              <span :class="{ 'text-white': step !== 1 || 2 || 3 }" class="d-flex align-self-center"
                                 >1</span
                              >
                           </AmcText>
                        </AmcText>
                        <AmcText :class="{ 'bg-primary': step !== 1 }" class="align-self-center step-line"></AmcText>
                        <AmcText :class="{ 'bg-primary': step !== 1 }" class="step-number align-self-center">
                           <span :class="{ 'text-white': step !== 1 }" class="d-flex align-self-center">2</span>
                        </AmcText>
                        <AmcText :class="{ 'bg-primary': step === 3 }" class="align-self-center step-line"></AmcText>
                        <AmcText :class="{ 'bg-primary': step === 3 }" class="step-number align-self-center">
                           <span :class="{ 'text-white': step === 3 }" class="d-flex align-self-center">3</span>
                        </AmcText>
                     </AmcText>
                  </template>
                  <template>
                     <AmcText v-if="step === 1">
                        <AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Tesis Adı') }}</label>
                              <input
                                 type="text"
                                 class="form-control shadow-none py-2 border-2"
                                 v-model="company.name"
                              />
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Kısa Adı') }}</label>
                              <input
                                 type="text"
                                 class="form-control shadow-none py-2 border-2"
                                 v-model="company.short_name"
                              />
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Proje') }}</label>
                              <select v-model="company.project_id" class="form-select shadow-none py-2 border-2">
                                 <option :value="project.id" v-for="(project, index) in allProjects" :key="index">
                                    {{ get(project, 'name') }}
                                 </option>
                              </select>
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Risk Durumu') }}</label>
                              <select v-model="company.risk_id" class="form-select shadow-none py-2 border-2">
                                 <option :value="risk.id" v-for="(risk, index) in allRiskStatus" :key="index">
                                    {{ get(risk, 'name') }}
                                 </option>
                              </select>
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Kullanım Amacı') }}</label>
                              <select
                                 v-model="company.purpose_id"
                                 class="form-select shadow-none py-2 border-2"
                                 aria-label="Default select example"
                              >
                                 <option :value="use.id" v-for="(use, index) in allUsages" :key="index">
                                    {{ get(use, 'name') }}
                                 </option>
                              </select>
                           </AmcText>
                           <AmcText class="mb-3" fwBold>
                              <AmcText class="fs-16 mb-2" fwBold>{{
                                 $t('facilities.modal.Abonelik Bitiş Tarihi')
                              }}</AmcText>
                              <VueCtkDateTimePicker
                                 v-model="company.subscription_end_at"
                                 locale="tr"
                                 no-shortcuts
                                 format="YYYY-MM-DD"
                                 only-date
                                 :min-date="today"
                              >
                              </VueCtkDateTimePicker>
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{
                                 $t('facilities.modal.Plan Gösterim Süresi (Saniye)')
                              }}</label>
                              <input
                                 type="number"
                                 class="form-control shadow-none py-2 border-2"
                                 v-model="company.site_plan_show_duration"
                              />
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText v-if="step === 2">
                        <AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Ülke') }}</label>
                              <select
                                 v-model="company.country_id"
                                 class="form-select shadow-none border-2 py-2"
                                 aria-label="Default select example"
                                 @change="loadCities"
                              >
                                 <option :value="country.id" v-for="country in allCountry" :key="country.id">
                                    {{ country.name }}
                                 </option>
                              </select>
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Şehir') }}</label>
                              <select
                                 v-model="company.city_id"
                                 class="form-select shadow-none py-2 border-2"
                                 aria-label="Default select example"
                                 @change="loadDistricts"
                              >
                                 <option :value="city.id" v-for="(city, index) in allCities" :key="index">
                                    {{ get(city, 'name') }}
                                 </option>
                              </select>
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.İlçe') }}</label>
                              <select
                                 v-model="company.district_id"
                                 class="form-select shadow-none py-2 border-2"
                                 aria-label="Default select example"
                              >
                                 <option :value="districts.id" v-for="(districts, index) in allDistricts" :key="index">
                                    {{ get(districts, 'name') }}
                                 </option>
                              </select>
                           </AmcText>
                           <AmcText class="mb-3">
                              <label class="form-label fs-16 fw-500">{{ $t('facilities.modal.Adres') }}</label>
                              <textarea
                                 v-model="company.address"
                                 rows="3"
                                 wrap="soft"
                                 class="form-control shadow-none border-2"
                              ></textarea>
                           </AmcText>
                           <AmcText class="row mt-3" fwBold>
                              <AmcText class="col-md-6">
                                 <AmcText class="fs-16 mb-2">{{ $t('placeholders.Enlem') }}</AmcText>
                                 <input
                                    type="number"
                                    class="form-control shadow-none border-2 py-2 me-3"
                                    v-model="company.latitude"
                                    :placeholder="$t('placeholders.Enlem')"
                                 />
                              </AmcText>
                              <AmcText class="col-md-6">
                                 <AmcText class="fs-16 mb-2">{{ $t('placeholders.Boylam') }}</AmcText>
                                 <input
                                    type="number"
                                    class="form-control shadow-none border-2 py-2"
                                    v-model="company.longitude"
                                    :placeholder="$t('placeholders.Boylam')"
                                 />
                              </AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcText>
                     <AmcText v-if="step === 3">
                        <AmcText class="text-center py-4">
                           <IconSuccess />
                           <AmcText class="text-primary h3 mt-5">{{ $t('facilities.modal.İşlem Başarılı') }}!</AmcText>
                           <AmcText class="text-secondary"
                              >{{ $t('facilities.modal.Tesis Bilgileri Gönderildi') }}.</AmcText
                           >
                        </AmcText>
                     </AmcText>
                  </template>
                  <template #actions>
                     <AmcText class="d-flex flex-row justify-content-between">
                        <button
                           v-if="step !== 3"
                           class="btn btn-outline-secondary"
                           data-bs-dismiss="modal"
                           @click="close"
                        >
                           {{ $t('modalActions.Vazgeç') }}
                        </button>
                        <button v-if="step === 2" class="btn btn-secondary ms-3 px-3" @click="previousStep">
                           {{ $t('modalActions.Geri') }}
                        </button>
                     </AmcText>
                     <AmcText>
                        <button v-if="step === 1" class="btn btn-primary" @click="nextStep">
                           {{ $t('modalActions.Devam') }}
                        </button>
                        <button v-if="step === 2" class="btn btn-primary" @click="editBuilding">
                           {{ $t('modalActions.Gönder') }}
                        </button>
                        <button v-if="step === 3" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">
                           {{ $t('modalActions.Kapat') }}
                        </button>
                     </AmcText>
                  </template>
               </AmcModal>
               <!-- Edit Modal -->
            </AmcText>
            <router-view></router-view>
         </AmcCol>
      </AmcRow>
   </AmcText>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import moment from 'moment';

import Notify from '@/libs/notify';
import UsagesApi from '@/api/UsagesApi';
import CitiesApi from '@/api/CitiesApi';
import BuildingApi from '@/api/BuildingApi';
import ProjectsApi from '@/api/ProjectsApi';
import DistrictsApi from '@/api/DistrictsApi';
import CountriesApi from '@/api/CountriesApi';
import ErrorHandler from '@/libs/error-handler';
import RiskClassesApi from '@/api/RiskClassesApi';
import IconEdit from '@/components/icons/IconEdit';
import IconSuccess from '@/components/icons/IconSuccess';
import IconCoordinates from '@/components/icons/IconCoordinates';
import IconUse from '@/components/icons/facilities-detail-icons/IconUse';
import IconHome from '@/components/icons/facilities-detail-icons/IconHome';
import IconPhotos from '@/components/icons/facilities-detail-icons/IconPhotos';
import IconModemler from '@/components/icons/facilities-detail-icons/IconModemler';
import IconAuthorities from '@/components/icons/facilities-detail-icons/IconAuthorities';
import IconNotificationSettings from '@/components/icons/facilities-detail-icons/IconNotificationSettings';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

const FilePond = vueFilePond(FilePondPluginFileValidateType);
import 'filepond/dist/filepond.min.css';

export default {
   components: {
      IconCoordinates,
      IconEdit,
      FilePond,
      IconSuccess,
   },
   data() {
      return {
         uploading: false,
         files: [],
         modalImgSrc: null,
         today: moment().get().format('YYYY-MM-DD'),
         items: [
            {
               title: this.$t('facilities.detail.nav.Genel'),
               subtitle: this.$t('facilities.detail.nav.Hızlı Bakış'),
               tag: IconHome,
               to: `/detail/${this.$route.params.id}`,
            },
            {
               title: this.$t('facilities.detail.nav.Yetkililer'),
               subtitle: this.$t('facilities.detail.nav.Hesap, Şifre, Güvenlik'),
               tag: IconAuthorities,
               to: `/detail/${this.$route.params.id}/authorities`,
            },
            {
               title: this.$t('facilities.detail.nav.Modemler'),
               subtitle: this.$t('facilities.detail.nav.Kurulu Modem ve Dağıtıcı'),
               tag: IconModemler,
               to: `/detail/${this.$route.params.id}/modems`,
            },
            {
               title: this.$t('facilities.detail.nav.Kullanım Bilgileri'),
               subtitle: this.$t('facilities.detail.nav.Raporlar ve Denetim'),
               tag: IconUse,
               to: `/detail/${this.$route.params.id}/usage-information`,
            },
            {
               title: this.$t('facilities.detail.nav.Dosyalar'),
               subtitle: this.$t('facilities.detail.nav.Kurum Dosyaları'),
               tag: IconPhotos,
               to: `/detail/${this.$route.params.id}/files`,
            },
            {
               title: this.$t('facilities.detail.nav.Bildirim Ayarları'),
               subtitle: this.$t('facilities.detail.nav.Anlık Haber Alın'),
               tag: IconNotificationSettings,
               to: `/detail/${this.$route.params.id}/notification-settings`,
            },
         ],
         company: {
            project: {},
         },
         step: 1,
         totalStep: 3,
         allProjects: [],
         allRiskStatus: [],
         allUsages: [],
         allCountry: [],
         allCities: [],
         allDistricts: [],
         readOnly: false,
      };
   },

   methods: {
      get,
      async getBuilding() {
         try {
            const result = await BuildingApi.GET_BUILDING(this.$route.params.id);
            this.company = result.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editBuilding() {
         this.company.city = find(this.allCities, { id: this.company.city_id });
         this.company.risk = find(this.allRiskStatus, { id: this.company.risk_id });
         this.company.risk = find(this.allRiskStatus, { id: this.company.risk_id });
         this.company.purpose = find(this.allUsages, { id: this.company.purpose_id });
         this.company.country = find(this.allCountry, { id: this.company.country_id });
         this.company.project = find(this.allProjects, { id: this.company.project_id });
         this.company.district = find(this.allDistricts, { id: this.company.district_id });

         try {
            await BuildingApi.EDIT_BUILDING(this.company.id, this.company);
            Notify.success(this.$t('notify.Tesis Bilgileri Güncellendi'));
            this.nextStep();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadProjects() {
         await ProjectsApi.index().then(({ data }) => (this.allProjects = data));
      },
      async loadRiskClasses() {
         await RiskClassesApi.index().then(({ data }) => (this.allRiskStatus = data));
      },
      async loadPurpose() {
         await UsagesApi.index().then(({ data }) => (this.allUsages = data));
      },
      async loadCountries() {
         await CountriesApi.index().then(({ data }) => (this.allCountry = data));
      },
      async loadCities() {
         await CitiesApi.index(this.company.country_id).then(({ data }) => (this.allCities = data));
      },
      async loadDistricts() {
         await DistrictsApi.index(this.company.country_id, this.company.city_id).then(
            ({ data }) => (this.allDistricts = data)
         );
      },
      showModal(item) {
         this.$refs.editModal.show(item);
         this.loadProjects();
         this.loadRiskClasses();
         this.loadPurpose();
         this.loadCountries();
         this.loadCities();
         this.loadDistricts();
      },
      nextStep() {
         if (this.step !== this.totalStep) {
            this.step++;
         }
      },
      previousStep() {
         if (this.step !== 1) {
            this.step--;
         }
      },
      close() {
         this.step = 1;
         this.getBuilding();
      },
      handleFilePondInit() {
         const pond = this.$refs.pond;
         let files = pond.getFiles();
         this.files = files;

         document.addEventListener('FilePond:addfile', () => {
            files = pond.getFiles();
            this.files = files;
         });

         document.addEventListener('FilePond:removefile', () => {
            files = pond.getFiles();
            this.files = files;
         });
      },
      async saveFiles() {
         const pond = this.$refs.pond;
         const files = this.files;
         this.uploading = true;
         try {
            await Promise.all(
               files.map(({ file }) => {
                  return BuildingApi.FILE_UPLOAD(this.$route.params.id, { file }, 'site_plan');
               })
            );
            pond.removeFiles();
            await this.getBuilding();
         } catch (err) {
            ErrorHandler.handle(err);
         }
         this.uploading = false;
      },
      async removeFile(fileID) {
         try {
            await BuildingApi.REMOVE_FILE(this.$route.params.id, fileID);
            await this.getBuilding();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showImgModal(imgSrc) {
         this.modalImgSrc = imgSrc;
         this.$refs.imgModal.show();
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   async beforeMount() {
      this.readOnly = this.$store.state.auth.authUser.read_only;
      await this.getBuilding();
   },
};
</script>

<style scoped lang="scss">
.files {
   margin-top: 30px;
   width: 65%;
   box-shadow: 0 0 8px rgba(165, 165, 165, 0.15);
   border-radius: 10px;
   background-color: #ffffff;
}
.upload-file-card {
   width: 35%;
   margin-top: 30px;
   margin-left: 20px;
   & .upload-area {
      background-color: rgba(229, 239, 255, 0.38);
      border-radius: 20px;
      height: 324px;
      & .upload-are-line {
         border: 3px dashed #6c757d;
         height: 100%;
         border-radius: 20px;
      }
   }
}
.file-thumbnail {
   display: flex;
   flex-direction: column;
   img {
      width: 150px;
      height: 150px;
      object-fit: contain;
      object-position: center;
   }
}
.step-number {
   font-size: 20px;
   font-weight: 600;
   height: 50px;
   width: 50px;
   background-color: #ced4da;
   display: flex;
   justify-content: center;
   border-radius: 100%;
}
.step-line {
   background-color: #ced4da;
   margin: 0 10px;
   width: 50px;
   height: 3px;
   border-radius: 50px;
}
.state-bar {
   margin-top: -9px;
   margin-right: 20px;
}
</style>
