<template>
   <svg stroke="#3B475D" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M11 1L21 7.5V14.5L11 21L1 14.5V7.5L11 1Z"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path d="M11 21V14.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 7.5L11 14.5L1 7.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 14.5L11 7.5L21 14.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 1V7.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconDevice',
};
</script>

<style scoped></style>
