import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';

import './plugins/mixin';
import filterTableData from './plugins/filterTableData';
import './plugins/GlobalComponents';

import './plugins/VueMultiSelect';
import './plugins/vue-notification';
import './plugins/VueCtkDateTimePicker';

import './assets/scss/main.scss';
import VueGlide from 'vue-glide-js';
import vuetify from './plugins/vuetify';
import 'vue-glide-js/dist/vue-glide.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import VueInputAutowidth from 'vue-input-autowidth';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);
Vue.prototype.moment = moment;
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import i18n from './i18n';
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.config.productionTip = false;
Vue.use(VueInputAutowidth);
Vue.use(VueGlide);

Vue.filter('filterTableData', filterTableData);

Vue.use(mdiVue, {
   icons: mdijs,
});

new Vue({
   router,
   store,
   vuetify,
   i18n,
   render: (h) => h(App),
}).$mount('#app');
