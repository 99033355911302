<template>
   <AmcText class="d-flex justify-content-center" @keyup.enter="resetPassword">
      <AmcText class="content">
         <AmcText class="text-center">
            <IconAmcBig width="300" />
         </AmcText>
         <AmcText class="content-card border rounded-8 p-5 mt-4">
            <AmcText class="fw-500">Yeni Şifre</AmcText>
            <input
               :class="{ 'border-danger': newPassword.errors.has('password') }"
               v-model="newPassword.password"
               type="password"
               class="form-control shadow-none mt-2"
            />
            <span
               class="text-danger fs-12"
               v-for="(error, i) in newPassword.errors.get('password')"
               :key="`p${i}`"
               v-html="error"
            ></span>
            <AmcText class="fw-500 mt-4">Yeni Şifre Tekrar</AmcText>
            <input
               :class="{ 'border-danger': newPassword.errors.has('password_confirmation') }"
               v-model="newPassword.password_confirmation"
               type="password"
               class="form-control shadow-none mt-2"
            />
            <span
               class="text-danger fs-12"
               v-for="(error, i) in newPassword.errors.get('password_confirmation')"
               :key="`pc${i}`"
               v-html="error"
            ></span>
            <AmcText class="d-flex justify-content-between">
               <router-link to="/login">
                  <button class="btn btn-primary mt-4">
                     <span>Giriş Yap</span>
                  </button>
               </router-link>

               <button @click="resetPassword" class="btn btn-primary mt-4">Gönder</button>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcLoading v-if="loading" />
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import ResetPasswordApi from '@/api/ResetPasswordApi';
import IconAmcBig from '@/components/icons/IconAmcBig';

export default {
   name: 'ResetPassword',
   data() {
      return {
         loading: false,
         newPassword: Form.create(['password', 'password_confirmation', 'user_id', 'token'])
            .validate({
               password: 'required',
               password_confirmation: 'required',
               user_id: 'required',
               token: 'required',
            })
            .defaults({
               password: '',
               password_confirmation: '',
            }),
      };
   },
   components: {
      IconAmcBig,
   },
   mounted() {
      this.newPassword.user_id = this.$route.query.user_id;
      this.newPassword.token = this.$route.query.token;
   },
   methods: {
      async resetPassword() {
         if (!this.checkValidation()) return;
         this.loading = true;
         try {
            this.newPassword.user_id = this.$route.query.user_id;
            this.newPassword.token = this.$route.query.token;
            await ResetPasswordApi.RESET_PASSWORD(this.newPassword.toObject());
            this.newPassword.reset();
            Notify.success(this.$t('notify.Şifreniz güncellendi. Lütfen tekrar giriş yapın!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loading = false;
         }
      },
      checkValidation() {
         return this.newPassword.isValid({
            disableDirtyCheck: true,
         });
      },
   },
};
</script>

<style scoped>
.content {
   margin-top: 10%;
}
.content-card {
   width: 500px;
}
</style>
