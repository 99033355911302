<template>
   <AmcText class="login" v-on:keyup.enter="login">
      <AmcText class="text-center">
         <AmcText class="mt-5 pb-5">
            <IconAmc />
         </AmcText>
         <AmcText class="d-inline-block"><AmcSwitchLang /></AmcText>
         <AmcText class="fs-3 text-black mt-5">{{ $t('login.Giriş Yap') }}</AmcText>
         <AmcText class="text-dark mt-2">{{ $t(`login.loginText`) }}</AmcText>
      </AmcText>
      <AmcText class="mb-3 amc-input mx-5 px-5 mt-5">
         <AmcText v-if="loginError" class="alert alert-danger" role="alert">
            {{ $t('login.Lütfen e-posta adresinizi ve şifrenizi kontrol ediniz') }}.
         </AmcText>
         <label class="form-label text-black">{{ $t('login.E-Posta') }}</label>
         <input
            v-model="formDataLogin.username"
            :class="{ 'border-danger': formDataLogin.errors.has('username') }"
            class="form-control"
            type="email"
         />
         <span
            class="text-danger fs-12"
            :key="i"
            v-for="(error, i) in formDataLogin.errors.get('username')"
            v-html="error"
         >
         </span>
      </AmcText>
      <AmcText class="mb-3 amc-input mx-5 px-5">
         <label class="form-label text-black">{{ $t('login.Parola') }}</label>
         <AmcText @click="show = true" class="text-primary float-end text-decoration-none" role="button">{{
            $t('login.Şifremi Unuttum')
         }}</AmcText>
         <input
            v-model="formDataLogin.password"
            @keyup="formDataLogin.isValid()"
            :class="{ 'border-danger': formDataLogin.errors.has('password') }"
            class="form-control"
            type="password"
         />
         <span
            class="text-danger fs-12"
            v-for="(error, i) in formDataLogin.errors.get('password')"
            :key="i"
            v-html="error"
         ></span>
         <AmcText class="form-check mt-3">
            <input id="gridCheck" class="form-check-input" type="checkbox" />
            <label class="form-check-label text-black" for="gridCheck">{{ $t('login.Oturum Açık Kalsın') }} </label>
         </AmcText>
         <button @click="login" class="btn w-100 btn-primary py-3 mt-3">
            <span>{{ $t('login.Giriş Yap') }}</span>
         </button>
      </AmcText>
      <AmcText class="footer-login text-center">
         <AmcText class="fw-lighter">© 2021 Finder. All Rights Reserved.</AmcText>
      </AmcText>
      <AmcLoading v-if="loginPanel" />
      <AmcText v-if="show" class="reset-password d-flex justify-content-center">
         <AmcText class="reset-password-area align-self-center">
            <input
               v-model="resetPassword.email"
               type="text"
               class="btn btn-reset-password bg-light-primary rounded-pill"
               placeholder="Email"
            />
            <AmcText class="d-flex justify-content-between mt-4">
               <button @click="reset" class="btn btn-outline-secondary rounded-pill">
                  {{ $t('modalActions.Vazgeç') }}
               </button>
               <button @click="sendEmail" class="btn btn-outline-primary rounded-pill">
                  <span v-if="!loading">{{ $t('modalActions.Gönder') }}</span>
                  <AmcText v-if="loading" class="spinner-border" role="status" />
               </button>
            </AmcText>
         </AmcText>
      </AmcText>
   </AmcText>
</template>
<script>
import get from 'lodash/get';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import IconAmc from '@/components/icons/IconAmc';
import ResetPasswordApi from '@/api/ResetPasswordApi';

export default {
   name: 'LoginForm',
   components: {
      IconAmc,
   },
   data() {
      return {
         show: false,
         loading: false,
         formDataLogin: Form.create(['username', 'password']).validate({
            username: 'required|email',
            password: 'required',
         }),
         resetPassword: Form.create(['email'])
            .validate({
               email: 'required|email',
            })
            .defaults({
               email: '',
            }),
         loginError: false,
         loginPanel: false,
      };
   },
   methods: {
      get,
      async login() {
         if (!this.checkValidation()) return;
         this.loginPanel = true;
         try {
            await this.$store.dispatch('auth/login', this.formDataLogin.toObject());
            await this.$router.push({ name: 'Dashboard' });
         } catch (err) {
            console.log('error login');
            console.log(err);
            this.loginError = true;
            this.loginPanel = false;
         } finally {
            (this.loginPanel = false), (this.$store.state.loading = false);
         }
      },
      checkValidation() {
         return this.formDataLogin.isValid({
            disableDirtyCheck: true,
         });
      },
      async sendEmail() {
         this.loading = true;
         try {
            await ResetPasswordApi.index(this.resetPassword.toObject());
            this.reset();
            Notify.success(
               this.$t('notify.Şifre yenileme istediğiniz mail adresinize gönderildi. Lütfen kontrol ediniz.')
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loading = false;
         }
      },
      reset() {
         this.show = false;
         this.resetPassword.reset();
      },
   },
};
</script>

<style lang="scss" scoped>
.login {
   & .form-control {
      height: 3.5rem;
   }

   & .footer-login {
      position: absolute;
      bottom: 0;
      left: 14%;
   }
}
.reset-password {
   width: 100%;
   height: 100vh;
   z-index: 99999;
   background-color: rgba(135, 135, 135, 0.5);
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   .reset-password-area {
      background-color: white;
      padding: 50px;
      border-radius: 20px;
      .btn-reset-password {
         width: 300px;
         height: 50px;
      }
   }
}
</style>
