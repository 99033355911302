<template>
   <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M238.42 77.4486C243.815 89.4075 251.803 112.304 248.592 140.782C244.473 177.319 224.082 206.125 204.25 222.31C138.438 276.021 17.9573 241.322 2.00311 190.726C-0.89274 181.546 -0.0786589 172.693 0.924673 161.801C4.1059 127.153 20.8832 116.855 32.1662 80.0651C42.4338 46.5856 32.3104 42.8186 41.9111 27.9338C62.7408 -4.35003 122.863 -5.97819 164.231 9.50137C180.119 15.4463 218.678 33.6835 238.42 77.4486Z"
         fill="#CADDFF"
      />
      <path
         d="M195.732 210.66H226.25C228.779 210.66 230.828 208.611 230.828 206.082V41.0698C230.828 38.252 228.545 35.969 225.727 35.969H148.398L195.732 210.66Z"
         fill="white"
      />
      <path
         d="M230.828 167.847V41.0698C230.828 38.252 228.545 35.969 225.727 35.969H148.398L230.828 167.847Z"
         fill="white"
      />
      <path
         d="M230.827 108.71C230.578 108.71 230.377 108.509 230.377 108.26V41.0697C230.377 38.5043 228.289 36.4195 225.727 36.4195H148.398C148.149 36.4195 147.947 36.2182 147.947 35.9689C147.947 35.7196 148.149 35.5183 148.398 35.5183H225.727C228.788 35.5183 231.278 38.0086 231.278 41.0697V108.26C231.278 108.506 231.077 108.71 230.827 108.71Z"
         fill="black"
      />
      <path d="M195.731 210.66H226.249C227.733 210.66 229.049 209.954 229.887 208.863L195.731 210.66Z" fill="white" />
      <path
         d="M226.249 211.11H195.732C195.483 211.11 195.281 210.909 195.281 210.66C195.281 210.41 195.483 210.209 195.732 210.209H226.249C227.544 210.209 228.74 209.617 229.53 208.587C229.68 208.388 229.965 208.352 230.161 208.503C230.359 208.653 230.395 208.938 230.245 209.134C229.286 210.392 227.829 211.11 226.249 211.11Z"
         fill="black"
      />
      <path
         d="M194.743 218.146C194.415 219.798 192.967 220.988 191.285 220.988H12.9746C10.7546 220.988 9.08741 218.957 9.51698 216.779L44.97 38.7597C45.2975 37.1075 46.7454 35.918 48.4276 35.918H226.741C228.961 35.918 230.628 37.9487 230.199 40.1266L194.743 218.146Z"
         fill="white"
      />
      <path
         d="M36.7006 221.435H12.9751C11.7825 221.435 10.662 220.907 9.90499 219.984C9.14798 219.062 8.84458 217.86 9.07589 216.689L44.5289 38.6727C44.8954 36.8162 46.5356 35.4705 48.4281 35.4705H226.742C227.934 35.4705 229.055 35.9992 229.812 36.9214C230.569 37.8436 230.872 39.0452 230.641 40.2168L209.214 147.802C209.166 148.045 208.928 148.204 208.685 148.156C208.442 148.108 208.282 147.871 208.33 147.627L229.758 40.0425C229.938 39.1383 229.704 38.2101 229.118 37.4951C228.532 36.7832 227.667 36.3717 226.742 36.3717H48.4281C46.9652 36.3717 45.6975 37.414 45.4121 38.8499L9.95906 216.866C9.77882 217.77 10.0131 218.699 10.5989 219.414C11.1847 220.125 12.0498 220.537 12.9751 220.537H36.7036C36.9529 220.537 37.1542 220.738 37.1542 220.988C37.1512 221.234 36.9499 221.435 36.7006 221.435Z"
         fill="black"
      />
      <path
         d="M112.54 221.435H87.3031C87.0538 221.435 86.8525 221.234 86.8525 220.984C86.8525 220.735 87.0538 220.534 87.3031 220.534H112.54C112.789 220.534 112.99 220.735 112.99 220.984C112.99 221.234 112.789 221.435 112.54 221.435Z"
         fill="black"
      />
      <path d="M37.333 149.451L41.064 129.847H61.7374L58.0034 149.451H37.333Z" fill="#CADDFF" />
      <path d="M60.5908 149.451L64.3218 129.847H84.9952L81.2613 149.451H60.5908Z" fill="#CADDFF" />
      <path d="M83.8447 149.451L87.5787 129.847H108.249L104.518 149.451H83.8447Z" fill="#CADDFF" />
      <path d="M107.102 149.451L110.836 129.847H131.506L127.775 149.451H107.102Z" fill="#CADDFF" />
      <path d="M130.358 149.451L134.089 129.847H154.763L151.029 149.451H130.358Z" fill="#CADDFF" />
      <path d="M153.612 149.451L157.346 129.847H178.02L174.286 149.451H153.612Z" fill="#71AFFA" />
      <path d="M176.869 149.451L180.603 129.847H201.273L197.542 149.451H176.869Z" fill="#71AFFA" />
      <path d="M33.1973 171.362L36.9312 151.758H57.6047L53.8707 171.362H33.1973Z" fill="#CADDFF" />
      <path d="M56.4541 171.362L60.1881 151.758H80.8585L77.1276 171.362H56.4541Z" fill="#CADDFF" />
      <path d="M79.7109 171.362L83.4449 151.758H104.115L100.381 171.362H79.7109Z" fill="#CADDFF" />
      <path d="M102.968 171.362L106.699 151.758H127.372L123.638 171.362H102.968Z" fill="#CADDFF" />
      <path d="M126.222 171.362L129.956 151.758H150.629L146.895 171.362H126.222Z" fill="#CADDFF" />
      <path d="M149.479 171.362L153.212 151.758H173.883L170.152 171.362H149.479Z" fill="#71AFFA" />
      <path d="M172.735 171.362L176.466 151.758H197.14L193.406 171.362H172.735Z" fill="#71AFFA" />
      <path d="M45.6016 105.626L49.3355 86.022H70.009L66.275 105.626H45.6016Z" fill="#CADDFF" />
      <path d="M68.8701 105.605L72.6011 86.0007H93.2745L89.5406 105.605H68.8701Z" fill="#CADDFF" />
      <path d="M92.1152 105.626L95.8462 86.022H116.52L112.786 105.626H92.1152Z" fill="#CADDFF" />
      <path d="M115.371 105.626L119.102 86.022H139.775L136.042 105.626H115.371Z" fill="#CADDFF" />
      <path d="M138.625 105.626L142.359 86.022H163.029L159.298 105.626H138.625Z" fill="#CADDFF" />
      <path d="M161.882 105.626L165.616 86.022H186.286L182.555 105.626H161.882Z" fill="#71AFFA" />
      <path d="M185.14 105.626L188.871 86.022H209.544L205.81 105.626H185.14Z" fill="#71AFFA" />
      <path d="M41.4678 127.54L45.2017 107.936H65.8721L62.1412 127.54H41.4678Z" fill="#CADDFF" />
      <path d="M64.7246 127.54L68.4556 107.936H89.129L85.395 127.54H64.7246Z" fill="#CADDFF" />
      <path d="M87.9814 127.54L91.7124 107.936H112.386L108.652 127.54H87.9814Z" fill="#CADDFF" />
      <path d="M111.234 127.54L114.968 107.936H135.639L131.908 127.54H111.234Z" fill="#CADDFF" />
      <path d="M134.491 127.54L138.225 107.936H158.896L155.165 127.54H134.491Z" fill="#CADDFF" />
      <path d="M180.729 127.54L184.459 107.936H205.133L201.399 127.54H180.729Z" fill="#71AFFA" />
      <path d="M157.748 127.54L161.479 107.936H182.152L178.418 127.54H157.748Z" fill="#71AFFA" />
      <path
         d="M82.6017 36.0231C82.3524 36.0231 82.1511 35.8218 82.1511 35.5725C82.1511 32.2741 81.0817 29.1559 79.057 26.5605C78.9038 26.3652 78.9399 26.0799 79.1351 25.9267C79.3304 25.7735 79.6158 25.8095 79.769 26.0048C81.9168 28.7594 83.0523 32.0698 83.0523 35.5725C83.0523 35.8218 82.848 36.0231 82.6017 36.0231Z"
         fill="black"
      />
      <path
         d="M67.4789 51.1453C58.8935 51.1453 51.9062 44.1611 51.9062 35.5727C51.9062 26.9873 58.8905 20 67.4789 20C70.7323 20 73.8474 20.9943 76.4909 22.8718C76.6952 23.016 76.7402 23.2984 76.596 23.4996C76.4518 23.7009 76.1695 23.749 75.9682 23.6048C73.4779 21.8354 70.543 20.9012 67.4789 20.9012C59.3892 20.9012 52.8074 27.4829 52.8074 35.5727C52.8074 43.6624 59.3892 50.2441 67.4789 50.2441C67.7283 50.2441 67.9295 50.4454 67.9295 50.6947C67.9295 50.9441 67.7283 51.1453 67.4789 51.1453Z"
         fill="black"
      />
      <path
         d="M102.857 36.0233C102.608 36.0233 102.406 35.8221 102.406 35.5727C102.406 32.1932 101.289 29.018 99.177 26.3865C99.0208 26.1913 99.0509 25.9089 99.2461 25.7527C99.4414 25.5965 99.7237 25.6265 99.8799 25.8218C102.091 28.5734 103.31 32.034 103.31 35.5727C103.307 35.8221 103.106 36.0233 102.857 36.0233Z"
         fill="black"
      />
      <path
         d="M87.7377 51.1453C79.1523 51.1453 72.165 44.1611 72.165 35.5727C72.165 26.9873 79.1493 20 87.7377 20C90.8108 20 93.7817 20.8952 96.3321 22.5834C96.5394 22.7216 96.5965 23.001 96.4583 23.2083C96.3201 23.4155 96.0407 23.4726 95.8335 23.3344C93.4303 21.7423 90.6306 20.9012 87.7377 20.9012C79.648 20.9012 73.0662 27.4829 73.0662 35.5727C73.0662 43.6624 79.648 50.2441 87.7377 50.2441C87.987 50.2441 88.1883 50.4454 88.1883 50.6947C88.1883 50.9441 87.984 51.1453 87.7377 51.1453Z"
         fill="black"
      />
      <path
         d="M123.116 36.0234C122.867 36.0234 122.665 35.8221 122.665 35.5728C122.665 32.1843 121.482 28.8799 119.337 26.2664C119.178 26.0742 119.208 25.7888 119.4 25.6326C119.592 25.4734 119.878 25.5034 120.034 25.6957C122.314 28.4684 123.57 31.977 123.57 35.5758C123.567 35.8221 123.365 36.0234 123.116 36.0234Z"
         fill="black"
      />
      <path
         d="M107.994 51.1453C99.4082 51.1453 92.4209 44.1611 92.4209 35.5727C92.4209 26.9873 99.4052 20 107.994 20C111.196 20 114.272 20.9643 116.888 22.7877C117.093 22.9289 117.144 23.2113 117 23.4155C116.858 23.6198 116.576 23.6709 116.372 23.5267C113.905 21.8084 111.01 20.8982 107.991 20.8982C99.9008 20.8982 93.3191 27.4799 93.3191 35.5697C93.3191 43.6594 99.9008 50.2411 107.991 50.2411C108.24 50.2411 108.441 50.4424 108.441 50.6917C108.441 50.9411 108.243 51.1453 107.994 51.1453Z"
         fill="black"
      />
      <path
         d="M143.375 36.0232C143.126 36.0232 142.925 35.822 142.925 35.5726C142.925 32.1361 141.711 28.7956 139.512 26.1641C139.353 25.9719 139.377 25.6895 139.569 25.5303C139.762 25.3711 140.044 25.3951 140.203 25.5874C142.54 28.3811 143.826 31.9258 143.826 35.5726C143.826 35.822 143.625 36.0232 143.375 36.0232Z"
         fill="black"
      />
      <path
         d="M128.252 51.1453C119.667 51.1453 112.68 44.1611 112.68 35.5727C112.68 26.9873 119.664 20 128.252 20C131.374 20 134.384 20.9192 136.958 22.6585C137.165 22.7967 137.219 23.0791 137.078 23.2834C136.94 23.4906 136.658 23.5447 136.453 23.4035C134.026 21.7633 131.19 20.8982 128.252 20.8982C120.163 20.8982 113.581 27.4799 113.581 35.5697C113.581 43.6594 120.163 50.2411 128.252 50.2411C128.502 50.2411 128.703 50.4424 128.703 50.6917C128.703 50.9411 128.502 51.1453 128.252 51.1453Z"
         fill="black"
      />
      <path
         d="M163.634 36.0231C163.384 36.0231 163.183 35.8219 163.183 35.5725C163.183 32.2261 162.084 29.0719 160.008 26.4554C159.852 26.2602 159.885 25.9778 160.08 25.8216C160.275 25.6654 160.558 25.6984 160.714 25.8937C162.919 28.6694 164.084 32.0158 164.084 35.5695C164.084 35.8219 163.883 36.0231 163.634 36.0231Z"
         fill="black"
      />
      <path
         d="M148.511 51.1453C139.926 51.1453 132.938 44.1611 132.938 35.5727C132.938 26.9873 139.923 20 148.511 20C151.62 20 154.621 20.9132 157.19 22.6405C157.397 22.7787 157.451 23.0581 157.313 23.2653C157.175 23.4726 156.895 23.5267 156.688 23.3885C154.267 21.7603 151.44 20.9012 148.514 20.9012C140.424 20.9012 133.843 27.4829 133.843 35.5727C133.843 43.6624 140.424 50.2441 148.514 50.2441C148.763 50.2441 148.965 50.4454 148.965 50.6947C148.965 50.9441 148.76 51.1453 148.511 51.1453Z"
         fill="black"
      />
      <path
         d="M183.893 36.0232C183.644 36.0232 183.443 35.8219 183.443 35.5726C183.443 32.2411 182.355 29.105 180.297 26.4945C180.144 26.2992 180.177 26.0169 180.373 25.8607C180.568 25.7075 180.85 25.7405 181.006 25.9358C183.19 28.7054 184.344 32.0369 184.344 35.5726C184.344 35.8219 184.143 36.0232 183.893 36.0232Z"
         fill="black"
      />
      <path
         d="M168.771 51.1453C160.186 51.1453 153.198 44.1611 153.198 35.5727C153.198 26.9873 160.183 20 168.771 20C171.808 20 174.749 20.8742 177.281 22.5294C177.488 22.6645 177.549 22.9439 177.41 23.1542C177.275 23.3615 176.996 23.4215 176.786 23.2834C174.403 21.7243 171.631 20.9012 168.771 20.9012C160.681 20.9012 154.099 27.4829 154.099 35.5727C154.099 43.6624 160.681 50.2441 168.771 50.2441C169.02 50.2441 169.222 50.4454 169.222 50.6947C169.222 50.9441 169.02 51.1453 168.771 51.1453Z"
         fill="black"
      />
      <path
         d="M204.151 36.0231C203.902 36.0231 203.7 35.8218 203.7 35.5725C203.7 32.202 202.529 28.9127 200.405 26.3052C200.249 26.113 200.276 25.8276 200.468 25.6714C200.66 25.5152 200.946 25.5422 201.102 25.7345C203.358 28.5011 204.602 31.9948 204.602 35.5695C204.602 35.8219 204.4 36.0231 204.151 36.0231Z"
         fill="black"
      />
      <path
         d="M189.03 51.1453C180.444 51.1453 173.457 44.1611 173.457 35.5727C173.457 26.9873 180.441 20 189.03 20C192.055 20 194.987 20.8682 197.51 22.5083C197.717 22.6435 197.777 22.9229 197.642 23.1332C197.507 23.3404 197.228 23.4005 197.017 23.2653C194.641 21.7183 191.877 20.9012 189.03 20.9012C180.94 20.9012 174.358 27.4829 174.358 35.5727C174.358 43.6624 180.94 50.2441 189.03 50.2441C189.279 50.2441 189.48 50.4454 189.48 50.6947C189.48 50.9441 189.279 51.1453 189.03 51.1453Z"
         fill="black"
      />
      <path
         d="M209.288 51.1453C200.703 51.1453 193.716 44.1611 193.716 35.5727C193.716 26.9873 200.7 20 209.288 20C217.874 20 224.861 26.9843 224.861 35.5727C224.861 35.822 224.66 36.0233 224.411 36.0233C224.161 36.0233 223.96 35.822 223.96 35.5727C223.96 27.4829 217.378 20.9012 209.288 20.9012C201.199 20.9012 194.617 27.4829 194.617 35.5727C194.617 43.6624 201.199 50.2441 209.288 50.2441C209.538 50.2441 209.739 50.4454 209.739 50.6947C209.739 50.9441 209.538 51.1453 209.288 51.1453Z"
         fill="black"
      />
      <path
         d="M67.7706 53.9089C66.8604 53.9089 65.9862 53.5244 65.3704 52.8545C65.2022 52.6712 65.2142 52.3859 65.3974 52.2176C65.5807 52.0494 65.866 52.0614 66.0343 52.2447C66.4788 52.7283 67.1127 53.0077 67.7706 53.0077C69.0683 53.0077 70.1257 51.9503 70.1257 50.6526C70.1257 49.3548 69.0683 48.2974 67.7706 48.2974C67.0226 48.2974 66.3106 48.6579 65.869 49.2647C65.7218 49.466 65.4395 49.508 65.2382 49.3639C65.0369 49.2167 64.9949 48.9343 65.1391 48.733C65.7519 47.8979 66.7342 47.3962 67.7676 47.3962C69.5639 47.3962 71.0239 48.8562 71.0239 50.6526C71.0239 52.4489 69.5639 53.9089 67.7706 53.9089Z"
         fill="black"
      />
      <path
         d="M87.6778 53.9089C86.7676 53.9089 85.8934 53.5244 85.2776 52.8545C85.1094 52.6712 85.1214 52.3859 85.3046 52.2176C85.4879 52.0494 85.7733 52.0614 85.9415 52.2447C86.3861 52.7283 87.0199 53.0077 87.6778 53.0077C88.9755 53.0077 90.0329 51.9503 90.0329 50.6526C90.0329 49.3548 88.9755 48.2974 87.6778 48.2974C86.9298 48.2974 86.2179 48.6579 85.7763 49.2647C85.6291 49.466 85.3467 49.508 85.1454 49.3639C84.9442 49.2167 84.9021 48.9343 85.0463 48.733C85.6591 47.8979 86.6414 47.3962 87.6748 47.3962C89.4712 47.3962 90.9311 48.8562 90.9311 50.6526C90.9311 52.4489 89.4742 53.9089 87.6778 53.9089Z"
         fill="black"
      />
      <path
         d="M107.589 53.9089C106.679 53.9089 105.805 53.5244 105.189 52.8545C105.021 52.6712 105.033 52.3859 105.216 52.2176C105.399 52.0494 105.684 52.0614 105.853 52.2447C106.297 52.7283 106.931 53.0077 107.589 53.0077C108.887 53.0077 109.944 51.9503 109.944 50.6526C109.944 49.3548 108.887 48.2974 107.589 48.2974C106.841 48.2974 106.129 48.6579 105.687 49.2647C105.54 49.466 105.258 49.508 105.057 49.3639C104.855 49.2167 104.813 48.9343 104.957 48.733C105.57 47.8979 106.553 47.3962 107.586 47.3962C109.382 47.3962 110.842 48.8562 110.842 50.6526C110.842 52.4489 109.382 53.9089 107.589 53.9089Z"
         fill="black"
      />
      <path
         d="M128.066 53.9089C127.156 53.9089 126.282 53.5244 125.666 52.8545C125.498 52.6712 125.51 52.3859 125.693 52.2176C125.877 52.0494 126.162 52.0614 126.33 52.2447C126.775 52.7283 127.409 53.0077 128.066 53.0077C129.364 53.0077 130.422 51.9503 130.422 50.6526C130.422 49.3548 129.364 48.2974 128.066 48.2974C127.318 48.2974 126.607 48.6579 126.165 49.2647C126.018 49.466 125.735 49.508 125.534 49.3639C125.333 49.2167 125.291 48.9343 125.435 48.733C126.048 47.8979 127.03 47.3962 128.063 47.3962C129.86 47.3962 131.32 48.8562 131.32 50.6526C131.32 52.4489 129.863 53.9089 128.066 53.9089Z"
         fill="black"
      />
      <path
         d="M148.188 53.9089C147.277 53.9089 146.403 53.5244 145.787 52.8545C145.619 52.6712 145.631 52.3859 145.814 52.2176C145.998 52.0494 146.283 52.0614 146.451 52.2447C146.896 52.7283 147.53 53.0077 148.188 53.0077C149.485 53.0077 150.543 51.9503 150.543 50.6526C150.543 49.3548 149.485 48.2974 148.188 48.2974C147.44 48.2974 146.728 48.6579 146.286 49.2647C146.139 49.466 145.856 49.508 145.655 49.3639C145.454 49.2167 145.412 48.9343 145.556 48.733C146.169 47.8979 147.151 47.3962 148.185 47.3962C149.981 47.3962 151.441 48.8562 151.441 50.6526C151.441 52.4489 149.981 53.9089 148.188 53.9089Z"
         fill="black"
      />
      <path
         d="M169.266 53.9089C168.356 53.9089 167.481 53.5244 166.866 52.8545C166.697 52.6712 166.709 52.3859 166.893 52.2176C167.076 52.0494 167.361 52.0614 167.529 52.2447C167.974 52.7283 168.608 53.0077 169.266 53.0077C170.564 53.0077 171.621 51.9503 171.621 50.6526C171.621 49.3548 170.564 48.2974 169.266 48.2974C168.518 48.2974 167.806 48.6579 167.364 49.2647C167.217 49.466 166.935 49.508 166.733 49.3639C166.532 49.2167 166.49 48.9343 166.634 48.733C167.247 47.8979 168.229 47.3962 169.263 47.3962C171.059 47.3962 172.519 48.8562 172.519 50.6526C172.519 52.4489 171.062 53.9089 169.266 53.9089Z"
         fill="black"
      />
      <path
         d="M189.174 53.9089C188.264 53.9089 187.39 53.5244 186.774 52.8545C186.605 52.6712 186.617 52.3859 186.801 52.2176C186.984 52.0494 187.269 52.0614 187.438 52.2447C187.882 52.7283 188.516 53.0077 189.174 53.0077C190.472 53.0077 191.529 51.9503 191.529 50.6526C191.529 49.3548 190.472 48.2974 189.174 48.2974C188.426 48.2974 187.714 48.6579 187.272 49.2647C187.125 49.466 186.843 49.508 186.642 49.3639C186.44 49.2167 186.398 48.9343 186.542 48.733C187.155 47.8979 188.138 47.3962 189.171 47.3962C190.967 47.3962 192.427 48.8562 192.427 50.6526C192.427 52.4489 190.97 53.9089 189.174 53.9089Z"
         fill="black"
      />
      <path
         d="M209.084 53.9089C208.174 53.9089 207.3 53.5244 206.684 52.8545C206.516 52.6712 206.528 52.3859 206.711 52.2176C206.894 52.0494 207.18 52.0614 207.348 52.2447C207.792 52.7283 208.426 53.0077 209.084 53.0077C210.382 53.0077 211.439 51.9503 211.439 50.6526C211.439 49.3548 210.382 48.2974 209.084 48.2974C208.336 48.2974 207.624 48.6579 207.183 49.2647C207.035 49.466 206.753 49.508 206.552 49.3639C206.35 49.2167 206.308 48.9343 206.453 48.733C207.065 47.8979 208.048 47.3962 209.081 47.3962C210.877 47.3962 212.337 48.8562 212.337 50.6526C212.337 52.4489 210.88 53.9089 209.084 53.9089Z"
         fill="black"
      />
      <path
         d="M169.56 124.049C169.416 124.049 169.272 124.037 169.127 124.01C168.307 123.857 167.631 123.277 167.355 122.49L165.117 116.657C164.79 115.803 165.216 114.848 166.069 114.524C166.922 114.196 167.878 114.623 168.202 115.476L169.764 119.552L174.892 111.364C175.376 110.591 176.397 110.357 177.169 110.841C177.941 111.324 178.175 112.346 177.692 113.118L171.428 123.115L171.359 123.196C170.918 123.74 170.251 124.049 169.56 124.049Z"
         fill="#367BF5"
      />
      <path
         d="M137.955 167.998C137.814 167.998 137.67 167.986 137.529 167.959C136.715 167.805 136.045 167.232 135.768 166.454L133.563 160.701C133.236 159.848 133.663 158.892 134.516 158.568C135.369 158.241 136.324 158.667 136.648 159.52L138.165 163.48L143.101 155.6C143.585 154.828 144.606 154.594 145.378 155.077C146.15 155.561 146.384 156.582 145.901 157.354L139.815 167.069L139.746 167.15C139.301 167.691 138.643 167.998 137.955 167.998Z"
         fill="#367BF5"
      />
      <path
         d="M126.6 102.352C126.456 102.352 126.312 102.339 126.168 102.312C125.345 102.156 124.666 101.576 124.389 100.786L122.103 94.8235C121.776 93.9704 122.203 93.0151 123.056 92.6907C123.909 92.3632 124.864 92.7898 125.189 93.6429L126.808 97.8666L132.023 89.5425C132.506 88.7705 133.528 88.5361 134.3 89.0198C135.072 89.5034 135.306 90.5248 134.822 91.2968L128.481 101.417L128.412 101.498C127.961 102.045 127.294 102.352 126.6 102.352Z"
         fill="#367BF5"
      />
      <path
         d="M16.7723 50.5863C18.6653 50.5863 20.1998 49.0517 20.1998 47.1588C20.1998 45.2658 18.6653 43.7312 16.7723 43.7312C14.8793 43.7312 13.3447 45.2658 13.3447 47.1588C13.3447 49.0517 14.8793 50.5863 16.7723 50.5863Z"
         fill="#CADDFF"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconCalender',
};
</script>

<style scoped></style>
