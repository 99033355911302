<template>
   <div class="footer pe-0">
      <div class="d-flex flex-row py-4 ms-5 justify-content-between">
         <p class="text-secondary">© 2021 Finder. All Rights Reserved.</p>
         <ul class="d-flex flex-row text-secondary me-5">
            <li>{{ $t('footer.Yardim Merkezi') }}</li>
            <li class="ms-5">{{ $t('footer.Kullanım Şartları') }}</li>
            <li class="ms-5">{{ $t('footer.Gizlilik Sözleşmesi') }}</li>
         </ul>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Footer',
};
</script>

<style scoped>
.footer {
   margin-top: 120px;
   border-top: 2px solid #e2e2e2;
   padding-top: 22px;
}
</style>
