import Http from '../libs/http';

class RiskClassesApi extends Http {
   index() {
      return super.get('api/panel/risk-classes', {});
   }
   CREATE_RISK(payload) {
      return super.post('api/panel/risk-classes', payload);
   }
   EDIT_RISK(id, payload) {
      return super.put(`api/panel/risk-classes/${id}`, payload);
   }
   REMOVE_RISK(ID) {
      return super.delete(`api/panel/risk-classes/${ID}`);
   }
}

export default new RiskClassesApi();
