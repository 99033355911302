import Http from '../libs/http';

class ProjectMessagesApi extends Http {
   index(projectID) {
      return super.get(`api/panel/projects/${projectID}/messages`);
   }
   CREAT_MESSAGE(projectID, payload) {
      return super.post(`api/panel/projects/${projectID}/messages`, payload);
   }
   EDIT_MESSAGE(projectID, messageID, payload) {
      return super.put(`api/panel/projects/${projectID}/messages/${messageID}`, payload);
   }
   REMOVE_MESSAGE(projectID, messageID) {
      return super.delete(`api/panel/projects/${projectID}/messages/${messageID}`);
   }
}

export default new ProjectMessagesApi();
