<template>
   <AmcModal ref="modal">
      <template #header>
         <AmcText class="h4 pt-3"> {{ $t('user.modal.Kullanıcı Düzenle') }}</AmcText>
      </template>
      <template>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">{{ $t('user.modal.Adı Soyadı') }}</AmcText>
            <input v-model="item.name" type="text" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">E-mail</AmcText>
            <input v-model="item.email" type="email" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">{{ $t('user.modal.Telefon') }}</AmcText>
            <input v-model="item.phone" type="email" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">{{ $t('user.modal.Proje') }}</AmcText>
            <VueSelect v-model="item.projects" :options="allProjects" label="name" multiple />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600 fs-16">{{ $t('user.modal.Şifre') }}</AmcText>
            <input v-model="item.password" type="password" class="form-control shadow-none" />
         </AmcText>
      </template>
      <template #actions>
         <AmcText>
            <button @click="hideModal" class="btn btn-outline-secondary">{{ $t('modalActions.Vazgeç') }}</button>
         </AmcText>
         <AmcText>
            <button @click="edit(item)" class="btn btn-primary">{{ $t('modalActions.Kaydet') }}</button>
         </AmcText>
      </template>
   </AmcModal>
</template>

<script>
import ProjectsApi from '@/api/ProjectsApi';
import map from 'lodash/map';
import pick from 'lodash/pick';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'ProjectManagerModal',
   data() {
      return {
         item: {
            password: '',
         },
         allProjects: [],
      };
   },
   methods: {
      show(item) {
         this.$refs.modal.show();
         this.item = item;
         this.loadProjects();
      },
      hideModal() {
         this.$refs.modal.hide();
      },
      edit(item) {
         this.hideModal();
         this.$emit('edit', item);
      },
      async loadProjects() {
         this.loadData = true;

         try {
            const { data } = await ProjectsApi.index();
            this.allProjects = map(data, (item) => pick(item, ['id', 'name']));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
   },
};
</script>
