<template>
   <svg fill="currentColor" style="width: 24px; height: 24px" viewBox="0 0 24 24">
      <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
   </svg>
</template>

<script>
export default {
   name: 'IconSend',
};
</script>

<style scoped>
svg:hover {
   fill: #137af6;
}
</style>
