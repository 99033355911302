<template>
   <AmcText v-on:keyup.enter="createRiskClass">
      <AmcCard v-if="!loadData" shadow Card p3 class="mt-4 d-flex justify-content-between">
         <AmcText class="align-self-center" fwBold> {{ $t('definition.Yeni Risk Sınıfı Tanımı') }}</AmcText>
         <AmcText class="d-flex">
            <AmcInput
               v-model="addRiskClass.name"
               width="300"
               py="2"
               secondary
               :placeholder="$t('placeholders.Yeni Risk Sınıfı Ekle')"
            ></AmcInput>
            <AmcButton @click="createRiskClass" class="text-white ms-3" :icon="IconAdded" ps="3" px="3" height="35px">{{
               $t('buttons.Yeni Risk Sınıfı Ekle')
            }}</AmcButton>
         </AmcText>
      </AmcCard>
      <AmcCard v-if="!loadData" p3 Card shadow class="mt-4">
         <AmcText as="table" class="table-striped table-borderless table">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText
                     v-for="(col, index) in headers"
                     :key="index"
                     as="th"
                     class="text-uppercase text-secondary text-start table-header"
                  >
                     {{ $t(`tableTitle.${col.title}`) }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText as="tr" class="table-row" v-for="risk in riskClasses" :key="risk.id">
                  <AmcText as="th" class="table-item input-name">
                     <input type="text" class="table-input form-control mt-2 fw-bold" v-model="risk.name" />
                  </AmcText>
                  <AmcText as="th" class="table-item text-center">
                     <button class="mt-2" @click="showRemoveModal(risk)"><IconDel /></button>
                     <button class="mt-2 ms-3" @click="editRiskClass(risk)">
                        <IconEdit />
                     </button>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcCard>
      <AmcRemoveModal ref="deleteModal" @remove="removeRiskClass" />
      <AmcLoading v-if="loadData" />
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import RiskClassesApi from '@/api/RiskClassesApi';
import IconEdit from '@/components/icons/IconEdit';
import IconAdded from '@/components/icons/IconAdded';

export default {
   name: 'RiskClasses',
   components: {
      IconDel,
      IconEdit,
   },
   data() {
      return {
         IconAdded,
         riskClasses: {},
         addRiskClass: Form.create(['name']).validate({ name: 'required' }),
         headers: [{ title: 'RİSK SINIFLARI' }],
         loadData: false,
      };
   },
   methods: {
      async loadRiskClasses() {
         this.loadData = true;
         try {
            await RiskClassesApi.index().then(({ data }) => (this.riskClasses = data));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
      async createRiskClass() {
         try {
            await RiskClassesApi.CREATE_RISK(this.addRiskClass.toObject()).then(() => {
               this.loadRiskClasses();
            });
            Notify.success(this.$t('notify.Risk Sınıfı Eklendi'));
            this.addRiskClass.reset();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async editRiskClass(item) {
         try {
            this.loadData = true;
            await RiskClassesApi.EDIT_RISK(item.id, item).then(() => {
               this.loadRiskClasses();
               this.loadData = false;
            });
            Notify.success(this.$t('notify.Risk Sınıfı Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeRiskClass(item) {
         try {
            await RiskClassesApi.REMOVE_RISK(item.id).then(() => {
               this.loadRiskClasses();
               Notify.error(this.$t('notify.Risk Sınıfı Silindi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   created() {
      this.loadRiskClasses();
   },
};
</script>

<style scoped lang="scss">
.active-bar {
   height: 4px;
   background-color: #137af6;
   border-radius: 3px 3px 0 0;
   margin-top: 13px;
}
.item-text {
   padding-top: 25px;
   &:hover {
      color: #000000;
   }
}
.table {
   .table-header {
      &:nth-child(1) {
         padding-right: 400px;
         padding-left: 50px;
      }
   }
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
   & .table-row:focus-within {
      border: 1px solid #137af6;
      --bs-table-striped-bg: #ffffff;
   }
   & .input-name {
      padding-left: 50px;
   }
   & .table-input {
      border: none !important;
      background-color: transparent;
      padding-left: 0 !important;
      &:focus {
         box-shadow: none;
      }
   }
}
</style>
