import Http from '../libs/http';

class Cities extends Http {
   index(countryID, cityID) {
      return super.get(`api/panel/countries/${countryID}/cities/${cityID}/districts`);
   }

   CREATE_DISTRICT(countryID, cityID, payload) {
      return super.post(`api/panel/countries/${countryID}/cities/${cityID}/districts`, payload);
   }

   EDIT_DISTRICT(countryID, cityID, districtID, payload) {
      return super.put(`api/panel/countries/${countryID}/cities/${cityID}/districts/${districtID}`, payload);
   }

   REMOVE_DISTRICT(countryID, cityID, districtID) {
      return super.delete(`api/panel/countries/${countryID}/cities/${cityID}/districts/${districtID}`);
   }
}

export default new Cities();
