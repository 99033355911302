<template>
   <AmcText class="mt-5 px-4">
      <AmcText class="border rounded-8 bg-light">
         <AmcText class="border-bottom">
            <AmcText class="p-4 fs-3"> {{ $t('geriBildirim.Geri Bildirim') }}</AmcText>
         </AmcText>
         <AmcText class="border-bottom">
            <AmcText class="p-4 fw-600 text-secondary">{{ $t('geriBildirim.GERİ BİLDİRİM OLUŞTURMA') }}</AmcText>
         </AmcText>
         <AmcText>
            <AmcText class="p-4">
               <textarea
                  class="form-control shadow-none p-3"
                  :placeholder="$t('placeholders.Geri bildirimizi detaylı bir şekilde belirtiniz')"
                  id="floatingTextarea2"
                  style="height: 200px"
                  v-model="newMessage.content"
               ></textarea>
            </AmcText>
            <AmcText class="d-flex justify-content-end pb-4 pe-4">
               <AmcButton @click="createMessage" class="text-white px-5">{{ $t('modalActions.Gönder') }}</AmcButton>
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcText class="rounded-8 shadow-sm mt-5 bg-light pb-4">
         <AmcText class="border-bottom">
            <AmcText class="fs-3 p-4">{{ $t('geriBildirim.Geri Bildirimler') }}</AmcText>
         </AmcText>
         <AmcText v-for="(message, index) in dataMessages" :key="index" class="border m-3 rounded-8">
            <AmcText class="rounded-8 m-4 bg-white">
               <AmcText class="messages-header d-flex justify-content-between border-bottom">
                  <AmcText class="py-3 px-4 text-secondary fw-600"> {{ get(message, 'owner.name') }} </AmcText>
                  <AmcText class="py-3 px-4 text-secondary">
                     {{ moment(get(message, 'created_at')).format('DD-MM-YYYY HH:MM:SS') }}
                  </AmcText>
               </AmcText>
               <AmcText class="p-4"> {{ get(message, 'content') }} </AmcText>
            </AmcText>
            <AmcText v-for="(answer, index) in get(message, 'answers')" :key="index" class="d-flex justify-content-end">
               <AmcText class="me-4 mb-4 bg-white rounded-8 w-75">
                  <AmcText class="d-flex justify-content-between border-bottom">
                     <AmcText class="px-4 py-2 text-secondary"> {{ get(answer, 'owner.name') }} </AmcText>
                     <AmcText class="px-4 py-2 text-secondary">
                        {{ moment(get(answer, 'updated_at')).format('DD-MM-YYYY HH:MM:SS') }}
                     </AmcText>
                  </AmcText>
                  <AmcText class="p-4"> {{ get(answer, 'content') }} </AmcText>
               </AmcText>
            </AmcText>
            <AmcText class="m-4">
               <AmcText class="position-relative">
                  <input
                     v-model="sendAnswer.content"
                     type="text"
                     class="form-control rounded-pill py-3 px-3 shadow-none"
                     :placeholder="$t('placeholders.Yanıtla')"
                  />
                  <span role="button" class="icon-send" @click="asnwerMessage(message.id)">
                     <IconSend />
                  </span>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import Form from '@/libs/form';
import MessagesApi from '@/api/MessagesApi';
import ErrorHandler from '@/libs/error-handler';
import IconSend from '@/components/UI/IconSend';

export default {
   name: 'Feedback',
   components: {
      IconSend,
   },
   data() {
      return {
         headers: [{ title: 'DURUM' }, { title: 'BİLDİRİM' }, { title: 'YANITLA' }],
         dataMessages: [],
         newMessage: Form.create(['content']).defaults({
            message: '',
         }),
         sendAnswer: Form.create(['content', 'parent_id']).defaults({
            content: '',
         }),
      };
   },
   methods: {
      get,
      async getMessages() {
         try {
            const response = await MessagesApi.index();
            this.dataMessages = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async createMessage() {
         try {
            await MessagesApi.CREAT_MESSAGE(this.newMessage.toObject());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.newMessage.reset();
            await this.getMessages();
         }
      },
      async asnwerMessage(ID) {
         this.sendAnswer.parent_id = ID;
         try {
            await MessagesApi.CREAT_MESSAGE(this.sendAnswer.toObject());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.sendAnswer.reset();
            await this.getMessages();
         }
      },
   },
   async beforeMount() {
      await this.getMessages();
   },
};
</script>

<style scoped lang="scss">
.icon-send {
   position: absolute;
   right: 10px;
   top: 15px;
}
</style>
