import Http from '../libs/http';

class CountriesApi extends Http {
   index() {
      return super.get('api/panel/countries', {});
   }
   CREATE_COUNTRY(payload) {
      return super.post('api/panel/countries', payload);
   }
   EDIT_COUNTRY(id, payload) {
      return super.put(`api/panel/countries/${id}`, payload);
   }
   REMOVE_COUNTRY(ID) {
      return super.delete(`api/panel/countries/${ID}`);
   }
}

export default new CountriesApi();
