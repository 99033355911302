<template>
   <svg width="21" height="33" viewBox="0 0 21 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M2.9 1H18.1C19.145 1 20 2.74375 20 4.875V28.125C20 30.2563 19.145 32 18.1 32H2.9C1.855 32 1 30.2563 1 28.125V4.875C1 2.74375 1.855 1 2.9 1Z"
         stroke="#137AF6"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <circle cx="10.5" cy="28.5" r="1.5" fill="#137AF6" />
      <line x1="1" y1="4.5" x2="19" y2="4.5" stroke="#137AF6" />
   </svg>
</template>

<script>
export default {
   name: 'IconPhone',
};
</script>

<style scoped></style>
