<template>
   <AmcText class="container-fluid px-0 position-relative">
      <AmcText v-if="this.loading">
         <AmcText class="sidebar-container">
            <Sidebar />
         </AmcText>
         <AmcText class="content">
            <Navbar />
            <AmcText v-if="this.$route.path === '/'" class="m-3">
               <AmcText class="ms-4" fsXlarge> {{ $t('dashboard.Genel Bakış') }} </AmcText>
               <AmcText>
                  <AmcRow>
                     <AmcCol col="6" class="mb-2">
                        <AmcText class="border rounded-8">
                           <AmcText class="text-center rounded-8 p-2 bg-alarm">
                              <span class="fw-600 fs-4 text-white"
                                 >{{ $t('dashboard.Alarm') }} ({{ get(allAlarm, 'data').length }})</span
                              >
                           </AmcText>
                           <AmcText class="p-3">
                              <input
                                 type="text"
                                 class="form-control shadow-none"
                                 :placeholder="$t('placeholders.Arama')"
                                 v-model="searchAlarm"
                              />
                           </AmcText>
                           <AmcText class="border-top">
                              <vue-custom-scrollbar v-if="allAlarm.data.length > 0" class="scroll" :settings="settings">
                                 <AmcText as="table" class="table-striped table-borderless table">
                                    <AmcText as="thead">
                                       <AmcText as="tr">
                                          <AmcText
                                             as="th"
                                             class="text-secondary table-header"
                                             v-for="(row, index) in headers"
                                             :key="index"
                                             v-html="$t(`tableTitle.${row.title}`)"
                                          >
                                          </AmcText>
                                       </AmcText>
                                    </AmcText>
                                    <AmcText as="tbody">
                                       <AmcText
                                          as="tr"
                                          class="table-row"
                                          v-for="alarm in $options.filters.filterTableData(
                                             allAlarm,
                                             headers,
                                             searchAlarm
                                          ).data"
                                          :key="alarm.id"
                                       >
                                          <AmcText as="td" class="table-item">
                                             <span class="table-item icon-notification px-2" @click="showModal(alarm)">
                                                <IconNotification />
                                             </span>
                                             {{ get(alarm, 'building.short_name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(alarm, 'serial_number') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(alarm, 'building.project.name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ moment(get(alarm, 'last_seen_at')).format('DD-MM-YYYY HH:MM:SS') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <AmcText
                                                v-if="get(alarm, 'building.google_pos')"
                                                as="a"
                                                target="_blank"
                                                :href="get(alarm, 'building.google_pos')"
                                                fwBold
                                                class="text-muted fs-15 h-100"
                                             >
                                                {{ $t('dashboard.Lokasyon') }}
                                             </AmcText>
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <button
                                                @click="showPlan(alarm)"
                                                v-if="get(alarm, 'building.site_plan')"
                                                class="btn btn-small btn-primary"
                                             >
                                                Plan
                                             </button>
                                          </AmcText>
                                       </AmcText>
                                    </AmcText>
                                 </AmcText>
                              </vue-custom-scrollbar>
                              <AmcText v-if="allAlarm.data.length < 1" class="alert alert-warning m-3">{{
                                 $t('dashboard.Şuan herhangi bir veri bulunmamaktadır')
                              }}</AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcCol>
                     <AmcCol col="6" class="mb-2">
                        <AmcText class="border rounded-8">
                           <AmcText class="text-center rounded-8 p-2 bg-fault">
                              <span class="fw-600 fs-4 text-white">
                                 {{ $t('dashboard.Arıza') }} ({{ get(allFault, 'data').length }})</span
                              >
                           </AmcText>
                           <AmcText class="p-3">
                              <input
                                 type="text"
                                 class="form-control shadow-none"
                                 :placeholder="$t('placeholders.Arama')"
                                 v-model="searchFault"
                              />
                           </AmcText>
                           <AmcText class="border-top">
                              <vue-custom-scrollbar v-if="allFault.data.length > 0" class="scroll" :settings="settings">
                                 <AmcText as="table" class="table-striped table-borderless table">
                                    <AmcText as="thead">
                                       <AmcText as="tr">
                                          <AmcText
                                             as="th"
                                             class="text-secondary table-header"
                                             v-for="(row, index) in headers"
                                             :key="index"
                                             v-html="$t(`tableTitle.${row.title}`)"
                                          />
                                       </AmcText>
                                    </AmcText>
                                    <AmcText as="tbody">
                                       <AmcText
                                          as="tr"
                                          class="table-row"
                                          v-for="fault in $options.filters.filterTableData(
                                             allFault,
                                             headers,
                                             searchFault
                                          ).data"
                                          :key="fault.id"
                                       >
                                          <AmcText as="td" class="table-item">
                                             <span class="table-item icon-notification px-2" @click="showModal(fault)">
                                                <IconNotification />
                                             </span>
                                             {{ get(fault, 'building.short_name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(fault, 'serial_number') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(fault, 'building.project.name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ moment(get(fault, 'last_seen_at')).format('DD-MM-YYYY HH:MM:SS') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <AmcText
                                                v-if="get(fault, 'building.google_pos')"
                                                as="a"
                                                target="_blank"
                                                :href="get(fault, 'building.google_pos')"
                                                fwBold
                                                class="text-muted fs-15 h-100"
                                             >
                                                {{ $t('dashboard.Lokasyon') }}
                                             </AmcText>
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <button
                                                @click="showPlan(fault)"
                                                v-if="get(fault, 'building.site_plan')"
                                                class="btn btn-small btn-primary"
                                             >
                                                Plan
                                             </button>
                                          </AmcText>
                                       </AmcText>
                                    </AmcText>
                                 </AmcText>
                              </vue-custom-scrollbar>
                              <AmcText v-if="allFault.data.length < 1" class="alert alert-warning m-3">
                                 {{ $t('dashboard.Şuan herhangi bir veri bulunmamaktadır') }}
                              </AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcCol>
                     <AmcCol col="6" class="mb-2">
                        <AmcText class="border rounded-8">
                           <AmcText class="text-center rounded-8 p-2 bg-offline">
                              <span class="fw-600 fs-4 text-white">
                                 {{ $t('dashboard.Çevrimdışı') }} ({{ get(allOffline, 'data').length }})</span
                              >
                           </AmcText>
                           <AmcText class="p-3">
                              <input
                                 type="text"
                                 class="form-control shadow-none"
                                 :placeholder="$t('placeholders.Arama')"
                                 v-model="searchOffline"
                              />
                           </AmcText>
                           <AmcText class="border-top">
                              <vue-custom-scrollbar
                                 v-if="allOffline.data.length > 0"
                                 class="scroll"
                                 :settings="settings"
                              >
                                 <AmcText as="table" class="table-striped table-borderless table">
                                    <AmcText as="thead">
                                       <AmcText as="tr">
                                          <AmcText
                                             as="th"
                                             class="text-secondary table-header"
                                             v-for="(row, index) in headers"
                                             :key="index"
                                             v-html="$t(`tableTitle.${row.title}`)"
                                          />
                                       </AmcText>
                                    </AmcText>
                                    <AmcText as="tbody">
                                       <AmcText
                                          as="tr"
                                          class="table-row"
                                          v-for="offline in $options.filters.filterTableData(
                                             allOffline,
                                             headers,
                                             searchOffline
                                          ).data"
                                          :key="offline.id"
                                       >
                                          <AmcText as="td" class="table-item">
                                             <span
                                                class="table-item icon-notification px-2"
                                                @click="showModal(offline)"
                                             >
                                                <IconNotification />
                                             </span>
                                             {{ get(offline, 'building.short_name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(offline, 'serial_number') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(offline, 'building.project.name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ moment(get(offline, 'last_seen_at')).format('DD-MM-YYYY HH:MM:SS') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <AmcText
                                                v-if="get(offline, 'building.google_pos')"
                                                as="a"
                                                target="_blank"
                                                :href="get(offline, 'building.google_pos')"
                                                fwBold
                                                class="text-muted fs-15 h-100"
                                             >
                                                {{ $t('dashboard.Lokasyon') }}
                                             </AmcText>
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <button
                                                @click="showPlan(offline)"
                                                v-if="get(offline, 'building.site_plan')"
                                                class="btn btn-small btn-primary"
                                             >
                                                Plan
                                             </button>
                                          </AmcText>
                                       </AmcText>
                                    </AmcText>
                                 </AmcText>
                              </vue-custom-scrollbar>
                              <AmcText v-if="allOffline.data.length < 1" class="alert alert-warning m-3">{{
                                 $t('dashboard.Şuan herhangi bir veri bulunmamaktadır')
                              }}</AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcCol>
                     <AmcCol col="6" class="mb-2">
                        <AmcText class="border rounded-8">
                           <AmcText class="text-center rounded-8 p-2 bg-online">
                              <span class="fw-600 fs-4 text-white"
                                 >{{ $t('dashboard.Çevrimiçi') }} ({{ get(allOnline, 'data').length }})</span
                              >
                           </AmcText>
                           <AmcText class="p-3">
                              <input
                                 type="text"
                                 class="form-control shadow-none"
                                 :placeholder="$t('placeholders.Arama')"
                                 v-model="searchOnline"
                              />
                           </AmcText>
                           <AmcText class="border-top">
                              <vue-custom-scrollbar
                                 v-if="allOnline.data.length > 0"
                                 class="scroll"
                                 :settings="settings"
                              >
                                 <AmcText as="table" class="table-striped table-borderless table">
                                    <AmcText as="thead">
                                       <AmcText as="tr">
                                          <AmcText
                                             as="th"
                                             class="text-secondary table-header"
                                             v-for="(row, index) in headers"
                                             :key="index"
                                             v-html="$t(`tableTitle.${row.title}`)"
                                          />
                                       </AmcText>
                                    </AmcText>
                                    <AmcText as="tbody">
                                       <AmcText
                                          as="tr"
                                          class="table-row"
                                          v-for="online in $options.filters.filterTableData(
                                             allOnline,
                                             headers,
                                             searchOnline
                                          ).data"
                                          :key="online.id"
                                       >
                                          <AmcText as="td" class="table-item">
                                             <span class="table-item icon-notification px-2" @click="showModal(online)">
                                                <IconNotification />
                                             </span>
                                             {{ get(online, 'building.short_name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(online, 'serial_number') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ get(online, 'building.project.name') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             {{ moment(get(online, 'last_seen_at')).format('DD-MM-YYYY HH:MM:SS') }}
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <AmcText
                                                v-if="get(online, 'building.google_pos')"
                                                as="a"
                                                target="_blank"
                                                :href="get(online, 'building.google_pos')"
                                                fwBold
                                                class="text-muted fs-15 h-100"
                                             >
                                                {{ $t('dashboard.Lokasyon') }}
                                             </AmcText>
                                          </AmcText>
                                          <AmcText as="td" class="table-item">
                                             <button
                                                @click="showPlan(online)"
                                                v-if="get(online, 'building.site_plan')"
                                                class="btn btn-small btn-primary"
                                             >
                                                Plan
                                             </button>
                                          </AmcText>
                                       </AmcText>
                                    </AmcText>
                                 </AmcText>
                              </vue-custom-scrollbar>
                              <AmcText v-if="allOnline.data.length < 1" class="alert alert-warning m-3">{{
                                 $t('dashboard.Şuan herhangi bir veri bulunmamaktadır')
                              }}</AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcCol>
                  </AmcRow>
               </AmcText>
               <NotificationModal ref="notificationModal" />
            </AmcText>

            <AmcText class="router-view m-3">
               <router-view />
            </AmcText>
            <AmcText>
               <Footer />
            </AmcText>
         </AmcText>
      </AmcText>
      <AmcLoading v-if="!this.loading"></AmcLoading>
      <AmcModal xl ref="sitePlan" class="img-modal">
         <template #header>
            <AmcText v-if="!item" class="h4 mx-auto pt-2"> {{ alarmBuilding }} </AmcText>
            <AmcText v-if="item" class="h4 mx-auto pt-2"> {{ get(item, 'building.short_name') }} </AmcText>
         </template>
         <AmcText v-if="alarmModal">
            <AmcImage v-if="!item" :src="alarmModal" class="m-2" />
         </AmcText>
         <AmcImage v-if="item" :src="get(item, 'building.site_plan')" class="m-2" />
         <audio ref="alarmSound">
            <source src="@/assets/media/alarm-sound.mp3" type="audio/mpeg" />
         </audio>
         <template #actions>
            <button @click="hidePlan" class="btn rounded-pill px-4 py-2 mx-auto fw-600 m-1 bg-light-primary">
               {{ $t('modalActions.Kapat') }}
            </button>
         </template>
      </AmcModal>
   </AmcText>
</template>

<script>
import get from 'lodash/get';
import isLength from 'lodash/isLength';

import Navbar from '@/views/Layout/Navbar';
import Footer from '@/views/Layout/Footer';
import Sidebar from '@/views/Layout/Sidebar';
import ErrorHandler from '@/libs/error-handler';
import NotificationModal from './NotificationModal';
import 'vue-custom-scrollbar/dist/vueScrollbar.css';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import OverviewStatusApi from '@/api/OverviewStatusApi';
import IconSearch from '@/components/icons/header/IconSearch';
import IconNotification from '@/components/icons/IconNotification';

export default {
   name: 'SlideBar',
   components: {
      NotificationModal,
      vueCustomScrollbar,
      IconNotification,
      Sidebar,
      Navbar,
      Footer,
   },
   data() {
      return {
         alarmModal: '',
         alarmBuilding: '',
         alarmOpen: false,
         autoRefresh: null,
         item: '',
         IconSearch,
         loading: false,
         searchAlarm: '',
         searchFault: '',
         searchOffline: '',
         searchOnline: '',
         allAlarm: [],
         allFault: [],
         allOffline: [],
         allOnline: [],
         headers: [
            { title: 'TESİS', value: 'building.short_name' },
            { title: 'MODEM ID', value: 'serial_number' },
            { title: 'PROJE', value: 'building.project.name' },
            { title: 'TARİH', value: 'last_seen_at' },
         ],
         settings: {
            suppressScrollY: false,
            suppressScrollX: false,
            wheelPropagation: false,
         },
      };
   },
   methods: {
      get,
      isLength,
      async getAlarm() {
         try {
            const response = await OverviewStatusApi.GET_ALARM();
            this.allAlarm = response;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getFault() {
         try {
            const response = await OverviewStatusApi.GET_FAULT();
            this.allFault = response;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getOffline() {
         try {
            const response = await OverviewStatusApi.GET_OFFLINE();
            this.allOffline = response;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getOnline() {
         try {
            const response = await OverviewStatusApi.GET_ONLINE();
            this.allOnline = response;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showModal(item) {
         this.$refs.notificationModal.show(item);
      },
      showPlan(item) {
         this.$refs.sitePlan.show(item);
         this.item = item;
         this.$refs.alarmSound.play();
      },
      hidePlan() {
         this.$refs.sitePlan.hide();
         this.$refs.alarmSound.pause();
         setTimeout(() => {
            this.item = null;
         }, 500);
      },
      refreshData() {
         this.autoRefresh = setInterval(() => {
            if (this.$route.path === '/') {
               this.getAlarm();
               this.getFault();
               this.getOffline();
               this.getOnline();
            }
         }, 10000);
      },
   },
   beforeDestroy() {
      clearInterval(this.autoRefresh);
   },
   watch: {
      allAlarm(newLength, oldLenght) {
         let timer = get(newLength.data[0], 'building.site_plan_show_duration');
         this.alarmModal = get(newLength.data[0], `building.site_plan`);
         this.alarmBuilding = get(newLength.data[0], 'building.short_name');

         if (get(newLength, 'data').length > get(oldLenght, 'data').length) {
            if (this.alarmModal) {
               this.$refs.sitePlan.show(this.allAlarm, this.alarmBuilding);
            }
            this.$refs.alarmSound.play();
            setTimeout(() => {
               this.$refs.sitePlan.hide();
               this.$refs.alarmSound.pause();
               this.alarmOpen = false;
            }, timer * 1000);
         }
      },
   },
   async beforeMount() {
      await this.getAlarm();
      await this.getFault();
      await this.getOffline();
      await this.getOnline();
      this.loading = true;
      this.refreshData();
   },
};
</script>

<style scoped lang="scss">
.table-header:first-child {
   padding-left: 50px;
}
.sidebar-container {
   width: 130px;
}
.content {
   padding-left: 130px;
}
.bg-alarm {
   background-color: #ea5348;
}
.bg-fault {
   background-color: #f5bd17;
}
.bg-offline {
   background-color: #929292;
}
.bg-online {
   background-color: #2fa84f;
}
.icon-notification {
   width: 50px;
   cursor: pointer;
   text-align: center;
}
.scroll {
   height: 250px;
   overflow: auto;
}
.img-modal {
   .modal-body {
      overflow-y: hidden !important;
      display: flex;
      justify-content: center;
      .amc-img {
         width: 100%;
         object-fit: contain;
      }
   }
}
</style>
