import Http from '../libs/http';

class ProjectsApi extends Http {
   index(id, params) {
      return super.get(`api/panel/projects${id ? '/' + id : ''}`, { params });
   }
   PROJECTS(params) {
      return super.get('api/panel/projects', { params });
   }

   EDIT_PROJECT(ID, payload) {
      return super.put(`api/panel/projects/${ID}`, payload);
   }

   CREATE_PROJECT(payload) {
      return super.post('api/panel/projects', payload);
   }

   REMOVE_PROJECT(ID) {
      return super.delete(`api/panel/projects/${ID}`);
   }
}

export default new ProjectsApi();
