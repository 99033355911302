<template>
   <AmcText v-on:keyup.enter="createdCountry">
      <AmcCard v-if="!loadData" shadow Card p3 class="mt-4 d-flex justify-content-between">
         <AmcText class="align-self-center" fwBold>{{ $t('definition.Yeni Lokasyon Tanımı') }} </AmcText>
         <AmcText class="d-flex">
            <AmcInput
               v-model="createCountry.name"
               width="300"
               py="2"
               secondary
               :placeholder="$t('placeholders.Yeni Lokasyon Ekle')"
            ></AmcInput>
            <AmcButton @click="createdCountry" class="text-white ms-3" :icon="IconAdded" ps="3" px="3" height="35px">{{
               $t('buttons.Yeni Lokasyon Ekle')
            }}</AmcButton>
         </AmcText>
      </AmcCard>
      <AmcCard v-if="!loadData" p3 Card shadow class="mt-4">
         <AmcText as="table" class="table-striped table-borderless table">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText
                     v-for="(col, index) in headers"
                     :key="index"
                     as="th"
                     class="text-secondary text-start table-header"
                  >
                     {{ $t(`tableTitle.${col.title}`) }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText as="tr" class="table-row" v-for="country in countries" :key="country.id">
                  <AmcText as="th" class="table-item input-name">
                     <input type="text" class="table-input form-control mt-2 fw-bold" v-model="country.name" />
                  </AmcText>
                  <AmcText as="th" class="table-item text-center">
                     <button class="mt-2" @click="showDetailLocation(country)"><IconEye /></button>
                     <button class="ms-3 mt-2" @click="showRemoveModal(country)"><IconDel /></button>
                     <button class="mt-2 ms-3" @click="editCountry(country)">
                        <IconEdit />
                     </button>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcCard>
      <AmcRemoveModal ref="deleteModal" @remove="removeCountry" />
      <LocationsDetailModal ref="detailLocation" />
   </AmcText>
</template>

<script>
import Form from '@/libs/form';
import Notify from '@/libs/notify';
import CountriesApi from '@/api/CountriesApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEye from '@/components/icons/IconEye';
import IconEdit from '@/components/icons/IconEdit';
import IconAdded from '@/components/icons/IconAdded';
import LocationsDetailModal from './_modals/LocationsDetailModal';

export default {
   name: 'Location',
   components: {
      IconDel,
      IconEye,
      IconEdit,
      LocationsDetailModal,
   },
   data() {
      return {
         item: {},
         IconAdded,
         countries: {},
         cities: {},
         createCountry: Form.create(['name']).validate({ name: 'required' }).defaults({ name: '' }),
         headers: [{ title: 'LOKASYONLAR' }],
         loadData: false,
      };
   },
   methods: {
      async loadCountries() {
         this.loadData = true;
         try {
            CountriesApi.index().then(({ data }) => (this.countries = data));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loadData = false;
         }
      },
      async createdCountry() {
         try {
            await CountriesApi.CREATE_COUNTRY(this.createCountry.toObject()).then(() => {
               this.loadCountries();
            });
            Notify.success(this.$t('notify.Lokasyon Eklendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.createCountry.name = '';
         }
      },
      async editCountry(item) {
         try {
            this.loadData = true;
            await CountriesApi.EDIT_COUNTRY(item.id, item).then(() => {
               this.loadCountries();
               this.loadData = false;
            });
            Notify.success(this.$t('notify.Lokasyon Güncellendi'));
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async removeCountry(item) {
         try {
            await CountriesApi.REMOVE_COUNTRY(item.id).then(() => {
               this.loadCountries();
               Notify.error(this.$t('notify.Lokasyon Silindi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },

      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
      showDetailLocation(item) {
         this.$refs.detailLocation.show(item);
      },
   },
   async beforeMount() {
      await this.loadCountries();
   },
};
</script>

<style scoped lang="scss">
.active-bar {
   height: 4px;
   background-color: #137af6;
   border-radius: 3px 3px 0 0;
   margin-top: 13px;
}
.item-text {
   padding-top: 25px;
   &:hover {
      color: #000000;
   }
}
.table {
   .table-header {
      &:nth-child(1) {
         padding-right: 400px;
         padding-left: 50px;
      }
   }
   --bs-table-striped-bg: rgba(244, 247, 252, 1);
   border-bottom: 1px solid #ebebeb;
   & .table-row:focus-within {
      border: 1px solid #137af6;
      --bs-table-striped-bg: #ffffff;
   }
   & .input-name {
      padding-left: 50px;
   }
   & .table-input {
      border: none !important;
      background-color: transparent;
      padding-left: 0 !important;
      &:focus {
         box-shadow: none;
      }
   }
}
.option-icon {
   position: absolute;
   top: 5px;
   right: 10px;
}
</style>
