import Http from '../libs/http';

class Cities extends Http {
   index(idCountry) {
      return super.get(`api/panel/countries/${idCountry}/cities`);
   }

   CREATE_CITY(idCountry, payload) {
      return super.post(`api/panel/countries/${idCountry}/cities`, payload);
   }

   EDIT_CITY(idCountry, idCity, payload) {
      return super.put(`api/panel/countries/${idCountry}/cities/${idCity}`, payload);
   }

   REMOVE_CITY(idCountry, idCity) {
      return super.delete(`api/panel/countries/${idCountry}/cities/${idCity}`);
   }
}

export default new Cities();
