<template>
   <AmcText>
      <AmcText class="rounded-8 shadow-sm p-3">
         <AmcText class="d-flex justify-content-between">
            <AmcText class="fs-3"> {{ $t('facilities.detail.modemler.Modemler') }}</AmcText>
            <AmcText v-if="!readOnly">
               <AmcButton @click="showCreateModal" class="text-white" :icon="IconAdded" ps="3" px="4" height="40px">
                  {{ $t('buttons.Yeni Modem Ekle') }}
               </AmcButton>
            </AmcText>
         </AmcText>
         <AmcText as="table" class="table-striped table-borderless table mt-4">
            <AmcText as="thead">
               <AmcText as="tr">
                  <AmcText
                     v-for="(col, index) in !readOnly ? headers : readonlyHeaders"
                     :key="index"
                     as="th"
                     class="text-uppercase text-secondary table-header"
                  >
                     {{ $t(`tableTitle.${col.title}`) }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText as="tbody">
               <AmcText as="tr" class="table-row" v-for="modem in buildingModem" :key="modem.id">
                  <AmcText as="td">
                     <AmcText class="text-secondary">
                        {{ get(modem, 'serial_number') }}
                     </AmcText>
                  </AmcText>
                  <AmcText as="td">
                     <AmcText class="fw-600">
                        {{ get(modem, 'device.name') }}
                     </AmcText>
                  </AmcText>
                  <AmcText as="td">
                     <AmcText class="text-secondary">
                        {{ get(modem, 'panel.name') }}
                     </AmcText>
                  </AmcText>
                  <AmcText as="td">
                     <AmcText class="text-secondary">
                        {{ get(modem, 'panel_model.name') }}
                     </AmcText>
                  </AmcText>
                  <AmcText as="td">
                     <AmcText class="text-secondary">
                        {{ moment(get(modem, 'last_seen_at')).format('DD-MM-YYYY HH:MM:SS') }}
                     </AmcText>
                  </AmcText>
                  <AmcText as="td" class="mx-auto">
                     <AmcText class="text-secondary text-center">
                        <AmcStatusIcon v-if="modem.sim_status === 'active'" text="Online" Online />
                        <AmcStatusIcon v-if="modem.sim_status === 'inactive'" text="Offline" Offline />
                     </AmcText>
                  </AmcText>
                  <AmcText as="td" class="text-center">
                     <AmcText class="text-secondary">
                        <AmcStatusIcon v-if="get(modem, 'last_event.alarm') === 'normal'" text="Normal" Normal />
                     </AmcText>
                  </AmcText>
                  <AmcText as="td" class="text-center" v-if="!readOnly">
                     <AmcText @click="showEditModal(modem)" as="button">
                        <IconEdit />
                     </AmcText>
                     <AmcText as="button" class="remove-icon ms-3" @click="showRemoveModal(modem)">
                        <IconDel />
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <!-- Modal Start -->
      <AmcModal ref="createModal" lg>
         <template #header>
            <AmcText class="h4 pt-3">{{ $t('facilities.detail.modemler.modal.Modem Ekle') }}</AmcText>
         </template>
         <template>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">Modem</AmcText>
               <select
                  v-model="dataModem"
                  class="form-select shadow-none border border-2 py-2"
                  aria-label="Default select example"
               >
                  <option :value="modem" v-for="(modem, index) in allProjectModem" :key="index">
                     {{ get(modem, 'serial_number') }}
                  </option>
               </select>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">Panel</AmcText>
               <select
                  v-model="modem.panel_id"
                  class="form-select shadow-none border border-2 py-2"
                  aria-label="Default select example"
                  @change="getModels"
               >
                  <option :value="panel.id" v-for="(panel, index) in allPanels" :key="index">
                     {{ get(panel, 'name') }}
                  </option>
               </select>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.modemler.modal.Marka') }}</AmcText>
               <select
                  v-model="modem.panel_model_id"
                  class="form-select shadow-none border border-2 py-2"
                  aria-label="Default select example"
               >
                  <option :value="model.id" v-for="(model, index) in allModels" :key="index">
                     {{ get(model, 'name') }}
                  </option>
               </select>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.modemler.modal.Zone Adet') }}</AmcText>
               <input v-model="modem.total_zone" type="number" class="form-control shadow-none" />
            </AmcText>
            <AmcText class="mb-3 row">
               <AmcText
                  class="d-flex justify-content-between col-md-6 mt-2"
                  v-for="(zone, index) in generatedArr"
                  :key="index"
               >
                  <AmcText class="align-self-center text-secondary fw-600 me-2">ZONE{{ zone }}:</AmcText>
                  <input v-model="modem.zones[index]" type="text" class="form-control shadow-none" />
               </AmcText>
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{
                  $t('facilities.detail.modemler.modal.Çevrimdışı Zaman Aşımı (Saniye)')
               }}</AmcText>
               <input v-model="modem.heartbit_timeout" type="number" class="form-control shadow-none" />
            </AmcText>
            <AmcText class="mb-3">
               <AmcText class="form-label fw-600">{{ $t('facilities.detail.modemler.modal.Eleman Adeti') }}</AmcText>
               <input v-model="modem.total_devices" type="number" class="form-control shadow-none" />
            </AmcText>
            <AmcText class="pt-4">
               <AmcText class="row">
                  <AmcText class="col d-flex justify-content-between">
                     <AmcText class="fw-600 mb-3 align-self-center">{{
                        $t('facilities.detail.modemler.modal.Kontaklar')
                     }}</AmcText>
                     <AmcText>
                        <AmcText
                           class="d-flex gap-4 mb-3 align-self-center"
                           :key="i"
                           v-for="(contact, i) in dataModem.contacts"
                        >
                           <AmcText class="d-flex">
                              <input
                                 v-model="contact.role"
                                 :id="`${i}-alarm`"
                                 value="alarm"
                                 type="radio"
                                 class="checkbox"
                              />
                              <AmcText
                                 as="label"
                                 :for="`${i}-alarm`"
                                 class="ms-2"
                                 :class="{ 'text-primary': contact.role === 'alarm' }"
                                 >Alarm
                              </AmcText>
                           </AmcText>
                           <AmcText class="d-flex">
                              <input
                                 v-model="contact.role"
                                 :id="`${i}-fault`"
                                 value="fault"
                                 type="radio"
                                 class="checkbox"
                              />
                              <AmcText
                                 as="label"
                                 :for="`${i}-fault`"
                                 class="ms-2"
                                 :class="{ 'text-primary': contact.role === 'fault' }"
                                 >Fault
                              </AmcText>
                           </AmcText>
                        </AmcText>
                     </AmcText>
                  </AmcText>

                  <AmcText class="col d-flex justify-content-center">
                     <AmcText class="d-flex align-self-center">
                        <label
                           :class="{ 'text-secondary': !modem.is_addressable }"
                           class="form-check-label fw-600 me-3"
                           for="switches2"
                           >{{ $t('facilities.detail.modemler.modal.Adresli') }}</label
                        >
                        <AmcText class="form-check form-switch">
                           <input
                              v-model="modem.is_addressable"
                              class="form-check-input"
                              type="checkbox"
                              id="switches2"
                           />
                        </AmcText>
                     </AmcText>
                     <AmcText class="d-flex align-self-center ms-4">
                        <label
                           :class="{ 'text-secondary': !modem.extension_enabled }"
                           class="form-check-label fw-600 me-3"
                           for="switches1"
                           >Extension Unit</label
                        >
                        <AmcText class="form-check form-switch">
                           <input
                              v-model="modem.extension_enabled"
                              class="form-check-input"
                              type="checkbox"
                              id="switches1"
                           />
                        </AmcText>
                     </AmcText>
                  </AmcText>
               </AmcText>
            </AmcText>
         </template>
         <template #actions>
            <button
               @click="reset"
               type="button"
               class="btn bg-transparent text-black border px-5 py-2 me-2"
               data-bs-dismiss="modal"
            >
               {{ $t('modalActions.Vazgeç') }}
            </button>
            <button @click="createModem" data-bs-dismiss="modal" class="btn btn-primary px-5 py-2">
               {{ $t('modalActions.Kaydet') }}
            </button>
         </template>
      </AmcModal>
      <!-- Modal Finish -->
      <!-- Remove Modal -->
      <AmcRemoveModal ref="deleteModal" @remove="removeModem" />
      <!-- Remove Modal -->
      <!-- Modal Start -->
      <ModemEditModal ref="editModal" @edit="editModem" />
      <!-- Modal Finish -->
      <AmcLoading v-if="loading"></AmcLoading>
   </AmcText>
</template>

<script>
import get from 'lodash/get';
import range from 'lodash/range';

import Form from '@/libs/form';
import Notify from '@/libs/notify';
import ModemApi from '@/api/ModemApi';
import PanelsApi from '@/api/PanelsApi';
import ModelsApi from '@/api/ModelsApi';
import BuildingApi from '@/api/BuildingApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import IconEdit from '@/components/icons/IconEdit';
import ProjectModemApi from '@/api/ProjectModemApi';
import IconAdded from '@/components/icons/IconAdded';
import ModemEditModal from './_modals/ModemEditModal';

export default {
   name: 'DetailModems',
   components: {
      IconEdit,
      IconDel,
      ModemEditModal,
   },
   data() {
      return {
         loading: false,
         buildingModem: [],
         projectID: {},
         dataModem: {},
         allProjectModem: [],
         allPanels: [],
         allModels: [],
         numberContactIn: null,
         IconAdded,
         alarm: null,
         fault: null,
         address: null,
         extension: null,
         readOnly: false,
         headers: [
            { title: 'ID' },
            { title: 'MODEM TÜRÜ' },
            { title: 'PANEL' },
            { title: 'MODEL' },
            { title: 'SON GÖRÜLME' },
            { title: 'DURUM' },
            { title: 'OLAY' },
            { title: 'DÜZENLE' },
         ],
         readonlyHeaders: [
            { title: 'ID' },
            { title: 'MODEM TÜRÜ' },
            { title: 'PANEL' },
            { title: 'MODEL' },
            { title: 'SON GÖRÜLME' },
            { title: 'DURUM' },
            { title: 'OLAY' },
         ],
         showMenu: false,
         modem: Form.create([
            'serial_number',
            'panel_id',
            'panel_model_id',
            'total_devices',
            'total_zone',
            'zones',
            'heartbit_timeout',
            'contacts',
            'is_addressable',
            'extension_enabled',
         ]).defaults({
            serial_number: '',
            panel_id: '',
            panel_model_id: '',
            total_devices: '',
            total_zone: '',
            zones: [],
            contacts: '',
            heartbit_timeout: '',
            is_addressable: false,
            extension_enabled: false,
         }),
      };
   },
   computed: {
      buildingId() {
         return this.$route.params.id;
      },
      generatedArr() {
         const tmp = [];
         for (let i = 1; i <= this.modem.total_zone; i++) {
            tmp.push(i);
         }
         return tmp;
      },
   },
   methods: {
      range,
      get,
      async getBuildingProjectId() {
         this.loading = true;
         try {
            const result = await BuildingApi.GET_BUILDING(this.buildingId);
            this.projectID = result.data.project_id;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loading = false;
         }
      },
      async getProjectModem() {
         try {
            const response = await ProjectModemApi.index(this.projectID);
            this.allProjectModem = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getModem() {
         try {
            const result = await ModemApi.index(this.buildingId);
            this.buildingModem = result.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getPanels() {
         try {
            const response = await PanelsApi.index();
            this.allPanels = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getModels() {
         const response = await ModelsApi.index(this.modem.panel_id);
         this.allModels = response.data;
      },
      async createModem() {
         this.modem.serial_number = this.dataModem.serial_number;
         this.modem.zones = this.modem.zones.map((zoneLabel, index) => {
            if (zoneLabel) {
               return {
                  index: index,
                  label: zoneLabel,
               };
            } else {
               return {
                  index: index,
                  label: null,
               };
            }
         });
         this.loading = true;
         try {
            this.modem.contacts = this.dataModem.contacts;
            await ModemApi.CREATE_MODEM(this.buildingId, this.modem.toObject());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.reset();
            this.loading = false;
            await this.getModem();
         }
      },
      async editModem(item) {
         try {
            await ModemApi.EDIT_MODEM(this.buildingId, item.id, item).then(() => {
               Notify.success(this.$t('notify.Modem Bilgileri Güncellendi'));
            });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.loading = false;
            await this.getModem();
            this.reset();
         }
      },
      async removeModem(item) {
         try {
            await ModemApi.REMOVE_MODEM(this.buildingId, item.id);
            Notify.error(this.$t('notify.Modem Silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            await this.getModem();
         }
      },
      reset() {
         this.modem.reset();
      },
      showCreateModal() {
         this.$refs.createModal.show();
         this.getProjectModem();
         this.getPanels();
      },
      showEditModal(item) {
         this.$refs.editModal.show(item);
         this.getModem();
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   async beforeMount() {
      this.readOnly = this.$store.state.auth.authUser.read_only;
      await this.getModem();
      await this.getBuildingProjectId();
   },
};
</script>

<style scoped lang="scss">
.table-header {
   &:nth-child(6) {
      text-align: center;
   }

   &:nth-child(7) {
      text-align: center;
   }

   &:nth-child(8) {
      text-align: center;
   }
}

.more-menu {
   box-shadow: 0 0 10px #ececec;
   border: 1px solid #efeff8;
   background-color: #ffffff;
   position: absolute;
   border-radius: 5px;
   z-index: 999;
   padding: 10px;
   right: 6%;
}
</style>
