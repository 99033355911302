<template>
   <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="47" cy="47" r="45.5" stroke="#2F71F2" stroke-width="3" />
      <path
         d="M68.2352 29L41.7646 59"
         stroke="#2F71F2"
         stroke-width="3"
         stroke-miterlimit="10"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path
         d="M25 45.7647L41.7647 59"
         stroke="#2F71F2"
         stroke-width="3"
         stroke-miterlimit="10"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconSuccess',
};
</script>
