<template>
   <AmcModal ref="modal" lg>
      <template #header>
         <AmcText class="h4 pt-3">{{ $t('facilities.detail.modemler.modal.Modem Düzenle') }}</AmcText>
      </template>
      <template>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600">Modem</AmcText>
            <input v-model="item.serial_number" disabled type="text" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600">Panel</AmcText>
            <select
               v-model="item.panel_id"
               class="form-select shadow-none border border-2 py-2"
               aria-label="Default select example"
               @change="getModels"
            >
               <option :value="panel.id" v-for="(panel, index) in allPanels" :key="index">
                  {{ get(panel, 'name') }}
               </option>
            </select>
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600">{{ $t('facilities.detail.modemler.modal.Marka') }}</AmcText>
            <select
               v-model="item.panel_model_id"
               class="form-select shadow-none border border-2 py-2"
               aria-label="Default select example"
            >
               <option :value="model.id" v-for="(model, index) in allModels" :key="index">
                  {{ get(model, 'name') }}
               </option>
            </select>
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600">{{ $t('facilities.detail.modemler.modal.Zone Adet') }}</AmcText>
            <input v-model="item.total_zone" type="number" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3 row">
            <AmcText
               class="d-flex justify-content-between col-md-6 mt-2"
               v-for="(zone, index) in item.zones"
               :key="index"
            >
               <AmcText class="align-self-center text-secondary fw-600 me-2">ZONE{{ index + 1 }}:</AmcText>
               <input type="text" class="form-control shadow-none" v-model="zone.label" />
            </AmcText>
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600">{{
               $t('facilities.detail.modemler.modal.Çevrimdışı Zaman Aşımı (Saniye)')
            }}</AmcText>
            <input v-model="item.heartbit_timeout" type="number" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="mb-3">
            <AmcText class="form-label fw-600">{{ $t('facilities.detail.modemler.modal.Eleman Adeti') }}</AmcText>
            <input v-model="item.total_devices" type="number" class="form-control shadow-none" />
         </AmcText>
         <AmcText class="pt-4 d-flex justify-content-between">
            <AmcText class="fw-600 align-self-center"> {{ $t('facilities.detail.modemler.modal.Kontaklar') }} </AmcText>
            <AmcText>
               <AmcText class="d-flex gap-4 mb-3" :key="i" v-for="(contact, i) in item.contacts">
                  <AmcText class="d-flex">
                     <input v-model="contact.role" :id="`${i}-alarm`" value="alarm" type="radio" class="checkbox" />
                     <AmcText
                        as="label"
                        :for="`${i}-alarm`"
                        class="ms-2"
                        :class="{ 'text-primary': contact.role === 'alarm' }"
                        >Alarm</AmcText
                     >
                  </AmcText>
                  <AmcText class="d-flex">
                     <input v-model="contact.role" :id="`${i}-fault`" value="fault" type="radio" class="checkbox" />
                     <AmcText
                        as="label"
                        :for="`${i}-fault`"
                        class="ms-2"
                        :class="{ 'text-primary': contact.role === 'fault' }"
                        >Fault</AmcText
                     >
                  </AmcText>
                  <input type="text" class="form-control shadow-none" v-model="contact.label" />
               </AmcText>
            </AmcText>
            <AmcText class="d-flex align-self-center">
               <label
                  :class="{ 'text-secondary': !item.is_addressable }"
                  class="form-check-label fw-600 me-3"
                  for="switches3"
                  >{{ $t('facilities.detail.modemler.modal.Adresli') }}</label
               >
               <AmcText class="form-check form-switch">
                  <input v-model="item.is_addressable" class="form-check-input" type="checkbox" id="switches3" />
               </AmcText>
            </AmcText>
            <AmcText class="d-flex align-self-center">
               <label
                  :class="{ 'text-secondary': !item.extension_enabled }"
                  class="form-check-label fw-600 me-3"
                  for="switches4"
                  >Extension Unit</label
               >
               <AmcText class="form-check form-switch">
                  <input v-model="item.extension_enabled" class="form-check-input" type="checkbox" id="switches4" />
               </AmcText>
            </AmcText>
         </AmcText>
      </template>
      <template #actions>
         <button type="button" class="btn bg-transparent text-black border px-5 py-2 me-2" data-bs-dismiss="modal">
            {{ $t('modalActions.Vazgeç') }}
         </button>
         <button @click="$emit('edit', item)" data-bs-dismiss="modal" class="btn btn-primary px-5 py-2">
            {{ $t('modalActions.Kaydet') }}
         </button>
      </template>
   </AmcModal>
</template>

<script>
import get from 'lodash/get';

import ModelsApi from '@/api/ModelsApi';
import PanelsApi from '@/api/PanelsApi';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'ModemEditModal',
   data() {
      return {
         item: {
            panel: {},
            panel_model: {},
         },
         allModels: {},
         allPanels: {},
         contactsOption: [
            { value: 'alarm', text: 'Alarm' },
            { value: 'fault', text: 'Fault' },
         ],
      };
   },
   methods: {
      get,
      async getPanels() {
         try {
            const response = await PanelsApi.index();
            this.allPanels = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getModels() {
         const response = await ModelsApi.index(this.item.panel_id);
         this.allModels = response.data;
      },
      show(item) {
         this.item = item;
         this.$refs.modal.show();
         this.getModels();
         this.getPanels();
      },
   },
};
</script>

<style scoped></style>
