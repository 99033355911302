<template>
   <AmcText>
      <AmcText class="d-flex flex-row justify-content-between">
         <AmcText fsXlarge> {{ $t('logs.Sistem Logları') }} </AmcText>
         <AmcStateButton
            @filterChanged="filterChanged"
            :filters="currentFilter"
            :fire="dataCount.fire"
            :fault="dataCount.fault"
            :online="dataCount.connected"
            :info="dataCount.info"
            :offline="dataCount.disconnected"
         />
      </AmcText>
      <AmcText class="rounded-8 shadow-sm p-3 mt-4">
         <!-- Table Header Start -->
         <AmcText class="table-header">
            <AmcText class="pb-3">
               <AmcText class="d-flex justify-content-between">
                  <AmcText class="d-flex">
                     <select
                        class="form-select shadow-none"
                        aria-label="Default select example"
                        v-model="projectID"
                        @change="loadBuilding"
                     >
                        <option selected value="">{{ $t('dropdowns.Tümü') }}</option>
                        <option :key="index" v-for="(project, index) in projectsData" :value="project.id">
                           {{ get(project, 'name') }}
                        </option>
                     </select>
                     <select
                        class="form-select shadow-none ms-3"
                        aria-label="Default select example"
                        v-model="buildingID"
                     >
                        <option selected value="">{{ $t('dropdowns.Tümü') }}</option>
                        <option :key="index" v-for="(building, index) in buildingData.data" :value="building.id">
                           {{ get(building, 'name') }}
                        </option>
                     </select>
                     <select class="form-select shadow-none ms-3" aria-label="Default select example" v-model="period">
                        <option value="day">{{ $t('dropdowns.Günlük') }}</option>
                        <option value="week">{{ $t('dropdowns.Haftalık') }}</option>
                        <option value="month">{{ $t('dropdowns.Aylık') }}</option>
                        <option value="year">{{ $t('dropdowns.Yıllık') }}</option>
                     </select>
                  </AmcText>
                  <AmcText>
                     <button class="btn btn-primary px-3" @click="filter">{{ $t('buttons.Filtrele') }}</button>
                  </AmcText>
               </AmcText>
            </AmcText>
         </AmcText>
         <!-- Table Header Finish -->

         <AmcTable hover striped :data="dataLogs" :header="headers" @pageChanged="loadLogs" @perPageChanged="loadLogs">
            <template #date="{ item }">
               <popper
                  trigger="hover"
                  :options="{
                     placement: 'top',
                     modifiers: { offset: { offset: '10px' } },
                  }"
               >
                  <AmcText class="popper shadow-sm p-3 rounded-8">
                     {{ moment(get(item, 'created_at')).format('DD-MM-YYYY HH:MM:SS') }}
                  </AmcText>

                  <button slot="reference">
                     {{ moment(get(item, 'created_at')).locale(lang).fromNow() }}
                  </button>
               </popper>
            </template>
            <template #alarm="{ prop }">
               <span v-if="prop === 'normal'">
                  <AmcText class="alert-primary fs-10 py-1 rounded-pill text-center">
                     {{ $t('situations.NORMAL') }}
                  </AmcText>
               </span>
               <span v-if="prop === 'alarm'">
                  <AmcText class="alert-danger alert-primary fs-10 py-1 rounded-pill text-center">{{
                     $t('situations.ALARM')
                  }}</AmcText>
               </span>
            </template>
            <template #fault="{ prop }">
               <span v-if="prop === 'normal'">
                  <AmcText class="alert-primary fs-10 py-1 rounded-pill text-center">{{
                     $t('situations.NORMAL')
                  }}</AmcText>
               </span>
               <span v-else-if="prop === 'fault'">
                  <AmcText class="alert-warning alert-primary fs-10 py-1 rounded-pill text-center">{{
                     $t('situations.ARIZA')
                  }}</AmcText>
               </span>
            </template>
            <template #status="{ prop }">
               <span v-if="prop === 'normal'">
                  <AmcText class="alert-primary fs-10 py-1 rounded-pill border-primary text-center">{{
                     $t('situations.NORMAL')
                  }}</AmcText>
               </span>
               <span v-else-if="prop === 'fault'">
                  <AmcText class="alert-warning alert-primary fs-10 py-1 rounded-pill border-primary text-center">{{
                     $t('situations.ARIZA')
                  }}</AmcText>
               </span>
               <span v-else-if="prop === 'connected'">
                  <AmcText class="alert-success alert-primary fs-10 py-1 rounded-pill border-primary text-center">{{
                     $t('situations.ÇEVRİMİÇİ')
                  }}</AmcText>
               </span>
               <span v-else-if="prop === 'disconnected'">
                  <AmcText class="alert-secondary alert-primary fs-10 py-1 rounded-pill border-primary text-center">{{
                     $t('situations.ÇEVRİMDIŞI')
                  }}</AmcText>
               </span>
               <span v-else-if="prop === 'fire'">
                  <AmcText class="alert-danger alert-primary fs-10 py-1 rounded-pill border-primary text-center">{{
                     $t('situations.YANGIN')
                  }}</AmcText>
               </span>
               <span v-else-if="prop === 'info'">
                  <AmcText class="alert-info alert-primary fs-10 py-1 rounded-pill border-primary text-center">{{
                     $t('situations.BİLGİ')
                  }}</AmcText>
               </span>
            </template>
            <template #panel="{ item }">
               <popper
                  v-if="
                     get(item, 'panel_data') ||
                     get(item, 'panel_node') ||
                     get(item, 'panel_loop') ||
                     get(item, 'panel_address') ||
                     get(item, 'panel_zone') !== null
                  "
                  trigger="hover"
                  :options="{
                     placement: 'left',
                     modifiers: { offset: { offset: '10px' } },
                  }"
               >
                  <AmcText class="popper shadow-sm p-0 rounded-8 w-25">
                     <AmcText class="border-bottom bg-light-primary">
                        <AmcText class="p-3 text-primary fs-5">Panel Data</AmcText>
                     </AmcText>
                     <AmcText class="p-2">
                        <AmcText class="fw-500 text-start py-2">
                           Data : <span class="text-secondary">{{ get(item, 'panel_data') }}</span>
                        </AmcText>
                        <AmcText class="fw-500 text-start py-2">
                           Node : <span class="text-secondary">{{ get(item, 'panel_node') }}</span>
                        </AmcText>
                        <AmcText class="fw-500 text-start py-2">
                           Loop : <span class="text-secondary">{{ get(item, 'panel_loop') }}</span>
                        </AmcText>
                        <AmcText class="fw-500 text-start py-2"> Adres : {{ get(item, 'panel_address') }} </AmcText>
                        <AmcText class="fw-500 text-start py-2">
                           Zone : <span class="text-secondary">{{ get(item, 'panel_zone') }}</span>
                        </AmcText>
                     </AmcText>
                  </AmcText>

                  <button slot="reference">
                     <IconEye />
                  </button>
               </popper>
            </template>
         </AmcTable>
      </AmcText>
      <AmcLoading v-if="stateLoading" />
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import LogApi from '@/api/LogApi';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import BuildingApi from '@/api/BuildingApi';
import ProjectsApi from '@/api/ProjectsApi';
import ErrorHandler from '@/libs/error-handler';
import IconEye from '@/components/icons/IconEye';

export default {
   name: 'Logs',
   components: {
      Popper,
      IconEye,
   },
   data() {
      return {
         lang: localStorage.getItem('lang') || 'tr',
         refreshLogs: null,
         headers: [
            { title: 'PROJE', value: 'building.project.name' },
            { title: 'TESİS', value: 'building.name' },
            { title: 'MODEM', value: 'serial_number' },
            { title: 'TARİH', value: 'date' },
            { title: 'ALARM', value: 'alarm' },
            { title: 'ARIZA', value: 'fault' },
            { title: 'OLAY', value: 'status' },
            { title: 'PANEL', value: 'panel' },
         ],
         dataLogs: {},
         dataCount: {},
         faultCount: 0,
         onlineCount: 0,
         offlineCount: 0,
         stateLoading: false,
         period: 'day',
         projectsData: [{ project: {} }],
         projectID: null,
         buildingData: [],
         buildingID: null,
         query: {
            page: get(this.$route.query, 'page', 1),
            per_page: get(this.$route.query, 'perPage', 10),
            fire: get(this.$route.query, 'fire', 1),
            fault: get(this.$route.query, 'fault', 1),
            info: get(this.$route.query, 'info', 1),
            is_connected: get(this.$route.query, 'is_connected', 1),
            is_disconnected: get(this.$route.query, 'is_disconnected', 1),
         },
      };
   },
   computed: {
      currentFilter() {
         return this.query;
      },
   },
   methods: {
      get,
      // eslint-disable-next-line
      async loadLogs() {
         const params = this.query;

         if (!params) return;

         try {
            params.page = get(this.$route.query, 'page', 1);
            params.per_page = get(this.$route.query, 'perPage', 10);
            params.count = null;
            params.project_id = this.projectID;
            params.building_id = this.buildingID;
            params.period = this.period;
            this.$router.push({ query: { ...this.$route.query, ...params } }).catch(() => {});

            let response = await LogApi.index(params);
            this.dataLogs = response;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadProjects() {
         try {
            const response = await ProjectsApi.index();
            this.projectsData = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadBuilding() {
         try {
            const response = await BuildingApi.index({ project_id: this.projectID });
            this.buildingData = response;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async loadStats() {
         const params = this.query;

         if (!params) return;

         try {
            this.dataCount = {};
            params.count = true;
            params.project_id = this.projectID;
            params.building_id = this.buildingID;
            params.period = this.period;
            this.dataCount = await LogApi.index(params);
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async filterChanged(filter, newValue) {
         this.query[filter] = newValue;
      },
      async filter() {
         await this.filterChanged();
         await this.loadStats();
         await this.loadLogs();
         await this.$router.push({ query: { ...this.$route.query, page: '1' } }).catch(() => {});
      },
      refreshData() {
         this.refreshLogs = setInterval(() => {
            if (this.$route.path === '/logs') {
               this.loadLogs();
               this.loadStats();
            }
         }, 30000);
      },
   },
   beforeDestroy() {
      clearInterval(this.refreshLogs);
   },
   async beforeMount() {
      await this.loadStats();
      await this.loadLogs();
      await this.loadProjects();
      this.refreshData();
   },
};
</script>

<style scoped lang="scss">
.panel-data {
   width: 6%;
}
.select-per-page {
   border: none !important;
   padding-top: 0;
   color: #606f89;
   font-weight: 500;

   &:focus {
      box-shadow: none !important;
   }
}
.center-item {
   &:nth-child(5) {
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      text-align: center !important;
   }

   &:nth-child(6) {
      border-right: 1px solid #ebebeb;
      text-align: center !important;
   }

   &:nth-child(7) {
      border-right: 1px solid #ebebeb;
      text-align: center !important;
   }
   &:nth-child(8) {
      border-right: 1px solid #ebebeb;
      text-align: center !important;
   }
}
</style>
