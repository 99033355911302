<template>
   <div class="navbar sticky-top bg-white">
      <ul class="pt-2 navbar-nav ps-5 d-flex flex-row">
         <li class="nav-item pt-3">
            <div class="position-relative">
               <IconLoginUser />
            </div>
         </li>
         <li class="nav-item pt-3 ms-3 position-relative">
            <p class="m-0 text-secondary fw-500">{{ $t('navbar.Hoşgeldiniz!') }}</p>
            <AmcText class="text-primary p-0 fw-500"> {{ this.$store.state.auth.authUser.name }} </AmcText>
         </li>
      </ul>
      <ul class="pt-2 navbar-nav d-flex flex-row pe-5 align-self-center pt-3">
         <li class="nav-item ms-4">
            <AmcButton @click="logout" class="text-white" px="3" height="37px">
               {{ $t('navbar.Çıkış Yap') }}
            </AmcButton>
         </li>
         <router-link to="/settings" as="li" class="nav-item ms-4">
            <button class="btn btn-light text-white fw-500 rounded-circle shadow p-2">
               <IconSettings />
            </button>
         </router-link>
         <li class="nav-item ms-3 align-self-center">
            <AmcSwitchLang />
         </li>
      </ul>
   </div>
</template>

<script>
import IconSearch from '@/components/icons/header/IconSearch';
import IconSettings from '@/components/icons/header/IconSettings';
import IconLoginUser from '@/components/icons/header/IconLoginUser';

export default {
   me: {},
   name: 'Toolbar',
   components: {
      IconLoginUser,
      IconSettings,
   },
   data() {
      return {
         search: '',
         IconSearch,
      };
   },
   methods: {
      logout() {
         this.$store.dispatch('auth/logout', { redirectRoute: 'Login' });
      },
   },
};
</script>

<style lang="scss" scoped>
.navbar {
   border-bottom: 2px solid #e2e2e2;
   padding-bottom: 25px;

   & .form-select {
      border: 1px solid #6c757d;
      &:focus {
         box-shadow: none;
      }
   }

   & .dropdown-menu {
      border: none;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      border-radius: 0 0 10px 10px;
      border-top: 3px solid #0d6efd;
   }

   & .nav-item-m:hover,
   .nav-item-m:focus {
      color: #212121;

      .nav-item-border {
         position: absolute;
         height: 4px;
         width: 45px;
         background: #137af6;
         bottom: -25px;
         border-radius: 2px 2px 0 0;
      }
   }
}
</style>
