<template>
   <AmcText class="pe-3">
      <AmcText class="fs-3 shadow rounded-8 p-4">{{ $t('facilities.detail.dosyalar.Dosyalar') }}</AmcText>
      <AmcText class="d-flex">
         <AmcText class="files">
            <AmcText class="text-center border-bottom py-3">
               <AmcText class="bg-light-primary rounded-pill mx-5">
                  <AmcText class="rounded-pill px-4 py-2 fw-600">
                     {{ $t('facilities.detail.dosyalar.Dosyalar') }}
                  </AmcText>
               </AmcText>
            </AmcText>
            <AmcText class="d-flex flex-wrap justify-content-center">
               <AmcText
                  class="file-thumbnail mb-3"
                  v-for="(img, index) in get(dataUsing, 'images')"
                  :key="`img-${get(dataUsing, 'images')}-${index}`"
               >
                  <AmcImage class="pointer m-2" thumbnail @click="showImgModal(img.src)" :src="img.thumbnail" />
                  <button class="remove-icon" @click="showRemoveModal(img.id)"><IconDel /></button>
               </AmcText>
               <AmcModal xl ref="imgModal" class="img-modal">
                  <AmcImage :src="modalImgSrc" class="m-2" />
                  <template #actions>
                     <button
                        @click="$refs.imgModal.hide()"
                        class="btn rounded-pill px-4 py-2 mx-auto fw-600 m-1 bg-light-primary"
                     >
                        {{ $t('modalActions.Kapat') }}
                     </button>
                  </template>
               </AmcModal>
               <AmcRemoveModal ref="deleteModal" @remove="removeFile" />
               <AmcText v-if="[...get(dataUsing, 'images')].length < 1" class="text-center my-5">
                  {{ $t('facilities.detail.dosyalar.Henüz hiçbir şey yüklenmedi') }}.
               </AmcText>
            </AmcText>
         </AmcText>
         <AmcText class="upload-file-card" v-if="!readOnly">
            <file-pond
               name="fileUpload"
               ref="pond"
               :label-idle="$t('facilities.detail.dosyalar.Dosyaları buraya sürükleyin')"
               accepted-file-types="image/jpeg, image/png"
               v-bind:allow-multiple="true"
               v-on:init="handleFilePondInit"
            />
            <AmcText v-if="files.length > 0" class="text-center">
               <button
                  :disabled="files.length < 1"
                  @click="saveFiles()"
                  class="btn rounded-pill px-4 py-2 fw-600 m-1 bg-light-primary"
               >
                  <span v-if="uploading" class="spinner-border" />
                  <AmcText v-if="!uploading"> {{ $t('modalActions.Kaydet') }} </AmcText>
               </button>
            </AmcText>
         </AmcText>
      </AmcText>
   </AmcText>
</template>

<script>
import get from 'lodash/get';

import vueFilePond from 'vue-filepond';
import BuildingApi from '@/api/BuildingApi';
import ErrorHandler from '@/libs/error-handler';
import IconDel from '@/components/icons/IconDel';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

import 'filepond/dist/filepond.min.css';
export default {
   name: 'Photos',
   data() {
      return {
         dataUsing: {},
         uploading: false,
         files: [],
         modalImgSrc: null,
         readOnly: false,
      };
   },
   components: {
      FilePond,
      IconDel,
   },
   methods: {
      get,
      handleFilePondInit() {
         const pond = this.$refs.pond;
         let files = pond.getFiles();
         this.files = files;

         document.addEventListener('FilePond:addfile', () => {
            files = pond.getFiles();
            this.files = files;
         });

         document.addEventListener('FilePond:removefile', () => {
            files = pond.getFiles();
            this.files = files;
         });
      },
      async getBuilding() {
         try {
            const result = await BuildingApi.GET_BUILDING(this.$route.params.id);
            this.dataUsing = result.data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async saveFiles() {
         const pond = this.$refs.pond;
         const files = this.files;
         this.uploading = true;
         try {
            await Promise.all(
               files.map(({ file }) => {
                  return BuildingApi.FILE_UPLOAD(this.$route.params.id, { file });
               })
            );
            pond.removeFiles();
            await this.getBuilding();
         } catch (err) {
            ErrorHandler.handle(err);
         }
         this.uploading = false;
      },
      async removeFile(fileID) {
         try {
            await BuildingApi.REMOVE_FILE(this.$route.params.id, fileID);
            await this.getBuilding();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      showImgModal(imgSrc) {
         this.modalImgSrc = imgSrc;
         this.$refs.imgModal.show();
      },
      showRemoveModal(item) {
         this.$refs.deleteModal.show(item);
      },
   },
   async beforeMount() {
      this.readOnly = this.$store.state.auth.authUser.read_only;
      await this.getBuilding();
   },
};
</script>

<style lang="scss">
.filepond--credits {
   display: none !important;
}

.img-modal {
   .modal-body {
      overflow-y: hidden !important;
      display: flex;
      justify-content: center;
      .amc-img {
         width: 100%;
         object-fit: contain;
      }
   }
}
</style>

<style scoped lang="scss">
.files {
   margin-top: 30px;
   width: 65%;
   box-shadow: 0 0 8px rgba(165, 165, 165, 0.15);
   border-radius: 10px;
   background-color: #ffffff;
}
.upload-file-card {
   width: 35%;
   margin-top: 30px;
   margin-left: 20px;
   & .upload-area {
      background-color: rgba(229, 239, 255, 0.38);
      border-radius: 20px;
      height: 324px;
      & .upload-are-line {
         border: 3px dashed #6c757d;
         height: 100%;
         border-radius: 20px;
      }
   }
}
.file-thumbnail {
   display: flex;
   flex-direction: column;
   img {
      width: 150px;
      height: 150px;
      object-fit: contain;
      object-position: center;
   }
}
</style>
