import Http from '../libs/http';

class LegalApi extends Http {
   index(ID) {
      return super.get(`api/panel/building/${ID}/legal`);
   }

   CREATE_LEGAL(ID, payload) {
      return super.post(`api/panel/building/${ID}/legal`, payload);
   }

   EDIT_LEGAL(ID, id, payload) {
      return super.put(`api/panel/building/${ID}/legal/${id}`, payload);
   }

   REMOVE_LEGAL(ID, id) {
      return super.delete(`api/panel/building/${ID}/legal/${id}`);
   }
}

export default new LegalApi();
