import Http from '../libs/http';

class BuildingApi extends Http {
   create(payload) {
      return super.post('api/panel/building', payload);
   }

   index(params) {
      return super.get('api/panel/building', { params });
   }

   GET_BUILDING(ID) {
      return super.get(`api/panel/building/${ID}`);
   }

   EDIT_BUILDING(ID, payload) {
      return super.put(`api/panel/building/${ID}`, payload);
   }

   REMOVE_BUILDING(ID) {
      return super.delete(`api/panel/building/${ID}`);
   }

   FILE_UPLOAD(ID, payload, collection = 'building') {
      return super.post(`api/panel/building/${ID}/images?collection=${collection}`, payload);
   }

   REMOVE_FILE(ID, payload) {
      return super.delete(`api/panel/building/${ID}/images/${payload}`);
   }
}

export default new BuildingApi();
