<template>
   <AmcModal lg ref="modal">
      <template #header class="d-flex">
         <AmcText inline class="text-black h4 py-3" fwBold> {{ $t('facilities.modal.Tesis Ekle') }} </AmcText>
         <AmcText class="d-flex">
            <AmcText class="d-flex">
               <AmcText :class="{ 'bg-primary': step === 1 || 2 || 3 }" class="step-number align-self-center">
                  <span :class="{ 'text-white': step !== 1 || 2 || 3 }" class="d-flex align-self-center">1</span>
               </AmcText>
               <AmcText :class="{ 'text-primary': step !== 1 || 2 || 3 }" class="align-self-center mx-3 fs-5 fw-500">
                  {{ $t('facilities.modal.Tesis Bilgileri') }}
               </AmcText>
            </AmcText>
            <AmcText class="d-flex">
               <AmcText :class="{ 'bg-primary': step !== 1 }" class="step-number align-self-center">
                  <span :class="{ 'text-white': step !== 1 }" class="d-flex align-self-center">2</span>
               </AmcText>
               <AmcText :class="{ 'text-primary': step !== 1 }" class="align-self-center mx-3 fw-500 fs-5">
                  {{ $t('facilities.modal.Adres') }}
               </AmcText>
            </AmcText>
            <AmcText class="d-flex">
               <AmcText :class="{ 'bg-primary': step === 3 }" class="step-number align-self-center">
                  <span :class="{ 'text-white': step === 3 }" class="d-flex align-self-center">3</span>
               </AmcText>
               <AmcText :class="{ 'text-primary': step === 3 }" class="align-self-center mx-3 fw-500 fs-5">
                  {{ $t('facilities.modal.Eklendi') }}
               </AmcText>
            </AmcText>
         </AmcText>
      </template>
      <AmcText>
         <AmcText v-if="step === 1">
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Tesis Adı') }}</AmcText>
               <input
                  class="form-control shadow-none border-2 py-2"
                  v-model="buildingCreate.name"
                  placeholder="Örn. Amc Panel"
                  @keyup="buildingCreate.isValid()"
                  :class="{ 'border-danger': buildingCreate.errors.has('name') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('name')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Kısa Adı') }}</AmcText>
               <input
                  class="form-control shadow-none border-2 py-2"
                  v-model="buildingCreate.short_name"
                  :placeholder="$t('placeholders.Anımsatıcı kısaltma yazın')"
                  @keyup="buildingCreate.isValid()"
                  :class="{ 'border-danger': buildingCreate.errors.has('short_name') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('short_name')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Proje') }}</AmcText>
               <select
                  v-model="buildingCreate.project_id"
                  class="form-select shadow-none border-2 py-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('project_id') }"
                  aria-label="Default select example"
               >
                  <option :value="project.id" v-for="(project, index) in allProjects" :key="index">
                     {{ project.name }}
                  </option>
               </select>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('project_id')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Risk Durumu') }}</AmcText>
               <select
                  v-model="buildingCreate.risk_id"
                  class="form-select shadow-none border-2 py-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('risk_id') }"
                  aria-label="Default select example"
               >
                  <option :value="risk.id" v-for="(risk, index) in allRiskStatus" :key="index">
                     {{ risk.name }}
                  </option>
               </select>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('risk_id')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Kullanım Amacı') }}</AmcText>
               <select
                  v-model="buildingCreate.purpose_id"
                  class="form-select shadow-none border-2 py-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('purpose_id') }"
                  aria-label="Default select example"
               >
                  <option :value="use.id" v-for="(use, index) in allUsages" :key="index">
                     {{ use.name }}
                  </option>
               </select>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('purpose_id')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Abonelik Bitiş Tarihi') }} </AmcText>
               <VueCtkDateTimePicker
                  v-model="buildingCreate.subscription_end_at"
                  :locale="lang"
                  no-shortcuts
                  format="YYYY-MM-DD"
                  only-date
                  :min-date="today"
                  position="top"
               >
               </VueCtkDateTimePicker>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2" fwBold>{{ $t('facilities.modal.Plan Gösterim Süresi (Saniye)') }} </AmcText>
               <input
                  type="number"
                  class="form-control shadow-none border-2 py-2"
                  v-model="buildingCreate.site_plan_show_duration"
                  @keyup="buildingCreate.isValid()"
                  :class="{ 'border-danger': buildingCreate.errors.has('site_plan_show_duration') }"
               />
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('site_plan_show_duration')"
                  v-html="error"
               ></span>
            </AmcText>
         </AmcText>
         <AmcText v-if="step === 2">
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2">{{ $t('facilities.modal.Ülke') }}</AmcText>
               <select
                  v-model="buildingCreate.country_id"
                  class="form-select shadow-none border-2 py-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('country_id') }"
                  aria-label="Default select example"
                  @change="Cities()"
               >
                  <option :value="country.id" v-for="country in allCountry" :key="country.id">
                     {{ country.name }}
                  </option>
               </select>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('country_id')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2">{{ $t('facilities.modal.Şehir') }}</AmcText>
               <select
                  v-model="buildingCreate.city_id"
                  class="form-select shadow-none border border-2 py-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('city_id') }"
                  aria-label="Default select example"
                  @change="Districts()"
               >
                  <option :value="city.id" v-for="(city, index) in allCities" :key="index">
                     {{ city.name }}
                  </option>
               </select>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('city_id')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2">{{ $t('facilities.modal.İlçe') }}</AmcText>
               <select
                  v-model="buildingCreate.district_id"
                  class="form-select shadow-none border border-2 py-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('district_id') }"
                  aria-label="Default select example"
               >
                  <option :value="district.id" v-for="(district, index) in allDistricts" :key="index">
                     {{ district.name }}
                  </option>
               </select>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('district_id')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="form-group mt-3" fwBold>
               <AmcText class="fs-16 mb-2">{{ $t('facilities.modal.Adres') }}</AmcText>
               <textarea
                  v-model="buildingCreate.address"
                  rows="3"
                  wrap="soft"
                  class="form-control shadow-none border-2"
                  :class="{ 'border-danger': buildingCreate.errors.has('address') }"
               ></textarea>
               <span
                  class="text-danger fs-12"
                  :key="i"
                  v-for="(error, i) in buildingCreate.errors.get('address')"
                  v-html="error"
               ></span>
            </AmcText>
            <AmcText class="row mt-3" fwBold>
               <AmcText class="col-md-6">
                  <AmcText class="fs-16 mb-2">{{ $t('placeholders.Enlem') }}</AmcText>
                  <input
                     type="number"
                     class="form-control shadow-none border-2 py-2 me-3"
                     v-model="buildingCreate.latitude"
                     :placeholder="$t('placeholders.Enlem')"
                  />
               </AmcText>
               <AmcText class="col-md-6">
                  <AmcText class="fs-16 mb-2">{{ $t('placeholders.Boylam') }}</AmcText>
                  <input
                     type="number"
                     class="form-control shadow-none border-2 py-2"
                     v-model="buildingCreate.longitude"
                     :placeholder="$t('placeholders.Boylam')"
                  />
               </AmcText>
            </AmcText>
         </AmcText>
         <AmcText v-if="step === 3">
            <AmcText class="text-center my-5">
               <IconSuccess class="text-center" />
               <AmcText class="text-primary mt-4 fs-3">{{
                  $t('facilities.modal.Tesis ekleme işlemi başarılı')
               }}</AmcText>
            </AmcText>
         </AmcText>
      </AmcText>
      <template #actions>
         <AmcText>
            <button
               v-if="step !== totalStep"
               type="button"
               class="btn bg-transparent text-black border px-5 py-2 me-2"
               data-bs-dismiss="modal"
               @click="reset"
            >
               {{ $t('modalActions.Vazgeç') }}
            </button>
            <button v-if="step !== 1" @click="previousStep" class="btn btn-secondary px-5 py-2">
               {{ $t('modalActions.Geri') }}
            </button>
         </AmcText>
         <button v-if="step === 1" @click="nextStep" class="btn btn-primary px-5 py-2">
            {{ $t('modalActions.Devam') }}
         </button>
         <button v-if="step === 2" @click="emitCreate" class="btn btn-primary px-5 py-2">
            {{ $t('modalActions.Gönder') }}
         </button>
         <button
            v-if="step === totalStep"
            type="button"
            class="btn bg-transparent text-black border px-5 py-2 me-2"
            data-bs-dismiss="modal"
            @click="reset"
         >
            {{ $t('modalActions.Kapat') }}
         </button>
      </template>
   </AmcModal>
</template>

<script>
import get from 'lodash/get';

import moment from 'moment';
import Form from '@/libs/form';
import CitiesApi from '@/api/CitiesApi';
import UsagesApi from '@/api/UsagesApi';
import ProjectsApi from '@/api/ProjectsApi';
import CountriesApi from '@/api/CountriesApi';
import DistrictsApi from '@/api/DistrictsApi';
import RiskClassesApi from '@/api/RiskClassesApi';
import IconSuccess from '@/components/icons/IconSuccess';

export default {
   name: 'CreateFacilitiesModal.vue',
   components: {
      IconSuccess,
   },
   data() {
      return {
         lang: localStorage.getItem('lang'),
         today: moment().get().format('YYYY-MM-DD'),
         allProjects: [],
         allRiskStatus: [],
         allUsages: [],
         allCountry: [],
         allCities: [],
         allDistricts: [],
         step: 1,
         totalStep: 3,
         buildingCreate: Form.create([
            'name',
            'short_name',
            'project_id',
            'risk_id',
            'purpose_id',
            'subscription_end_at',
            'site_plan_show_duration',
            'country_id',
            'city_id',
            'district_id',
            'address',
            'latitude',
            'longitude',
         ]).validate({
            name: 'required',
            short_name: 'required',
            project_id: 'required',
            risk_id: 'required',
            purpose_id: 'required',
            country_id: 'required',
            city_id: 'required',
            district_id: 'required',
            address: 'required',
         }),
      };
   },
   methods: {
      async loadProjects() {
         await ProjectsApi.index().then(({ data }) => (this.allProjects = data));
      },
      async RiskStatus() {
         await RiskClassesApi.index().then(({ data }) => (this.allRiskStatus = data));
      },
      async Usages() {
         await UsagesApi.index().then(({ data }) => (this.allUsages = data));
      },
      async Countries() {
         await CountriesApi.index().then(({ data }) => (this.allCountry = data));
      },
      async Cities() {
         await CitiesApi.index(this.buildingCreate.country_id).then(({ data }) => (this.allCities = data));
      },
      async Districts() {
         await DistrictsApi.index(this.buildingCreate.country_id, this.buildingCreate.city_id).then(
            ({ data }) => (this.allDistricts = data)
         );
      },
      show() {
         this.$refs.modal.show();
         this.loadProjects();
         this.RiskStatus();
         this.Usages();
         this.Countries();
      },
      previousStep() {
         this.step--;
      },
      nextStep() {
         if (this.checkValidation()) this.step++;
      },
      checkValidation() {
         const validationKeyMapping = {
            1: ['name', 'short_name', 'project_id', 'risk_id', 'purpose_id'],
            2: ['country_id', 'city_id', 'district_id', 'address'],
         };

         return this.buildingCreate.isValid({
            disableDirtyCheck: true,
            only: get(validationKeyMapping, this.step),
         });
      },
      reset() {
         this.buildingCreate.reset();
         this.step = 1;
      },
      data() {
         let formatData = this.buildingCreate.toObject();
         return formatData;
      },
      emitCreate() {
         if (!this.checkValidation()) return;
         this.nextStep();
         this.$emit('create', this.buildingCreate);
      },
   },
};
</script>

<style scoped lang="scss">
.form-select {
   &:focus {
      border-color: #ced4da;
   }
}
.step-number {
   font-size: 20px;
   font-weight: 600;
   height: 50px;
   width: 50px;
   background-color: #ced4da;
   display: flex;
   justify-content: center;
   border-radius: 100%;
}

.modal-header {
   .badge {
      border-radius: 100%;
      background-color: #e6e7e9;
      padding: 10px 12px;
      font-size: 14px;
      margin-right: 8px;
   }
}
</style>
