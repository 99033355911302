<template>
   <svg stroke="#137AF6" width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M3.4 1H22.6C23.92 1 25 2.125 25 3.5V18.5C25 19.875 23.92 21 22.6 21H3.4C2.08 21 1 19.875 1 18.5V3.5C1 2.125 2.08 1 3.4 1Z"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <path d="M25 3L13 13L1 3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
   </svg>
</template>

<script>
export default {
   name: 'IconMail',
};
</script>
