import Http from '../libs/http';

class ProjectModemApi extends Http {
   index(ID) {
      return super.get(`api/panel/projects/${ID}/modem`);
   }
   EDIT_MODEM(ID, id, payload) {
      return super.put(`api/panel/projects/${ID}/modem/${id}`, payload);
   }
   CREATE_MODEM(ID, payload) {
      return super.post(`api/panel/projects/${ID}/modem`, payload);
   }
   DELETE_MODEM(ID, id) {
      return super.delete(`api/panel/projects/${ID}/modem/${id}`);
   }
}

export default new ProjectModemApi();
