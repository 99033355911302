<template>
   <div class="p-0 login-slider">
      <vue-glide :animationDuration="4000" :autoplay="true" :bullet="true" :gap="0" :perView="1">
         <vue-glide-slide v-for="(item, index) in items" :key="index">
            <img :src="require(`@/assets/images/login-slider-${item.img}.png`)" class="slider-img" />
            <div class="slider-text mb-2">
               <div class="mb-2">{{ item.text1 }}</div>
               <div class="fs-12 fw-lighter">
                  {{ item.text2 }}
               </div>
            </div>
         </vue-glide-slide>
      </vue-glide>
   </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js';

export default {
   name: 'LoginSlider',
   components: {
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide,
   },
   data() {
      return {
         items: [
            {
               img: 'img',
               text1: 'Alarm Monitoring Center',
               text2: 'Finder elektronik güvenlik sektörünün ihtiyacı olan 7 özelliği sunmak üzere kurulmuştur.',
            },
            {
               img: 'img',
               text1: 'Alarm Monitoring Center',
               text2: 'Finder elektronik güvenlik sektörünün ihtiyacı olan 7 özelliği sunmak üzere kurulmuştur.',
            },
            {
               img: 'img',
               text1: 'Alarm Monitoring Center',
               text2: 'Finder elektronik güvenlik sektörünün ihtiyacı olan 7 özelliği sunmak üzere kurulmuştur.',
            },
         ],
      };
   },
};
</script>

<style lang="scss">
.login-slider {
   background: linear-gradient(90deg, #137af6 -10.48%, rgba(19, 122, 246, 0) 116.7%);

   .glide {
      .glide__slides {
         z-index: -1;
         margin: 0;
      }
   }

   position: relative;

   .slider-img {
      width: 100%;
      height: 100vh;
   }

   .slider-text {
      position: absolute;
      bottom: 10%;
      color: white;
      margin-left: 8rem;
   }

   & .glide__bullets {
      position: absolute;
      margin-left: 8rem;
      margin-bottom: 3rem;
      bottom: 0;

      & button.glide__bullet {
         background: rgba(255, 255, 255, 0.38);
         height: 12px;
         min-width: 12px;
         border: none;
         margin-right: 10px;
         border-radius: 50%;
      }

      & button.glide__bullet.glide__bullet--active {
         background: #ffffff;
      }
   }
}
</style>
